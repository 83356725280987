import Observable from 'observable';
import moment from 'moment';
import BuildingInfo from '../buildingInfo';

const NOTIFICATION_CLEAN_RATE = 10000; // 10 seconds
// const MAX_ALIVE_TIME_OF_NOTIFICATION = 300000; // 5 minutes
const MAX_ALIVE_TIME_OF_NOTIFICATION = 10000; // 10 seconds

class CordovaNotificationHandler {
    notifications = [];
    notificationCleanerInterval;

    constructor() {
        this.observable = Observable();
        this.observable([]);

        this.incomingForegroundNotification = Observable.signal();

        this.startNotificationCleaner();
    }

    startNotificationCleaner = () => {
        this.notificationCleanerInterval = setInterval(this.cleanOldNotifications, NOTIFICATION_CLEAN_RATE);
    }

    cleanOldNotifications = () => {
        this.notifications.forEach((notification) => {
            const notificationTimestamp = notification.timestamp;
            const timeSinceNotificationArrived = moment().diff(notificationTimestamp);
            const hasExceededMaxAliveTime = timeSinceNotificationArrived > MAX_ALIVE_TIME_OF_NOTIFICATION;
            if (hasExceededMaxAliveTime) {
                this.removeSingleNotification(notification);
            }
        });
    }

    setHistory = (history) => {
        this.history = history;
    }

    setUnitChangeFunction = (unitChangeFunction) => {
        this.unitChangeFunction = async (buildingName, unit) => {
            let currentBuilding = await BuildingInfo.getCurrentBuilding()
            let currentUnit = await BuildingInfo.getCurrentUnit()
            let currentBuildingName = currentBuilding.name
            console.log(`Notification changing unit: currentBuilding: ${currentBuildingName}, buildingName: ${buildingName}, currentUnit: ${currentUnit}, unit: ${unit}`)
            if (currentBuildingName !== buildingName || currentUnit !== unit) {
                let building = BuildingInfo.getBuildingByName(buildingName)
                await unitChangeFunction(building, unit)
            }
        }
    }

    handleNotification = (notification) => {
        let data = notification.additionalData;
        let isValidNotification = (data && data.building && data.unit && data.page);
        if (isValidNotification) {
            let isAppInBackground = !data.foreground;
            if (isAppInBackground) {
                this.openNotification(notification);
            } else {
                this.openForegroundNotification(notification);
                this.appendNotification(notification);
            }
        } else {
            console.log('[cordovaNotificationHandler.handleNotification()]! Invalid notification: ' + JSON.stringify(notification));
        }
    }

    openNotification = (notification) => {
        let data = notification.additionalData;
        this.unitChangeFunction(data.building, data.unit).then(() => {
            let path = `/${data.building}/${data.unit}/dashboard/${data.page}/`;
            if(data.request_id){
                path += data.request_id;
            }
            console.log('[cordovaNotificationHandler.openNotification()] Notification just opened. Moving to path: ' + path);
            this.history.push(path);
            this.removeSingleNotification(notification);
        })
    }

    openForegroundNotification = (notification) => {
        this.incomingForegroundNotification(notification);
    }

    removeSingleNotification = (notification) => {
        const indexOfNotification = this.notifications.indexOf(notification);
        this.notifications.splice(indexOfNotification);
        this.notifyChange();
        const removedNotificationIsLastOne = this.incomingForegroundNotification() === notification;
        if (removedNotificationIsLastOne) {
            this.incomingForegroundNotification(null);
        }
    }

    appendNotification = (notification) => {
        const newNotifications = this.notifications.slice();
        newNotifications.push(this.withTimestamp(notification));
        this.setNotifications(this.getLastTwoElements(newNotifications));
        console.log('[cordovaNotificationHandler.handleNotification()] Notification arrived in foreground: ' + JSON.stringify(notification));
    }

    withTimestamp = (notification) => {
        const notificationWithTimestamp = Object.assign({}, notification);
        notificationWithTimestamp.timestamp = moment();
        return notificationWithTimestamp;
    }

    setNotifications = (notifications) => {
        this.notifications = notifications;
        this.notifyChange();
    }

    notifyChange = () => {
        this.observable(this.notifications);
    }

    getLastTwoElements = (array) => {
        const topIndexOfArray = array.length;
        const twoBeforeLastIndex = Math.max(0, topIndexOfArray - 2);
        return array.slice(twoBeforeLastIndex, topIndexOfArray);
    }

    // Public 
    getNotifications = () => {
        return this.observable;
    }

    // Public
    getIncomingForegroundNotification = () => {
        return this.incomingForegroundNotification;
    }
}

export default new CordovaNotificationHandler();
