import { injectIntl, defineMessages } from "react-intl";

const msg = defineMessages({
  
  login: {
    id: 'access.login',
    defaultMessage: 'Log in'
  },
  
  email: {
    id: 'access.email',
    defaultMessage: 'Email',
  },

  password: {
    id: 'access.password',
    defaultMessage: 'Password',
  },
  
  forgotPassword: {
    id: 'access.forgotPassword',
    defaultMessage: 'Forgot Password'
  },

  newAccount: {
    id: 'access.newAccount',
    defaultMessage: 'Create New Account'
  },

  activateDevice: {
    id: 'access.activateDevice',
    defaultMessage: 'Activate Device'
  }
  
})


export default msg