import React, { Component } from 'react';
import * as moment from 'moment';

import { remote } from './helpers';
import Calendar from './Calendar';
import ByHourCalendar from './ByHourCalendar';
import ItemChooser from './ItemChooser';

import { Route, Switch } from 'react-router-dom';

import Header from '../../../containers/PageView/Header';
import PageFrame from '../../../components/PageFrame.js';

import { reservations } from './helpers';
import { dateHelpers } from './helpers';
import { message } from 'antd';
import msg from './messages'

// Constants
const ALL_DAY = 0;
const REQUEST_DELAY_SECONDS = .2;

class DatePicker extends Component {
  constructor(props) {
    super(props);

    console.log('[amenities:DatePicker.constructor()] Creating DatePicker');

    // Send reservable data to states
    let groupData = this.props.groupData;

    this.AddRequestData = this.AddRequestData.bind(this);
    this.isDateClosured = this.isDateClosured.bind(this);
    this.getFirstAvailableDay = this.getFirstAvailableDay.bind(this);
    this.confirmReservation = this.confirmReservation.bind(this);
    this.removeReservation = this.removeReservation.bind(this);
    this.addReservation = this.addReservation.bind(this);
    this.updateReservation = this.updateReservation.bind(this)
    this.getSelectedItem = this.getSelectedItem.bind(this);
    this.refreshAvailability = this.refreshAvailability.bind(this);

    // Make checks
    let isAllDayReservable = (groupData.period === ALL_DAY);

    this.updateReservations();

    this.updateClosures();

    // Create empty queue
    this.delayedRequestQueue = {};

    // Set initial state
    this.state = {
      'isAllDayReservable': isAllDayReservable,
      'endDate': null,
      'itemID': null,
      'itemAmount': null,
      'action': 'reserve',
      'eventCallback': this.AddRequestData.bind(this),
      'groupData': this.props.groupData,
      'reservations': [],
      'closures': [],
      'isItemSelectorRequired': (props.groupData.items.length >= 1),
      'selectedDate': null,
      'selectedHour': null,
      'availability': {},
      'delayedRequestQueue': [],
    }
  }

  getSelectedItem() {
    var itemID = this.state.itemID || this.props.groupData.items[0].id;
    var item;
    var i = 0;
    do {
      item = this.props.groupData.items[i];
      i++;
    } while (item.id !== itemID && this.props.groupData.items.length > i);
    if (item.id === itemID)
      return item;
  }

  getSelectedTurnTime() {
    return (this.state.selectedHour || this.state.selectedDate || this.state.selectedMonth);
  }

  getCurrentReservation() {
    var currentItem = this.getSelectedItem.bind(this)();
    var startDate = this.getSelectedTurnTime();

    var newReservation = {
      // id: undefined,
      // for_unit_id: this.props.groupData.for_unit_id,
      // state: 'reserved',
      state: 'pending',
      description: this.props.groupData.description+"1",
      start_date: startDate && startDate.format('YYYY-MM-DDTHH:mm:ss'),
      end_date: startDate && startDate.clone().add(this.props.groupData.period, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
      fmt_start_date: startDate && startDate.format('MM/DD/YY H:mmA'),
      fmt_end_date: startDate && startDate.clone().add(this.props.groupData.period, 'minutes').format('MM/DD/YY H:mmA'),
      period: this.props.groupData.period,
      symbol_price: currentItem.symbol_price,
      item_description: this.props.groupData.description,
      name: this.props.groupData.name,
      item_name: currentItem.name,
      for_unit_number: this.props.unitID,
      amount: ([null, undefined].includes(this.state.itemAmount)? 1: this.state.itemAmount),
      reservable_id: currentItem.id,
      group_id: this.props.groupData.id
    };
    var reservationToken = reservations.getToken(newReservation);
    newReservation.id = reservationToken;
    return newReservation;
  }

  updateReservations(date) {
    remote.fetchReservations(
      this.props.reqData,
      this.props.groupData.id,
      (resp) => this.updateReservationsCallback.bind(this)(resp),
      date
    );
  }

  updateReservationsCallback(resp) {
    if (resp.status === 200) {
      this.setState({
        'reservations': reservations.sortByDay(resp.data),
      });
    }
  }

  updateClosures() {
    remote.fetchClosures(
      this.props.reqData,
      this.props.groupData.id,
      this.closuresCallback.bind(this)
    );
  }

  closuresCallback(resp) {
    if (resp.status === 200) {
      this.setState({
        closures: resp.data,
      });
    }
  }

  queueDelayedReservation(reqData, reservation, delay, callback) {
    var reservationToken;
    if (reservation && delay) {
      let isByHour = (this.props.groupData.period !== 0);
      let startDate = moment(reservation.start_date);
      var endDate = this.props.groupData.period !== 0? startDate.clone().add(this.props.groupData.period, 'minutes'): null;
      let dayIndex = dateHelpers.serializeDay(startDate);
      let hourIndex = isByHour? dateHelpers.serializeHour(startDate): '0000';
      let itemID = reservation.reservable_id;

      // This identifies the reservation until it gets its id
      reservationToken = dayIndex + hourIndex + '_' + itemID;

      if (reservation.amount === 'ERROR') {
        console.log('[amenities:DatePicker.queueDelayedReservation()]! "ERROR" amount recieved. Unqueueing reservation');
        if (this.delayedRequestQueue[reservationToken])
          clearTimeout(this.delayedRequestQueue[reservationToken].id);
      } else {
        console.log('[amenities:DatePicker.queueDelayedReservation()] Queueing reservation (#'+reservation.group_id+') to wait for '+delay+' seconds');

        if (!this.delayedRequestQueue[reservationToken])
          this.delayedRequestQueue[reservationToken] = {};
        else {
          clearTimeout(this.delayedRequestQueue[reservationToken].id);
        }

        // reservation is not queued, add new timeout
        this.delayedRequestQueue[reservationToken].id = setTimeout(
          function queuedReservation() {
            console.log('[amenities:DatePicker.queueDelayedReservations()] Running queued reservation request (#'+reservationToken+')');

            // Already running, so entry must be clean
            clearTimeout(this.delayedRequestQueue[reservationToken].id);
            delete this.delayedRequestQueue[reservationToken];
              
            // Make reservation
            remote.makeReservation(
              reqData,
              reservation.reservable_id||0,
              reservation.amount,
              startDate,
              endDate,
              callback,
            );
          }.bind(this),
          delay*1000 // delay is expressed in seconds
        );
      }
    } else {
      console.log('[amenities:DatePicker.queueDelayedReservation()]! Could not queue reservation');
    }
    return reservationToken;
  }

  AddRequestData(eventData){
    
    if (eventData) {
      var nextState = {};
      if (eventData.date !== undefined) {
        console.log('[amenities:DatePicker.AddRequestData()] Date has been chosen');
        nextState.selectedDate = nextState.selectedMonth = eventData.date;
      } else if (eventData.hour !== undefined) {
        console.log('[amenities:DatePicker.AddRequestData()] Hour has been chosen');
        nextState.selectedHour = eventData.hour; // dateHelpers.setHour(this.state.selectedDate, eventData.hour);
      } else if (eventData.item && eventData.item.id) {
        console.log('[amenities:DatePicker.AddRequestData()] Item has been chosen');
        nextState.itemID = eventData.item.id;
        if (![undefined, null].includes(eventData.item.amount)) {
          nextState.itemAmount = eventData.item.amount;
        }
      }
      if (eventData.type === 'cancel') {
        nextState.action = 'cancel';
      } else {
        nextState.action = 'reserve';
      }

      this.setState(nextState, this.afterAddRequestData.bind(this));
    }
  }

  afterAddRequestData() {
    if (this.isStateReadyToMakeRequest()) {
      if (this.state.action === 'reserve') {
        console.log('[amenities:DatePicker.AddRequestData()] About to make reservation request');
        this.handleReserveAction();
      } else if (this.state.action === 'cancel') {
        console.log('[amenities:DatePicker.AddRequestData()] About to cancel reservation');
        this.handleCancelAction();
      }
      this.cleanStateAfterReserved();
    }
  }

  cleanStateAfterReserved() {
    this.setState(() => {
      let newState = {action: 'reserve'};
      if (this.isItemSelectorRequired()) {
        newState.itemID = null;
        newState.itemAmount = null;
      } else if (this.isByHourCalendar()) {
        newState.itemID = null;
        newState.selectedHour = null;
      } else {
        newState.itemID = null;
        newState.selectedDate = null;
      }
      return newState;
    });
  }

  handleReserveAction() {
    var newReservation = this.getCurrentReservation();
    
    
    var isValidAmount = (![undefined, null].includes(this.state.itemAmount));
    if (isValidAmount) {
      
      // User is modifying amount, use delayed reservation
      newReservation.id = this.getCurrentReservationID();
      let token = reservations.getToken(newReservation);
      this.makeDelayedReservation(newReservation);
      if (!newReservation.id) {
        newReservation.id = token; // update reservation, it now has the queued reservation token as id
      }
    } else {
      
      this.makeSimpleReservation(newReservation);
    }
    if (this.state.itemAmount !== 0 && Number.isInteger(this.state.itemAmount)) {
      this.props.addReservation(newReservation);
    }
    
  }

  handleCancelAction() {
    var newReservation = this.getCurrentReservation();
    newReservation.id = this.getCurrentReservationID();
    console.log('[amenities:DatePicker.AddRequestData()] About to cancel reservation');
    if (newReservation.id) {
      this.cancelReservation(newReservation);
    } else {
      console.log('[amenities:DatePicker.AddRequestData()]! Could not cancel reservation: Reservation id #'+newReservation.id+' does not exists');
    }
  }

  getCurrentReservationID() {
    var itemID = this.getSelectedItem().id;
    var reservationID = reservations.getReservationID(
      itemID,
      this.state.reservations,
      this.state.selectedHour||this.state.selectedDate,
      !this.state.isAllDayReservable,
    );
    return reservationID;
  }

  isStateReadyToMakeRequest() {
    return (
      (this.state.selectedDate) &&                                  // There MUST be a date selected,
      (this.state.selectedHour || this.state.isAllDayReservable) && // an hour if reservable is not all-day one, and
      this.state.itemID     // an item id if there is more than one item in group
    );
  }


  makeSimpleReservation(theReservation) {
    
    let startDate = this.getSelectedTurnTime();
    let endDate = this.isByHourCalendar() ? startDate.clone().add(this.props.groupData.period, 'minutes') : null;
    
    this.addPendingReservation(theReservation);
    remote.makeReservation(
      this.props.reqData,
      this.state.itemID || this.getItemID(0),
      this.state.itemAmount,
      startDate,
      endDate,
      ({id, err}) => {
        if (err) {
          //Show cartel when you are exceeding max pending reservations
          if ('response' in err && 'data' in err.response && 'type' in err.response.data && err.response.data.type === 'exceededReservations') message.warning(this.props.t(msg.exceededReservations));
          console.log('[amenities:DatePicker.AddRequestData()]! Could not request reservation: Server responded with error code: '+err+'');
          this.declineReservation(theReservation);
        } else if (theReservation) {
          console.log('[amenities:DatePicker.AddRequestData()] Server successfully responsed reservation request');
          if (id) {
            console.log('[amenities:DatePicker.AddRequestData()] Reservation successfully requested. Setting id #'+id);
            this.confirmReservation(theReservation, id);
          } else {
            console.log('[amenities:DatePicker.AddRequestData()] Reservation successfully canelled. Removing id #'+theReservation.id);
            this.props.removeReservation(theReservation.id);
            this.removeReservation(theReservation);
          }
        }
      },
    );
  }

  makeDelayedReservation(theReservation) {
    this.queueDelayedReservation(
      this.props.reqData,
      theReservation,
      REQUEST_DELAY_SECONDS,
      ({id, err}) => {
        if (err) {
          //Show cartel when you are exceeding max pending reservations
          if ('response' in err && 'data' in err.response && 'type' in err.response.data && err.response.data.type === 'exceededReservations') message.warning(this.props.t(msg.exceededReservations));
          console.log('[amenities:DatePicker.AddRequestData()]! Could not request reservation: Server responded with error code: '+err+'');
          this.declineReservation(theReservation);
        } else if (theReservation) {
          console.log('[amenities:DatePicker.AddRequestData()] Server successfully responsed reservation request');
          if (theReservation.amount === 0) {
            this.removeReservation(theReservation);
            this.props.removeReservation(theReservation.id);
          } else {
            if (id) {
              theReservation.id = id;
              theReservation.state = 'reserved';
            }
            this.props.updateReservation(theReservation.id, theReservation);
            this.updateReservation(theReservation);
          }

        }
      },
    );
  }

  cancelReservation(theReservation) {
    let reservationID = this.getCurrentReservationID();
    this.setCancellingReservation(theReservation);
    remote.cancelReservation(
      this.props.reqData,
      reservationID,
      ({err}) => {
        if (err) {
          console.log('[amenities:DatePicker.AddRequestData()]! Server did not cancel reservation: err '+err);
          this.declineCancelation(theReservation);
        } else {
          console.log('[amenities:DatePicker.AddRequestData()] Reservations just cancelled, refreshing');
          this.props.removeReservation(reservationID);
          this.removeReservation(theReservation);
        }
      }
    );
  }

  addPendingReservation(theReservation) {
    let newPendingReservation = reservations.clone(theReservation);
    newPendingReservation.id = reservations.getToken(theReservation);
    this.props.addReservation(newPendingReservation);
    this.updateReservation(newPendingReservation);
  }

  setCancellingReservation(theReservation) {
    let newReservation = reservations.clone(theReservation);
    newReservation.id = this.getCurrentReservationID();
    newReservation.state = 'cancelling';
    this.props.updateReservation(newReservation.id, newReservation);
  }

  confirmReservation(theReservation, id) {
    let modifiedReservation = reservations.clone(theReservation);
    modifiedReservation.id = id;
    modifiedReservation.state = 'reserved';
    this.props.updateReservation(
      reservations.getToken(theReservation),
      modifiedReservation
    );
    this.updateReservation(modifiedReservation);
  }

  declineReservation(theReservation) {
    let reservationToken = reservations.getToken(theReservation);
    let failedReservation = reservations.clone(theReservation);
    failedReservation.id = reservationToken;
    this.props.removeReservation(reservationToken);
    this.removeReservation(failedReservation);
  }

  declineCancelation(theReservation) {
    let newReservation = reservations.clone(theReservation);
    newReservation.state = 'reserved';
    this.props.updateReservation(newReservation.id, newReservation);
  }

  removeReservation(theReservation) {
    var startDate = moment(theReservation.start_date);
    var dateIndex = reservations._dateToString(startDate);

    if (this.state.reservations[dateIndex]) {
      for (let i = 0; i < this.state.reservations[dateIndex].length; i++) {
        let iteratedReservation = this.state.reservations[dateIndex][i];
        if (iteratedReservation.id === theReservation.id) {
          this.setState( oldState => {
            var newReservations = Object.assign({}, oldState.reservations);
            newReservations[dateIndex] = newReservations[dateIndex].slice();
            newReservations[dateIndex].splice(i, 1);
            console.log('[reservables:DatePicker.removeReservation()] Reservation just removed!');
            return {reservations: newReservations}
          });
        }
      }
    }
  }

  addReservation(theReservation) {
    var startDate = moment(theReservation.start_date);
    var dateIndex = reservations._dateToString(startDate);
    this.setState( oldState => {
      var newReservations = Object.assign({}, oldState.reservations);
      if (!newReservations[dateIndex]) {
        newReservations[dateIndex] = [];
      }
      newReservations[dateIndex] = newReservations[dateIndex].slice();
      newReservations[dateIndex].push(theReservation);
      console.log('[reservables:DatePicker.updateReservation()] Reservation just added!');
      return {reservations: newReservations}
    });
  }

  updateReservation(theReservation) {
    var startDate = moment(theReservation.start_date);
    var dateIndex = reservations._dateToString(startDate);
    var reservationFound = false;
    if (this.state.reservations[dateIndex]) {
      for (let i = 0; i < this.state.reservations[dateIndex].length; i++) {
        let iteratedReservation = this.state.reservations[dateIndex][i];
        let reservationToken = reservations.getToken(theReservation);
        if (iteratedReservation.id === theReservation.id || iteratedReservation.id === reservationToken) {
          reservationFound = true;
          this.setState( oldState => {
            // clone reservations and return a version where that one is removed
            var clonedReservation = reservations.clone(theReservation);
            var newReservations = Object.assign({}, oldState.reservations);
            newReservations[dateIndex] = newReservations[dateIndex].slice();
            newReservations[dateIndex][i] = clonedReservation;
            console.log('[reservables:DatePicker.updateReservation()] Reservation just updated!');
            return {reservations: newReservations}
          });
        }
      }
    }
    if (!reservationFound)
      this.addReservation(theReservation);
  }

  isDateClosured(date) {
    var isClosured = false;
    if (this.props.groupData.period === 0) {
      for (var i = 0; i < this.state.closures.length && !isClosured; i++) {
        var closure = this.state.closures[i];
        var closureDate = moment(closure.start_date);
        isClosured = (dateHelpers.isSameDate(closureDate, date));
      }
    }
    return isClosured;
  }

  isByHourCalendar() {
    return (this.props.groupData.period !== 0);
  }

  isItemSelectorRequired() {
    return (
      this.props.groupData.items.length > 1 ||
      this.props.groupData.items[0].amount !== 0
    );
  }

  getItemID(index) {
    return this.props.groupData.items[index].id;
  }

  getFirstAvailableDay() {
    var isByHour = (this.props.period !== 0);
    var firstDay = moment().add(this.props.groupData.min_time_in_advance, this.props.groupData.min_advance_unit);
    // if by hour, and last turn has already passed, then add a day
    if (isByHour && dateHelpers.serializeDay(moment()) === dateHelpers.serializeDay(firstDay)) {
      let [o_h, o_m, o_s] = this.props.groupData.open_time.split(':');
      let [c_h, c_m, c_s] = this.props.groupData.close_time.split(':');
      var lastTurn = dateHelpers.getByHourCalendarRepresentation(
        moment().set({
          hours: o_h,
          minutes: o_m,
          seconds: o_s,
        }),
        moment().set({
          hours: c_h,
          minutes: c_m,
          seconds: c_s,
        }),
        this.props.groupData.period,
      );
      lastTurn = lastTurn[lastTurn.length-1];
      if (firstDay.isAfter(lastTurn)) {
        firstDay.add(1, 'day');
      }
    }
    // TODO: checkif blocked by DOW
    var i = 0; // use to count not more than 7 days when checking if blocked by dow

    var isClosured = true;
    while (isClosured) {
      isClosured = this.isDateClosured(firstDay);
      if (isClosured) {
        firstDay.add(1, 'day');
      }
    }
    if (!isClosured) {
      console.log('First available day: '+firstDay.format('YYYYMMDDHHmm'));
      return firstDay;
    }
    return false;
  }

  refreshAvailabilityIfMonthChanged(prevProps, prevState) {
    if (
      this.state.reservations !== prevState.reservations
      || this.state.closures !== prevState.closures
      || this.props.groupData !== prevProps.groupData
      || (
        this.state.selectedMonth && prevState.selectedMonth
        && this.state.selectedMonth.format('YYYYMM') !== prevState.selectedMonth.format('YYYYMM')
      )
      || (
        this.state.selectedDate && prevState.selectedDate
        && this.state.selectedDate.format('YYYYMM') !== prevState.selectedDate.format('YYYYMM')
      )
      || (
        this.state.selectedHour && prevState.selectedHour
        && this.state.selectedHour.format('YYYYMM') !== prevState.selectedHour.format('YYYYMM')
      )
    ) {
      this.refreshAvailability();
    }
  }

  refreshAvailability() {
    var month =  this.getSelectedTurnTime() || this.getFirstAvailableDay() || moment();
    console.log('[amenities:DatePicker.componentDidUpdate] Refreshing reservation availability for month '+dateHelpers.serializeDate(month));
    this.setState({
      availability: reservations.computeAvailability(
        this.state.reservations,
        this.state.closures,
        this.props.groupData,
        month,
        this.props.unitID,
      )
    });
  }

  onMonthChange(month) {
    this.setState((prevProps, prevState) => {
      if (prevState.selectedMonth !== month) {
        console.log('[amenities:DatePicker.onMonthChange()] Month updated: '+dateHelpers.serializeDate(month));
        return {selectedMonth: month};
      }
    });
    this.updateReservations(month);
  }

  componentDidUpdate(prevProps, prevState) {
    this.refreshAvailabilityIfMonthChanged(prevProps, prevState);
  }

  render() {

    return (
      <div className="date-picker">

          { /* Toggle between calendar pickers to complete transaction */ }
          <Switch level={this.props.level + 1 }>
            <Route
              exact
              path={this.props.basepath}
              render={
                () => (
                  <Calendar
                    groupData={this.state.groupData}
                    onSelect={this.AddRequestData}
                    reservations={this.state.reservations}
                    unitID={this.props.unitID}
                    removeReservation={this.props.removeReservation}
                    addReservation={this.props.addReservation}
                    myReservations={this.props.myReservations}
                    UIStages={this.state.UIStages}
                    closures={this.state.closures}
                    availability={this.state.availability}
                    onMonthChange={this.onMonthChange.bind(this)}
                    defaultValue={this.state.selectedMonth}
                    name={this.props.name}
                    level={this.props.level + 1}
                  />
                )
              }
            />
            <Route
              path={this.props.basepath+'/:startDate'}
              onChange={(prevProps, newProps) => console.log('Route has changed! prevProps: '+prevProps+', newProps: '+newProps)}
              render={
                (routeProps) => (
                  <PageFrame>
                    <Header>{this.props.groupData.name}</Header>
                    <ByHourCalendar
                      routerProps={routeProps}
                      date={this.state.selectedDate}
                      openTime={this.state.groupData.open_time}
                      closeTime={this.state.groupData.close_time}
                      period={this.state.groupData.period}
                      groupData={this.state.groupData}
                      reservations={this.state.reservations}
                      unitID={this.props.unitID}
                      removeReservation={this.props.removeReservation}
                      addReservation={this.props.addReservation}
                      myReservations={this.props.myReservations}
                      AddRequestData={this.AddRequestData}
                      closures={this.state.closures}
                      availability={this.state.availability}
                      resetDateAndHour={
                        function() {
                          console.log('About to clear date and hour from datepicker state');
                          var newState = {
                            selectedDate: null,
                            selectedHour: null,
                          };
                          this.setState(newState);
                        }.bind(this)
                      }
                      isAllDayReservable={this.state.isAllDayReservable}
                      isItemSelectorRequired={this.state.isItemSelectorRequired}
                      level={this.props.level + 1}
                    />
                  </PageFrame>
                )
              }
            />
          </Switch>
          {

            (this.state.isItemSelectorRequired && this.state.selectedDate
            && (this.props.groupData.period === 0 || (this.state.selectedHour && this.state.selectedHour._isAMomentObject)))
            ? (
              <ItemChooser
                items={this.props.groupData.items}
                isVisible={true}
                reservations={this.state.reservations}
                date={this.getSelectedTurnTime()}
                modalCallback={
                  function() {
                    var newState = {
                      itemID: null,
                    };
                    var toClean = 'selectedHour';
                    if (this.props.groupData.period === 0) {
                      toClean = 'selectedDate';
                    }
                    newState[toClean] = null;
                    this.setState(newState);
                  }.bind(this)
                }
                unitID={this.props.unitID}
                removeReservation={this.props.removeReservation}
                addReservation={this.props.addReservation}
                myReservations={this.props.myReservations}
                closures={this.props.closures}
                isByHour={this.state.selectedHour !== null}
                availability={this.state.availability}
                groupData={this.props.groupData}
                addRequestData={this.AddRequestData}
                t={this.props.t}
              />
            )
            : null
          }
      </div>
    );
  }
}

export default DatePicker