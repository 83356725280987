import React from 'react';
import Zone from './Zone';
import { SmartRequests } from '../../../utilities/index.js';


class Automation extends React.Component{
  
  constructor(props) {
    super(props);
    this.state = { 'controllers': {}, 'isKhimo': null}
    
    SmartRequests.get('commands').then( req => {
      if (req.status === 200 && req.data && req.data.controllers) {
        let parsedCommands = {};
        req.data.controllers.forEach( controller => {
          if (parsedCommands[controller.location] === undefined) {
            parsedCommands[controller.location] = [];
          }
          parsedCommands[controller.location].push(controller);
        });
        this.setState({ 'controllers': parsedCommands, 'isKhimo': req.data.is_khimo})
      }
    }).catch( err => console.log('[automation:helpers.fetchCommands()]! Could not fetch controllers data: '+err) )

  }
  componentWillMount() {
    this.props.hidePageText();
  }

  componentDidUpdate(prevProps, prevState) {
    const { controllers } = this.state;
    if (prevState.controllers !== controllers && Object.keys(controllers).length === 0) {
        this.props.showPageText();
    }
}

  render() {
    return (
      <div className="automation box">
      {
        Object.keys(this.state.controllers).map(
          (loc, index) => (
            <div key={'controller-'+index} className='widgets-box'>
              <h2>{loc}</h2>
              <div className="zones">
                {
                  this.state.controllers[loc].map(
                    (controller, index) => (
                      <Zone
                        key={'key-'+index}
                        unit={this.props.unit}
                        name={controller.name}
                        commands={controller.commands}
                        isKhimo={this.state.isKhimo}
                        reqData={this.props.reqData}
                      />
                    )
                  )
                }
              </div>
            </div>
          )
        )
      }
      </div>
    );
  }
}

export default Automation