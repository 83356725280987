import { injectIntl, defineMessages } from "react-intl";

const msg = defineMessages({

  SuccessRequest: {
    id:'custom.SuccessRequest',
    defaultMessage:'The request has been sent successfully'
  },

  ErrorRequest: {
    id:'custom.ErrorRequest',
    defaultMessage:'There was an error with the request'
  },
  noRequestsMatch:{
    id:'custom.noRequestsMatch',
    defaultMessage:'No requests found matching criteria!'
  },
  submit: {
    id:'custom.submit',
    defaultMessage:'Submit'
  },
  addComent: {
    id:'custom.addComent',
    defaultMessage:'Add a Comment'
  },
  showEvents: {
    id:'custom.showEvents',
    defaultMessage:'Show Events'
  },
  close :{
    id:'custom.close',
    defaultMessage:'Close'
  },
  reload :{
    id:'custom.reload',
    defaultMessage:'Reload'
  },
  cancelComment:{
    id:'custom.cancel',
    defaultMessage:'Cancel'
  },
  cancelRequest:{
    id:'custom.cancelRequest',
    defaultMessage:'Cancel Request'
  },
  all:{
    id:'custom.all',
    defaultMessage:'All'
  },
  info: {
    id:'custom.info',
    defaultMessage: 'More info'
  },
  fieldRequired :{
    id:'custom.fieldRequired',
    defaultMessage:'This field is required!'
  },
  detailsRequestTitle :{
    id:'custom.detailsRequestTitle',
    defaultMessage:'Request details'
  },
  successCancelRequest :{
    id:'custom.successCancelRequest',
    defaultMessage:'¡The request was successfully canceled!'
  },
  lastModification :{
    id:'custom.lastModification',
    defaultMessage:'Last modification'
  },
  deployInteraction :{
    id:'custom.deployInteraction',
    defaultMessage:'Deploy Interaction'
  },
  interactionIsNotEnabled :{
    id:'custom.interactionIsNotEnabled',
    defaultMessage:'This interaction is not enabled at this time'
  },
  message :{
    id:'custom.message',
    defaultMessage:'Message'
  },
  interactionDisabled :{
    id:'custom.interactionDisabled',
    defaultMessage:'Interaction disabled'
  },
  createMessage :{
    id:'custom.createMessage',
    defaultMessage:'The order has been successfully created, you can communicate with the staff through this channel'
  },
  assignMessage :{
    id:'custom.assignMessage',
    defaultMessage:'Your order has been assigned to the role of'
  },
  ackMessage :{
    id:'custom.ackMessage',
    defaultMessage:'accept your request'
  },
  cancelMessage :{
    id:'custom.cancelMessage',
    defaultMessage:'The request has been canceled'
  },
  closeMessage :{
    id:'custom.closeMessage',
    defaultMessage:'The request has been closed. Message from the staff: '
  },
  reopenMessage :{
    id:'custom.reopenMessage',
    defaultMessage:'The interaction has been opened'
  },
  ongoing :{
    id:'custom.ongoing',
    defaultMessage:'Ongoing'
  },
  requestState :{
    id:'custom.requestState',
    defaultMessage:'Your request is in a state'
  },

  

  
  

  

  
  
  
  
  
  

  

  

  
  

  
  
  //states
  pending :{
    id:'custom.pending',
    defaultMessage:'Pending'
  },
  processing:{
    id:'custom.processing',
    defaultMessage:'In progress'
  },
  closed :{
    id:'custom.closed',
    defaultMessage:'Closed'
  },
  lastEventDate :{
    id:'custom.lastEventDate',
    defaultMessage:'Last Updated'
  },
  EventTittle :{
    id:'custom.EventTittle',
    defaultMessage:'Event'
  },
  EventDate :{
    id:'custom.EventDate',
    defaultMessage:'Date'
  },
  EventBy :{
    id:'custom.EventBy',
    defaultMessage:'Event by'
  },
  EventMessage :{
    id:'custom.EventMessage',
    defaultMessage:'Message'
  },
//events
// E_REQUEST_ACK = _('ack')
// E_REQUEST_ASSIGN = _('assign')
// E_REQUEST_CANCEL = _('cancel')
// E_REQUEST_CLOSE = _('close')
// E_REQUEST_COMMENT = _('comment')
// E_REQUEST_CREATE = _('create')         # initial
// E_REQUEST_ESCALATE = _('escalate')
// E_REQUEST_REOPEN = _('reopen')
// E_REQUEST_ATTACH = _('request_attach') 
  ack :{
    id:'custom.event.ack',
    defaultMessage:'Acknowledged'
  },
  assign :{
    id:'custom.event.assign',
    defaultMessage:'Assigned'
  },
  cancel :{
    id:'custom.event.cancel',
    defaultMessage:'Cancelled'
  },
  // close :{ //already defined
  //   id:'custom.event.closed',
  //   defaultMessage:'Closed'
  // },
  comment :{
    id:'custom.event.comment',
    defaultMessage:'Commented'
  },
  create :{
    id:'custom.event.create',
    defaultMessage:'Created'
  },
  escalate :{
    id:'custom.event.escalate',
    defaultMessage:'Escalated'
  },
  reopen :{
    id:'custom.event.reopen',
    defaultMessage:'Reopened'
  },
  request_attach :{
    id:'custom.event.request_attach',
    defaultMessage:'Attached'
  },
  
})


export default msg

