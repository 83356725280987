import React, { Component } from 'react';

import { Modal } from 'antd';

import { injectIntl } from "react-intl";

import * as utils from '../../../utilities/utils.js'

import msg from './messages'

import DeliveryDetails from './DeliveryDetails'




class DeliveryDetailsModal extends Component {
  constructor(props) {
    super(props);
  }
  handleClose() {
    this.props.modalCallback();
  }
  render() {
    const del = this.props.delivery
      return (
        <Modal
        closable
        maskClosable
        destroyOnClose
        visible={true}
        footer={null}
        onCancel={this.handleClose.bind(this)}
        >
          <DeliveryDetails 
          delivery={this.props.delivery} 
          reqData={this.props.reqData}
          />
        </Modal>
      );
    }
}

export default DeliveryDetailsModal = DeliveryDetailsModal
