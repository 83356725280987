import React from 'react'
import * as utils from '../../utilities/utils.js'
import { Switch, Route, Redirect } from 'react-router-dom'
import { withRouter } from 'react-router'
import { message } from 'antd'
import LogIn from '../Access'
import DashBoard from '../Dashboard'
import Guest from '../Guest'
import Fetching from '../../components/Fetching.js'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import { IntlProvider, addLocaleData } from "react-intl"
import { CordovaCommunication } from './cordovaCommunication'
import BuildingInfo from './buildingInfo'
import LoginError from '../../components/LoginError'
import translations from "../../i18n/locales"
import CookieStorage from './cookieStorage.js'
import * as CustomStorage from '../../utilities/customStorage'
import * as CredentialsStorage from './credentialsStorage.js';
import { cancellAllPageFetchingRequests } from '../Dashboard/pageData'
import { SmartRequests } from '../../utilities/index.js';
import { abortPreferencesRecovery, finishPreferencesRecovery } from './cordovaCommunication/cordovaInit'
import Axios from 'axios'
import { ZoomableImagesRenderer } from '../../components/ZoomableImage'

const axios = Axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

const NOT_LOGGED_STATE = 'NOT_LOGGED'
const LOGGED_STATE = 'LOGGED'

addLocaleData(es);
addLocaleData(en);

// This is the Main component, it will be created at the start of the app.
class Main extends React.PureComponent {

  infoInterval = null;

  state = {
    logged: null,
    msg: '',
    unit: 0,
    building: '',
    locale: (navigator.languages && navigator.languages[0]) 
      || navigator.languages
      || navigator.UserLanguage
      || 'en',
    userLocale: false,
    token: undefined,
    tpMode: false,
    isDevice: false,
    displayAuthenticationError: false,
    isGuest: false,
  }
  
  componentDidMount = () => {
    
    console.log('mounting Main')
    SmartRequests.init(this.login, this.showAuthError, this.dismissAuthError);
    CordovaCommunication.initialize(this.props.history, this.setToken, () => {}, this.setTpMode);
    CordovaCommunication.openChannel(CordovaCommunication.INITIALCONFIG_CHANNEL);
    CordovaCommunication.openChannel(CordovaCommunication.MDMCONFIG_CHANNEL);
    CordovaCommunication.openChannel(CordovaCommunication.READY_CHANNEL);
    CordovaCommunication.setHandler(CordovaCommunication.LINK_FOLLOWER, this.moveTo);
    CordovaCommunication.openChannel(CordovaCommunication.LINK_FOLLOWER);
    CustomStorage.setup().then(() => {
      CordovaCommunication.waitForPreferences()
      .then(async (preferences) => {
        await CookieStorage.store();
        if (!await utils.handlePreviousPreferences(preferences)) {
          await abortPreferencesRecovery()
        } else {
          await finishPreferencesRecovery()
        }
      })
      .catch((err) => {
        console.log(`[Main.componentDidMount()] Preferences were not read: ${JSON.stringify(err)}`);
      })
      .finally(() => {
        let location = this.props.history.location
        let path = location.pathname
        let isGuestURL = path.match(/\/[^\/]*\/guest\//)
        if (isGuestURL) {
          this.setState({ logged: NOT_LOGGED_STATE, isGuest: true })
          CordovaCommunication.sendData(CordovaCommunication.READY_CHANNEL, true);
        } else {
          this.start();
        }
      });
      CookieStorage.recover();
    });
  }

  componentWillUnmount = () => {
    CordovaCommunication.closeChannel(CordovaCommunication.INITIALCONFIG_CHANNEL);
    CordovaCommunication.closeChannel(CordovaCommunication.MDMCONFIG_CHANNEL);
    CordovaCommunication.closeChannel(CordovaCommunication.READY_CHANNEL);
  }

  componentDidUpdate = (prevProps, prevState) => {
    let loggedStateChanged = prevState.logged !== this.state.logged;
    if (loggedStateChanged) {
      let logged = this.state.logged === LOGGED_STATE;
      CordovaCommunication.sendData(CordovaCommunication.LOGIN, logged);
      setTimeout(() => {
        if (logged) {
          CordovaCommunication.openChannel(CordovaCommunication.NOTIFICATIONS_CHANNEL);
          CordovaCommunication.openChannel(CordovaCommunication.TOKEN_CHANNEL);
        } else {
          CordovaCommunication.closeChannel(CordovaCommunication.NOTIFICATIONS_CHANNEL);
          CordovaCommunication.closeChannel(CordovaCommunication.TOKEN_CHANNEL);
        }
      }, 2000);
    }
    this.callStartIfFirstTimeCommingFromGuestUrl()
  }

  callStartIfFirstTimeCommingFromGuestUrl = () => {
    let location = this.props.history.location
    let path = location.pathname
    let urlIsNowNotGuest = !path.match(/\/[^\/]*\/guest/)
    if (this.state.isGuest && urlIsNowNotGuest) {
      this.setState({ isGuest: false })
      this.start()
    }
  }

  moveTo = async (path) => {
    if (await this.isPathValid(path)) {
      const history = this.props.history
      const allIntermediateStates = this.getAllIntermediateStatesOnHistory(path)
      allIntermediateStates.forEach(state => {
        history.push(state)
      })
    }
  }

  isPathValid = async (path) => {
    const pathRegex = new RegExp(`^/([^/]*)/([^/]*)/([^/]*)/(.*)`)
    const pathMatch = path.match(pathRegex)
    const building = pathMatch[1]
    const unit = pathMatch[2]
    const section = pathMatch[3]
    const subPath = pathMatch[4]
    if (unit !== 'guest') {
      const buildings = await BuildingInfo.getStoredBuildings()
      const foundUserBuilding = buildings.find(b => b.name = building)
      if (foundUserBuilding && section === 'dashboard') {
        return true
      }
    } else {
      return true
    }
    return false
  }

  getAllIntermediateStatesOnHistory = (path) => {
    const pathRegex = new RegExp(`^/([^/]*/[^/]*/[^/]*)/(.*)`)
    const pathMatch = path.match(pathRegex)
    const pathBase = pathMatch[1]
    const subPath = pathMatch[2]

    const subpathRegex = /([^\/]+)/g
    const subpathChildren = subPath.match(subpathRegex) || []

    const intermediateState = subpathChildren.map((p, index) => {
      const ancestorSubpath = subpathChildren.splice(0, index).join('/')
      const ancestorPath = `${pathBase}/${ancestorSubpath ? `${ancestorSubpath}/` : ''}${p}/`
      return ancestorPath
    })
    return intermediateState
  }

  setToken = newToken => { 
      this.setState({ token: newToken })
  }

  start = async (shouldGoDashboard) => {
    if (await SmartRequests.usesOauth()) {
      this.setState({ isDevice:true })
    }
    BuildingInfo.getCurrentBuilding().then(async building => {
      const unit = await BuildingInfo.getCurrentUnit();
      this.setState({
        logged: LOGGED_STATE,
        msg: '',
        unit: unit,
        building: building.name
      })
      if (shouldGoDashboard) {
        this.props.history.push('/' + building.name + '/' + unit + '/dashboard/')
      }
      this.refreshBuildingInfo().then(() => {
        this.setUserLocale()
      })
    }).catch( (error) => {
      console.log(`COULD NOT LOG IN: ${JSON.stringify(error)}`);
      this.setState({ logged: NOT_LOGGED_STATE });
    }).finally(() => {
      CordovaCommunication.sendData(CordovaCommunication.READY_CHANNEL, true);
    });
  }

  login = (user, pass) => {
    
    const params = new URLSearchParams();
    params.append('username', user);
    params.append('password', pass);
    return axios.post('/resident/login', params).then((response) => {
      CookieStorage.store();
      CredentialsStorage.store({user, pass});
      this.refreshBuildingInfo().then(() => {
        this.setUserLocale()
      }).catch((error) => {
        console.log(`Could not log in: ${JSON.stringify(error)}`);
        this.setState({ logged: NOT_LOGGED_STATE });
      });
    }).catch((error) => {
      message.error('Email and password does not match');
      throw error;
    })
  }

  pLessLogin = (activation) => {
    return SmartRequests.loginDevice(activation).then((res) => {
      this.refreshBuildingInfo()
      .then(() => {
        this.setUserLocale()
        CookieStorage.store();
      }) 
      .catch( (error) => {
        throw 'There was an error with the conection.'
      });
    }).catch((error) => {
      throw 'The activation code is incorrect.'
    })
  }

  refreshBuildingInfo = async () => {
    try {
      const building = await BuildingInfo.retriveCurrentBuilding();
      if (building) {
        this.setBuildingInfo(building);
      }
    } catch (error) {
      this.showAuthError();
    }
  }

  setBuildingInfo = (building) => {
    this.setState({
      logged: LOGGED_STATE,
      unit: building.units[0],
      building: building.name,
      displayAuthenticationError: false,
    })
  }

  logout = async () => {
    const tasks = [
      BuildingInfo.dropData(),
      CredentialsStorage.dropData(),
      SmartRequests.logoutDevice(),
      SmartRequests.get('/resident/logout')
    ];
    cancellAllPageFetchingRequests('User logged out');
    this.setState({ 
      logged: NOT_LOGGED_STATE,
      isDevice:false 
    })
    await Promise.all(tasks);
  }

  showAuthError = () => {
    this.setState({
      displayAuthenticationError: true
    });
  }

  dismissAuthError = () => {
    this.setState({
      displayAuthenticationError: false
    });
  }

  setTpMode = tpMode => {
    this.setState({ tpMode: tpMode })
  }

  changeLocale = locale => {
    return this.setState({ locale: locale })
  }

  setUserLocale = async()  => {
    let sessionData = await BuildingInfo.getStoredInfo()
    if (sessionData.locale){
      this.setState({ userLocale: true, locale: sessionData.locale}) 
    }
    else {
      this.setState({ userLocale: true })
    }}

  render(){
    if (this.state.logged === LOGGED_STATE && !this.state.userLocale && !this.state.displayAuthenticationError){
      return <Fetching enabled/>
    }

    var inner = this.state.logged === LOGGED_STATE ? 
      <div>
        <Switch>
          <Route path='/:building/guest/' strict render={ route => 
            <Guest building={route.match.params.building} hash={route.match.params.hash} /> 
          } />
          <Route path='/:building/:unit/dashboard/' strict render={ route => 
            <DashBoard isDevice={this.state.isDevice} tpMode={this.state.tpMode} logoutFunction={this.logout} changeLocale={this.changeLocale} history={route.history} building={route.match.params.building} unit={route.match.params.unit} token={this.state.token} /> 
          } />
          <Redirect to={'/' + this.state.building + '/' + this.state.unit +  "/dashboard/"}/>
        </Switch>
        <LoginError visible={this.state.displayAuthenticationError} logout={this.logout.bind(this)} ignore={this.dismissAuthError} />
      </div>
       :
      <Switch className="class-test">
        <Route path='/:building/guest/' strict render={ route => 
          <Guest building={route.match.params.building} hash={route.match.params.hash} /> 
        } />
        <Route path='/(.*/)?(.*/)?/dashboard/' strict render={() => 
          <Redirect to="/access/login" /> 
        } />
        <Route path='/access'     render={ route => 
          <LogIn pLessLogin={this.pLessLogin} loginFunction={this.login} startFunction={this.start} history={route.history} changeLocale={this.changeLocale} />
        } />
        <Redirect to="/access/login"/>
      </Switch>

    return ([
      <IntlProvider locale={this.state.locale} key={this.state.locale} messages={translations[this.state.locale]} >
        {inner}
      </IntlProvider>,
      <ZoomableImagesRenderer key="zoomable"/>
    ])
  }
} 


export default Main = withRouter( Main )
