import React from 'react';
import { withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
// Clean
import { Switch } from 'react-router-dom'

const TransitionSwitch = withRouter(({ children, location, match, level }) =>  {
  let components = location.pathname.split('/').slice(0,level)
  let key = components.join("/")
  return (
    <TransitionGroup>
      <CSSTransition key={key} appear={true} classNames="slide" timeout={300}>
        <Switch location={location}>
          {children}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
})


export default TransitionSwitch