
import * as CustomStorage from '../../utilities/customStorage'

const COOKIE = 'COOKIE';
const AUTH_COOKIE_NAME = "shine-auth-token-resident";
const COOKIE_PATH = "; Path=/";

class CookieStorage {
  async recover() {
    const cookie = await CustomStorage.getItem(COOKIE);
    if (cookie) {
      window.document.cookie = cookie;
    }
  }

  async store() {
    const authCookie = this.getAuthCookie();
    if (authCookie) {
      const cookie = authCookie + COOKIE_PATH;
      await CustomStorage.setItem(COOKIE, cookie);
    }
  }

  getAuthCookie() {
    const cookieObject = window.document.cookie;
    const cookies = cookieObject.split(';').map(c => c.trim());
    let authCookie;
    for (let i = 0; i < cookies.length && !authCookie; i++) {
      const iteratedCookie = cookies[i];
      if (iteratedCookie.includes(AUTH_COOKIE_NAME)) {
        authCookie = iteratedCookie;
      }
    }
    return authCookie;
  }
}

export default new CookieStorage();
