import React from 'react';
import SmartLink from './SmartLink';
import { getPlatform } from '../utilities/utils'
import { CordovaCommunication } from '../containers/Main/cordovaCommunication'
import { Link } from 'react-router-dom';

class Square extends React.Component {

  _openExternal = (event) => {
    const pageData = this._getParsedData()
    const externalUrl = pageData.externalUrl
    // const externalIOS = pageData.externalIOS

    // if (ios and externalIOS) {

    // } else if (android and externalAndroid) {

    // }
    if (externalUrl) {
      event.preventDefault()
      const platform = getPlatform()
      let target = pageData.target
      if (platform === 'browser') {
        if (target !== '_system') {
          target = '_blank'
        }
        window.open(externalUrl, target)
      } else {
        const data = {
          href: externalUrl,
          target
        }
        CordovaCommunication.sendData('linkOpener', data)
      }
    }
  }

  _getParsedData = () => {
    if (this.props.pageData) {
      let pageData
      try {
        pageData = JSON.parse(this.props.pageData)
        return pageData
      } catch (e) { 
      }
    }
    return {}
  }
  
  render() {
    const className = `square ${this.props.className || this.props.name || ''}`
    const to = `${this.props.to || this.props.name}/`
    return (
        <SmartLink className={className} to={to} level={this.props.level} onClick={this.props.onClick} >
        { this.props.widget && (this.props.widget.value > 0 ? 
                              <p className="widget" id="{this.props.id}_widget">{this.props.widget.value}</p> : 
                               this.props.widget.icons ? 
                                <div className="widget widget-icons">
                                  {this.props.widget.icons}
                                </div> : null
                                )
                             }
        <p className="title">{this.props.title}</p>
        </SmartLink>
      
    )
  }
}

export default Square
