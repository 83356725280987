import { Button, Checkbox, InputNumber, Modal, Progress, Slider, message } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import Header from '../../PageView/Header';
import PageFrame from '../../../components/PageFrame';
import { SmartRequests } from '../../../utilities';
import msg from './messages';
import { Chart } from "react-google-charts";


class PollViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsChecked: [],
      results: null,
      poll: null,
      answered: false,
      isModalVisible: false,
      inputValue: 0,
    };
  }


  componentDidMount() {
    console.log('Mounting PollViewer');
    this.t = this.props.intl.formatMessage;
    this.getDataPoll();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.poll && prevState.poll && this.state.poll.answered !== prevState.poll.answered && this.state.poll.public_results === 1) {
      this.getPollsResults();
    }
  }

  calculateInitialInputValue(ls_min, ls_max) {
    return Number.isInteger((ls_min + ls_max) / 2) ? (ls_min + ls_max) / 2 : Math.ceil((ls_min + ls_max) / 2);
  }

  getDataPoll = () => {
      SmartRequests.get(`poll/${this.props.id_poll}`).then((resp) => {
        if (resp.status === 200) {
          if (resp.data.poll){
            this.setState({inputValue: this.calculateInitialInputValue(resp.data.poll.ls_min, resp.data.poll.ls_max)});
            if ((resp.data.poll.answered || (resp.data.poll.web_status === "finished" && resp.data.poll.already_answered > 0)) && resp.data.poll.public_results === 1){
              this.getPollsResults(resp.data.poll);
            } else {
              this.setState({poll: resp.data.poll})
            }
          }
        }
      })
      .catch((err) => {
        console.log('Error: ' + err);
      });
    }

  getPollsResults = (poll=null) => {
    SmartRequests.get(`poll/${this.props.id_poll}/results`).then((resp) => {
      if (resp.status === 200) {
        if (poll){
          this.setState({ poll, results: resp.data.poll_res})
        }else{
          this.setState({ results: resp.data.poll_res})
        }
        
      }
    })
    .catch((err) => {
      console.log('Error: ' + err);
    });
  }

  onChange = (checkedValues) => {
    this.setState({ optionsChecked: checkedValues });
  };

  onSliderChange = (value) => {
    const { ls_min, ls_max } = this.state.poll;
    if (value >= ls_min && value <= ls_max) {
      this.setState({ inputValue: value });
    }
  };

  getChoices() {
    const { poll } = this.state;
    const choices = [];

    for (let key in poll) {
      if (key.startsWith('choice')) {
        const choiceValue = poll[key];
        const choiceIndex = parseInt(key.slice(-1));

        choices.push({ label: choiceValue, value: choiceIndex });
      }
    }
    return choices;
  }

  someOptionSelected = () => {
    return this.state.optionsChecked.length > 0;
  }

  submitPoll = (id_poll) => {
    let data = {};
    let answer = '';
    const { type_of_poll, multiple_answers, ls_min } = this.state.poll;

    if (type_of_poll === 'multiple_choice') {
      if (multiple_answers) {
        answer = this.state.optionsChecked.join(',');
      } else {
        answer = this.state.optionsChecked.length > 0 ? this.state.optionsChecked[0] : '';
      }
    } else {
        answer = String(this.state.inputValue || ls_min);
    }
    data = { answer: answer };
    SmartRequests.post(`poll/${id_poll}`, data).then((resp) => {
      if (resp.status === 200) {
        this.props.updatePolls(id_poll);
        this.setState({isModalVisible: false});
        this.setState({poll: {...this.state.poll, answered: 1}})
        this.props.subtractVoteForNotResponding();
        message.success(this.t(msg.pollSuccessfully));
      }
    })
    .catch((err) => {
      console.log('Error: ' + err);
    });
    
  };

  renderDescMultipleChoice = (answered, web_status) => {
    if (web_status === "sent" && !this.state.answered && answered === 0){
      if (this.state.poll.multiple_answers){
        return <p>{this.props.intl.formatMessage(msg.ChooseSomeOptions)}</p>
      } else {
          return <p>{this.props.intl.formatMessage(msg.ChooseSingleOption)}</p>
      }
    } else {
      if (web_status === "finished"){
        return <p>{this.props.intl.formatMessage(msg.pollResultsMessage)}</p>
      } else {
        return <p>{this.props.intl.formatMessage(msg.pollResultsMessageIncludingYourResponse)}</p>
      }
    }
  }

  renderDescLinearScale = (answered, ls_min, ls_max, web_status, already_answered) => {
    if (web_status === "sent"){
      if (!this.state.answered && answered === 0){
        return <p>{this.props.intl.formatMessage(msg.rangeInstruction) + ` (${ls_min } - ${ls_max})`}</p>
      } else {
        return <p>{this.props.intl.formatMessage(msg.pollResultsMessageIncludingYourResponse)}</p>
      }
    } else {
      if (already_answered > 0){
        return <p>{this.props.intl.formatMessage(msg.pollResultsMessage)}</p>
      } else {
        return null;
      }
    }
  }

  getTotal = (propertyName) => {
    if (this.state.results) {
      const sum = this.state.results.reduce((total, result) => total + result[propertyName], 0);
      return sum;
    } else {
      return 0;
    }
  }
  
  getTotalVotes = () => {
    return this.getTotal('value');
  }
  
  getTotalSumOfWeights = () => {
    return this.getTotal('sum_weights');
  }
  
  findValueByAnswer = (answerToFind) => {
    if (this.state.results && this.state.results.length > 0 && Array.isArray(this.state.results)) {
      const foundResult = this.state.results.find(result => result.answer === answerToFind);
      return foundResult ? foundResult.value : 0;
    } else {
      return 0;
    }
  }

  findSumWeightByAnswer = (answerToFind) => {
    if (this.state.results && this.state.results.length > 0 && Array.isArray(this.state.results)) {
      const foundResult = this.state.results.find(result => result.answer === answerToFind);
      return foundResult ? foundResult.sum_weights : 0;
    } else {
      return 0;
    }
  }
  
  renderMultipleChoiceResults = (max_choices, already_answered, answered) => {
    let hasSumOfWeights = this.state.poll.use_unit_weight;
    let totalElements = hasSumOfWeights ? this.getTotalSumOfWeights() : this.getTotalVotes();

    const progressComponents = [];
    const choices = this.getChoices();

    for (let i = 1; i <= max_choices; i++) {
      let percent = 0;
      if (already_answered > 0 || answered === 1){
        let valueOfAnswer = hasSumOfWeights ? this.findSumWeightByAnswer(i) : this.findValueByAnswer(i);
        percent = ((valueOfAnswer/totalElements)*100);
        percent = Number.isInteger(percent) ? percent : percent.toFixed(2);
      }
      progressComponents.push(
        <React.Fragment key={i}>
          <p style={{margin: "0"}}>{choices[i-1].label}</p>
          <Progress style={{marginBottom: "1rem"}} percent={percent} size="default" />
        </React.Fragment>
      );
    }
  
    return (
      <div>
        {progressComponents}
      </div>
    );
  }

  renderMultipleChoice = (multiple_answers) => {
    return (
      <Checkbox.Group
        className='container-checkbox-group'
        onChange={this.onChange}
        value={this.state.optionsChecked}
      >
        {this.getChoices().map((choice) => (
          <Checkbox
            className='container-checkbox-item'
            key={choice.value}
            value={choice.value}
            disabled={!multiple_answers && this.state.optionsChecked.length > 0 && this.state.optionsChecked[0] !== choice.value}
          >
            {choice.label}
          </Checkbox>
        ))}
      </Checkbox.Group>
    )
  }

  renderLinearScale = (ls_min, ls_max) => {
    
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Slider min={ls_min} max={ls_max} value={this.state.inputValue} onChange={this.onSliderChange} style={{ flex: 1 }}/>
        <InputNumber
            min={ls_min}
            max={ls_max}
            step={1}
            value={this.state.inputValue}
            onChange={this.onSliderChange}
            style={{ marginLeft: 10 }}
        />
      </div>
    )
  }

  renderLinearScaleResults = (already_answered, answered) => {
    let hasSumOfWeights = this.state.poll.use_unit_weight;

    let data = [
      [this.props.intl.formatMessage(msg.VotingOptions), this.props.intl.formatMessage(msg.Votes)],
    ];

    if (hasSumOfWeights) {
      data[0].push(this.props.intl.formatMessage(msg.weightedValue));
    }

    if (this.state.results){
      this.state.results.forEach(vote => {
        let year = vote.answer;
        let votes = Math.floor(vote.value);
        if (hasSumOfWeights) {
          let sumOfWeights = parseFloat(vote.sum_weights.toFixed(2));
          data.push([year, votes, sumOfWeights]);
        } else {
          data.push([year, votes]);
        }
      });
    }

    if (already_answered > 0 || answered === 1) {
      return (
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="300px"
          data={data}
        />
      );
    } else {
      return null;
    }
  };
  

  handleCancelModal = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  renderContentModal = () => {
    return (
      <React.Fragment>
        <div>
          <p>{this.t(msg.confirmationMessage)}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem'}}>
          <Button type='danger' onClick={() => this.handleCancelModal()}>{this.t(msg.cancel)}</Button>
          <Button type='primary' onClick={() => this.submitPoll(this.props.id_poll)}>{this.t(msg.confirm)}</Button>
        </div>
      </React.Fragment>
    )
  }

  verifyFieldsRequired = (type_of_poll) => {
    if (type_of_poll === 'multiple_choice' && !this.someOptionSelected()){
      message.error(this.t(msg.selectAtLeastOneOption));
    } else {
      this.setState({isModalVisible: true})
    }
  }

  render() {
    const t = this.props.intl.formatMessage;

    if (this.state.poll) {
      const { type_of_poll, title, description, web_status, answered, already_answered, ls_min, ls_max, multiple_answers, max_choices, public_results } = this.state.poll;

      let showMultipleChoice = web_status === "sent" && !this.state.answered && answered === 0 && type_of_poll === 'multiple_choice';
      let showLinearScale = web_status === "sent" && !this.state.answered && answered === 0 && type_of_poll === 'linear_scale';
      let showPollResultsMultipleChoice = (web_status === "finished" || this.state.answered || answered === 1) && type_of_poll === 'multiple_choice';
      let showPollResultsLinearScale = (web_status === "finished" || this.state.answered || answered === 1) && type_of_poll === 'linear_scale';
      let showSaveButton = web_status === "sent" && !this.state.answered && answered === 0;
      let showCloseButton = web_status === "finished" || this.state.answered || answered === 1;
      let noResponsesPoll = web_status === "finished" && already_answered === 0;

      let showNoResults = (showPollResultsMultipleChoice || showPollResultsLinearScale) && public_results === 0;

      let showDescLinearScale = type_of_poll === 'linear_scale' && !showNoResults;
      let showDescMultipleChoice = type_of_poll === 'multiple_choice' && !showNoResults;

      return (
        <PageFrame>
          <Header pageInfo={{ content: null }}>{title}</Header>
          {<div className='container-body-poll'>
            <div className='container-content-poll'>
              <div className='container-desc'>
                <h2>{description}</h2>

                {showDescLinearScale && this.renderDescLinearScale(answered, ls_min, ls_max, web_status, already_answered)}

                {showDescMultipleChoice && this.renderDescMultipleChoice(answered, web_status)}
                
              </div>

              {showMultipleChoice && this.renderMultipleChoice(multiple_answers)}

              {showPollResultsMultipleChoice && public_results === 1 && this.renderMultipleChoiceResults(max_choices, already_answered, answered)}

              {showLinearScale && this.renderLinearScale(ls_min, ls_max)}

              {showPollResultsLinearScale && public_results === 1 && this.renderLinearScaleResults(answered, already_answered)}

              {showNoResults && <p>{t(msg.resultsVisibilityEnabled)}</p>}

              {showSaveButton && 
                <div>
                  <Button type='primary' onClick={() => this.verifyFieldsRequired(type_of_poll)}>{t(msg.save)}</Button>
                </div>
              }

              {showCloseButton && 
                <div>
                  {noResponsesPoll && 
                    <div>
                      <p>{t(msg.NoResponsesPoll)}</p>
                    </div>
                  }
                  <Button type='primary' onClick={() => this.props.history.goBack()}>{t(msg.close)}</Button>
                </div>
              }


              {this.state.isModalVisible &&
                <Modal
                  title= {t(msg.confirmPoll)}
                  onCancel={this.handleCancelModal}
                  visible={this.state.isModalVisible}
                  footer={null}
                >
                  {this.renderContentModal()}
                </Modal>
              }

            </div>
          </div>
          }
        </PageFrame>
      );
    } else {
      return null;
    }
  }
}

export default injectIntl(withRouter(PollViewer));
