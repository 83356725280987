import React from 'react';

import { CordovaCommunication } from '../Main/cordovaCommunication';

const VERSION_LISTENERS = [ ];
let VERSION_VALUE = null;

function versionValueListener(value) {
  console.log(`Version value received: ${value}`);
  VERSION_VALUE = value;
  VERSION_LISTENERS.forEach(setVersion => {
    setVersion(value);
  });
}

function readFrontendVersion() {
  try {
    const version = require('./v').default;
    return version;
  } catch (e) { }
}

class Version extends React.Component {
  state = { 
    nativeVersion: null,
    frontendVersion: null,
  }

  componentDidMount = () => {
    VERSION_LISTENERS.push(this.setNativeVersion);
    this.setNativeVersion(VERSION_VALUE);
    this.setFrontendVersion(readFrontendVersion());
  }

  render = () => {
    let versionString = '';
    if (this.state.nativeVersion) {
      versionString += `version: ${this.state.nativeVersion.version} (${this.state.nativeVersion.hash} - ${this.state.frontendVersion.version}:${this.state.frontendVersion.hash})`;
    }
    return <span className="version-displayer"> {versionString} </span>;
  }

  setNativeVersion = (newVersion) => {
    this.setState({nativeVersion: newVersion});
  }

  setFrontendVersion = (newVersion) => {
    this.setState({frontendVersion: newVersion});
  }
}

export { versionValueListener };
export { Version };
