import React from 'react'
import { ZoomInOutlined } from '@ant-design/icons'

let setRendererImage = (src) => { console.log(`Images renderer not yet mounted! ${src}`) }

export class ZoomableImage extends React.PureComponent {
    _enable = () => {
        setRendererImage(this.props.src)
    }
    
    _disable = () => {
        setRendererImage(null)
    }

    componentWillMount = () => {
        this.ref = React.createRef()
    }

    componentDidMount = () => {
        this.ref.current.addEventListener('click', this._enable)
    }

    render = () => {
        const { src, alt, width, height } = this.props;
        return (
            <div 
                ref={this.ref} 
                className="zoomable-image"
                style={{ 
                    width: width || '100%', 
                    height: height || '100%', 
                    position: 'relative',
                    overflow: 'hidden'
                }}
            >
                <img 
                    src={src} 
                    alt={alt} 
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
                <span 
                    className="zoomable-visible-flag"
                    style={{
                        position: 'absolute',
                        bottom: '5px',
                        right: '5px',
                        background: 'rgba(0,0,0,0.5)',
                        padding: '2px',
                        borderRadius: '40%'
                    }}
                > 
                    <ZoomInOutlined style={{color: 'white'}} /> 
                </span>
            </div>
        )
    }
}

export function setRendererInstance(instance) {
    setRendererImage = instance._setRendererImage
}