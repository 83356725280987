import React from 'react';
import SignalButton from './SignalButton';
import { SmartRequests } from '../../../utilities/index.js';

class Zone extends React.Component {

  sendSignal(signal) {
    var req = (this.props.isKhimo) ?
      SmartRequests.post('khimo', { signal }) :
      SmartRequests.post('command/' + signal, { })
    return req
  }

  render() {
    return (
      <div className="zone">
        <h3>{this.props.name}</h3>
        {
          Object.keys(this.props.commands).map(
            (signalIndex, index) => (
              <SignalButton
                key={'signalbutton-'+index}
                signal={this.props.commands[signalIndex].id}
                displayName={this.props.commands[signalIndex].display_name}
                sendSignal={this.sendSignal.bind(this)}
              />
            )
          )
        }
      </div>
    );
  }
}

export default Zone