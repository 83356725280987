import React, { Component } from 'react';

import { Icon } from 'antd'
import { Spin } from 'antd'
import { Tooltip } from 'antd'

import { injectIntl } from "react-intl";
import {getUrl} from '../../../utilities/smartRequests/requestQueueItem.js'

import * as utils from '../../../utilities/utils.js'

import msg from './messages'

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'




function showDeliveryEvents() {
  const t = this.props.intl.formatMessage
  const rootEl = document.getElementsByClassName("inner-dashboard")[0]
  //document.getElementById("root")
  if (this.state.events.length > 0)
    return (<div className="events">{ 
        this.state.events.map( (e) => {
            let eventName = e && e.event || "unknown"
            if (msg[e.event]) {
              eventName = t(msg[e.event])
            } else {
              console.error("Event without translation: ", eventName)
            }
            return <div className="event">
          <Tooltip placement="topLeft" detailstitle={JSON.stringify(e, null, 2)}>
          { eventName } - {e.fmt_date}
          {e.event_photo && (
              <Zoom   
              overlayBgColorEnd="rgba(255, 255, 255, 0.95)"
              overlayBgColorStart="rgba(255, 255, 255, 0)"
              transitionDuration={400}
              portalEl={rootEl}
              wrapElement="span"
              zoomMargin={1}
              zoomZindex={2147483648}
              wrapStyle={{float: 'right'}} 
              >
                  <img src={e.event_photo} width={100} />
              </Zoom>) ||
              e.signature_id && (
            <Zoom   
            overlayBgColorEnd="rgba(255, 255, 255, 0.95)"
            overlayBgColorStart="rgba(255, 255, 255, 0)"
            transitionDuration={400}
            portalEl={rootEl}
            wrapElement="span"
            zoomMargin={1}
            zoomZindex={2147483648}
            wrapStyle={{float: 'right'}} 
            >
              {this.state.fullUrl && (
                <img src={this.state.fullUrl+'deliveries/'+e.signature_id+'.png'} width={100} />
              ) || ("Loading..." )}
                </Zoom>)}
          </Tooltip>
          </div>
        }) 
        } </div>)
  else 
    return (<div> -- </div>)
  }



class DeliveryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { 'events': false, 'fullUrl': false};
    this.state.load = this.loadDetails();
  }
  async loadDetails() {
    const URL = 'deliveries/'+this.props.delivery.id+'/events'
    this.setState({
      'fullUrl': await getUrl()
    });
    let that = this
    return utils.get(this.props.reqData, URL).then(function(resp){
        if (resp && resp.data) {
          that.setState({'events':resp.data});
      } else {
          that.setState({'events':null});
          //FIXME: ERROR MESSAGE
        }
      });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.delivery.id !== this.props.delivery.id) {
      console.log('[DeliveryDetails:componentDidUpdate()] Changing delivery')
      this.setState({
          'events': false,
          'loading': this.loadDetails()
        });
    }
  }
  render() {
    const t = this.props.intl.formatMessage
    const del = this.props.delivery
      return (
        <div className="item-selector-container" >
          <h1 className='item-list-title'> { t(msg.from) + ' ' + del.sender + '(' + del.tn + ')' } </h1>
          <Spin spinning={! this.state.events} delay={500} style={{"min-width":"100%"}}>
           { 
             ( this.state.events && showDeliveryEvents.bind(this)() )
           }
           </Spin>
        </div>
      );
    }
}

export default DeliveryDetails = injectIntl(DeliveryDetails)
