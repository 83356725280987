import * as CustomStorage from '../../utilities/customStorage'
import { isNativeStorageSupported } from '../../utilities/utils';

const USER = 'USER';
const PASS = 'PASS';

export const store = async ({user, pass}) => {
  if (!isNativeStorageSupported()) return;
  await Promise.all([
    CustomStorage.setItem(USER, user),
    CustomStorage.setItem(PASS, pass)
  ]);
}

export const recover = async () => {
  const user = await CustomStorage.getItem(USER);
  const pass = await CustomStorage.getItem(PASS);
  const alreadyStored = !!(user && pass);
  const recovered = alreadyStored ? {user, pass} : { };
  return recovered;
}

export const dropData = async () => {
  await Promise.all([
    CustomStorage.removeItem(USER),
    CustomStorage.removeItem(PASS),
    CustomStorage.removeItem('COOKIE')
  ]);
}
