import React from 'react';
import { Link } from 'react-router-dom'

import { Icon } from 'antd'

class Header extends React.PureComponent{
  render() {
    let img = ''
    try{
      img = <img height='45px' src={require('../../images/logos/' + this.props.building + '.png')} alt={'building'}/>
    } catch(e) {
    }

    return (
      <div className='page-header'> 
        <div className='top'>
          {img}
        </div>
        <h1 className='title'>{this.props.children}</h1> 
      </div>
    )
  }
}

export default Header