import React, { Component } from 'react';
import * as moment from 'moment'
import { reservations, dateHelpers } from './helpers';
import { RESERVED_FOR_ME, RESERVED_FOR_CLOSURE, RESERVED_FOR_ANOTHER, RESERVED_FOR_NOBODY } from './helpers';


import LoadingComponent from '../../../components/LoadingComponent';

import { injectIntl } from "react-intl";

import msg from './messages'


class AvailabilityMessage extends Component {

  render() {
    const t = this.props.translator
    if (this.props.isBlockedDOW) {
      return (<span> {t(msg.reservationUnavailable)} </span>);
    } else if (this.props.reservationLevel === RESERVED_FOR_CLOSURE) {
      return (<span> {t(msg.closed)} </span>);
    } else if (this.props.isBlockedByConsecutive) {
      return (<span> {t(msg.consecutiveNotAllowed)} </span>);
    } else if (this.props.isTurnLoading) {
      return <LoadingComponent isLoading={true} />
    } else if (this.props.reservationLevel === RESERVED_FOR_ME) {
      if (this.props.date.isBefore(moment())) {
        return (<span> {t(msg.wasReservedForYou)} </span>);
      } else {
        return (<span> {t(msg.reservedForYou)} </span>);
      }
    } else if (this.props.reservationLevel === RESERVED_FOR_ANOTHER) {
      if (this.props.date.isBefore(moment())) {
        return (<span> {t(msg.reservationUnavailable)} </span>);
      } else {
        return (<span> {t(msg.reservedForAnother)} </span>);
      }
    } else if (
      (
        moment().add(this.props.minAdvance, this.props.minUnit).isSameOrAfter(this.props.date) //this.props.date.clone().add(this.props.minAdvance, 'days').isBefore(moment()) 
        || (this.props.maxAdvance != 0 && moment().add(this.props.maxAdvance, 'days').startOf('day').isSameOrBefore(this.props.date.clone().startOf('day'))) // this.props.date.clone().add(-this.props.maxAdvance, 'days').isAfter(moment())
      )
    ) {
      return (<span> {t(msg.reservationUnavailable)} </span>);
    }
    return (                      
      <span className="until">
        { t(msg.until) + ' ' + dateHelpers.getEnd(this.props.date, this.props.period).format('hh:mm A') }
      </span>
    );
  }
}

class CalendarCell extends Component {
  constructor(props) {
    super(props);
    this.getReservationLevel = this.getReservationLevel.bind(this);
    this.isReservedForMe = this.isReservedForMe.bind(this);
  }

  clickEventHandler(isLastStage) {
    
    if (!this.props.isBlocked) {
      var eventData = {};
      eventData.type = 'date-chosen';
      eventData.hour = this.props.date;
      console.log('[amenities:CalendarCell.clickEventHandler()] Date selected: '+eventData.hour);
      if (this.isReservedForMe() && isLastStage) {
        console.log('[amenities:CalendarCell.clickEventHandler()] Reservation cancelled');
        eventData.type = 'cancel';
      }
      this.props.AddRequestData(eventData);
    }
  }

  takeFocus() {
    console.log('[amenities:CalendarCell.takeFocus()] Day '+this.props.dayIndex+' has requested to toggle focus');
    this.props.onClick(this.props.dayIndex);
  }

  isBlocked() {
    const isDateBeforeMinAvailableDay = moment().add(this.props.groupData.min_time_in_advance, this.props.groupData.min_advance_unit).isSameOrAfter(this.props.date);
    const isDateAfterMaxAvailableDay = (this.props.groupData.max_days_in_advance != 0 && moment().add(this.props.groupData.max_days_in_advance, 'days').startOf('day').isSameOrBefore(this.props.date.clone().startOf('day')));
    var isBlocked = (
      !this.props.date
      || this.props.isBlocked
      || this.props.isBlockedByConsecutive
      || (this.getReservationLevel() === RESERVED_FOR_CLOSURE)
      || this.props.isBlockedDOW
      || (isDateBeforeMinAvailableDay || isDateAfterMaxAvailableDay)
    );
    return isBlocked;
  }

  getReservationLevel() {
    let reservationLevel = RESERVED_FOR_NOBODY;
    let dayIndex = dateHelpers.serializeDay(this.props.date);
    let hourIndex = dateHelpers.serializeHour(this.props.date);
    let isAvailabilityReady = this.props.availability && this.props.availability[dayIndex] && this.props.availability[dayIndex][hourIndex];
    if (isAvailabilityReady) {
      var availability = this.props.availability[dayIndex][hourIndex];
      var skip = false;
      //give more importance to RESERVED_FOR_ME and RESERVED_FOR_NOBODY instead of only the last one found
      for (var itemID in availability) {
        if (availability[itemID] === RESERVED_FOR_ME) {
          reservationLevel = RESERVED_FOR_ME;
          break;
        } else if ([RESERVED_FOR_CLOSURE, RESERVED_FOR_ANOTHER].includes(availability[itemID])) {
          if (skip) {
            continue
          }
          reservationLevel = reservationLevel || availability[itemID]; // if no reservation found, then set to the one just found
        } else if (availability[itemID] === RESERVED_FOR_NOBODY) {
          reservationLevel = RESERVED_FOR_NOBODY; 
          skip=true //(FOR BETTER USER EXPERIENCE)
          //once RESERVED_FOR_NOBODY is set the only next possible lvl is RESERVED_FOR_ME
        }
      }
    }
    return reservationLevel;
  }

  isReservedForMe() {
    return this.getReservationLevel() === RESERVED_FOR_ME;
  }

  getCellStyle() {
    if (this.props.isTurnLoading) {
      return 'loading';
    }
    return reservations.getStyledClass(
      this.getReservationLevel(),
      this.isBlocked()
    );
  }
  
  render() {
    return (
        <div className={"calendar-cell " + this.getCellStyle()}
              onClick={
                        (
                          (this.props.isItemSelectorRequired || !this.props.isAllDayReservable)
                          && !this.isBlocked()
                          && !this.props.isTurnLoading
                        )
                        ? () => (
                            this.clickEventHandler(this.props.groupData.items.length === 1 && this.props.groupData.items[0].amount === 0)
                          )
                        : null
                      }
        >
        <div className="date">
          <div>
            <span className="from">
              {
                this.props.date.format('hh:mm A')
              }
            </span>
            <br/>
            {
              <AvailabilityMessage 
                date={this.props.date} 
                reservationLevel={this.getReservationLevel()} 
                minAdvance={this.props.groupData.min_time_in_advance}
                maxAdvance={this.props.groupData.max_days_in_advance}
                minUnit={this.props.groupData.min_advance_unit}
                period={this.props.groupData.period}
                isBlockedDOW={this.props.isBlockedDOW}
                isBlockedByConsecutive={this.props.isBlockedByConsecutive}
                isTurnLoading={this.props.isTurnLoading}
                translator={this.props.intl.formatMessage}
              />
            }
          </div>
        </div> 
      </div>
    );
  }
}

export default CalendarCell = injectIntl(CalendarCell)