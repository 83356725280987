import React from 'react'


class Box extends React.Component{
    render(){
        const className = `container-box ${this.props.className || ''}`
        return (
            <div className={className}>
                {this.props.children}
            </div>
        )
    }
}

export default Box