import React from 'react';

export class Header extends React.Component{
  render(){
    return (
      <div className='header'>
        <h1> {this.props.children} </h1>
      </div>
    )
  }
}

export default Header