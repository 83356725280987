import React from 'react';

function UnitChooser(units, setUnit) {
  // If there is more than one building
  if ((new Set(units.map(u => u[0]))).size > 1) {
    return units.map( unit => <li key={unit[0].display_name + unit[1]} onClick={() => setUnit(...unit)} > {unit[0].display_name} - {unit[1]} </li>)
  } else {
    return units.map( unit => <li key={unit[1]} onClick={() => setUnit(...unit)}> {unit[1]} </li>)
  }
}

export default UnitChooser;