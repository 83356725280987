import { injectIntl, defineMessages } from "react-intl";

const msg = defineMessages({
  
  ops: {
    id: 'errorPage.ops',
    defaultMessage: 'Oops!'
  },
  
  error: {
    'id':'errorPage.error',
    'defaultMessage': 'Our apologies. We\'re unable to find the page you\'re looking for.'
  }
  
})


export default msg
