import { VERSION } from '../../../utilities/utils'
import { SmartRequests } from '../../../utilities'

const URL = '/resident/guest/ws/' + VERSION + '/vehicles/'

export function get_vehicle (building, hash) {
  return SmartRequests.get('/' + building + URL + hash)
}

export function post_vehicle_event (building, hash, event) {
  return SmartRequests.post(
    '/' + building + URL + hash,
    {
      event: event
    }
  )
}


export function get_buildings () {
  return SmartRequests.get('/account/buildings')
} 
