import React from 'react';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router-dom';
import { Button, List, Modal, Typography, Divider, Radio, Spin, Input, Dropdown, Menu } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import moment from 'moment-timezone';
import msg from './messages.js';
const { Text, Title } = Typography;

class ConfirmOrderModal extends React.Component {

    constructor(props) {
      super(props);
      this.hasDelivery = this.props.optional_states.includes('ready_for_deliver')
      this.hasPickup = this.props.optional_states.includes('ready_for_pickup')
      this.state = {
        currentStep: 1,
        receive_selected: this.hasDelivery && this.hasPickup? null : this.hasDelivery ? 'ready_for_deliver' : 'ready_for_pickup',
        receive_deliver_selected: null, 
        loading: false,
        errorSelectReadyForDeliverOrPickup: false,
        errorSelectDeliverPlace: false,
        notes: null
      };
    }

    handleReceiveChange = e => {
      this.setState({
        receive_selected: e.target.value,
        receive_deliver_selected: null, // Reset if changing between delivery/pickup
      });
    };
    
    componentDidMount() {
      const building_timezone = this.props.reqData.building.timezone
      const now = moment.tz(building_timezone)
      const openTime = this.props.openTime
      const closeTime = this.props.closeTime
      let isOpen;
      if (openTime < closeTime) {
        isOpen = now.isBetween(openTime, closeTime)
      }else{
        isOpen = !now.isBetween(closeTime, openTime)
      }
      this.setState({isOpen})
    }

    handleDeliverPlaceChange = (value, type, index) => {
      console.log('handleDeliverPlaceChange', value, type, index)
      this.setState((prevState) => {
        // Create a shallow copy of the current array up to the specified index, removes whatever is after the index
        if (!prevState.receive_deliver_selected) {
          return {
            receive_deliver_selected: [{ value, type }],
          };
        }
        const newReceiveDeliverSelected = prevState.receive_deliver_selected.slice(0, index);
        
        // Set the new value and type at the specified index
        newReceiveDeliverSelected[index] = { value, type };
        
        return {
          receive_deliver_selected: newReceiveDeliverSelected,
        };
      });
    };
  
    handleOk = (e) => {
      const { receive_selected, receive_deliver_selected, notes } = this.state;
      e.currentTarget.disabled = true;
  
      let finalSelectedPlace = '';
      if (receive_selected === 'ready_for_deliver') {
        let errorToSet = false;
        if(!receive_deliver_selected){
          errorToSet = true;
        }else{
          receive_deliver_selected.forEach((place, index) => {
            // Check if the last item is of type 'nested'
              if (index === receive_deliver_selected.length - 1 && place.type === 'nested') {
              errorToSet = true;
            }
            else if (index===0) {
              finalSelectedPlace = place.value;
            }else{
              finalSelectedPlace += ` - ${place.value}`;
            }
          });
        }
        if (errorToSet) {
          this.setState({ errorSelectDeliverPlace: true }, () => {
            setTimeout(() => {
              this.setState({ errorSelectDeliverPlace: false });
            }, 1500);
          });
          e.currentTarget.disabled = false;
          return;
        } 
      }
  
      if (receive_selected) {
        this.setState({ loading: true });
        receive_selected === 'ready_for_deliver' ? 
          this.props.onOk(receive_selected, notes, finalSelectedPlace) : 
          this.props.onOk(receive_selected, notes);
      } else {
        e.currentTarget.disabled = false;
        this.setState({ errorSelectReadyForDeliverOrPickup: true }, () => {
          setTimeout(() => {
            this.setState({ errorSelectReadyForDeliverOrPickup: false });
          }, 1500);
        });
      }
    };

    getDeliveryOptions = (places, unit, translate, index) => (
      <Menu>
        {places.map((place) => {
          if (typeof place === 'string') {
            if (place === 'own_unit') {
              // FIXME: VALIDATE USER IS NOT A GUEST
              return (
                <Menu.Item key={place} onClick={() => this.handleDeliverPlaceChange(place, 'string', index)}>
                  {translate(msg.own_unit, { unit })}
                </Menu.Item>
              );
            }
            return (
              <Menu.Item key={place} onClick={() => this.handleDeliverPlaceChange(place, 'string', index)}>
                {place}
              </Menu.Item>
            );
          } else if (typeof place === 'object') {
            const placeName = Object.keys(place)[0];
            return (
              <Menu.Item key={placeName} onClick={() => this.handleDeliverPlaceChange(placeName, 'nested', index)}>
                {placeName}
              </Menu.Item>
            );
          }
          return null;
        })}
      </Menu>
    );

    setDeliveryOptions = (deliver_places, unit, translate, recursionIndex = 0) => {
      const selectedIndexContent = this.state.receive_deliver_selected && this.state.receive_deliver_selected[recursionIndex]
      return (
        <div style={{marginTop:'1rem'}}
        className={this.state.errorSelectDeliverPlace ? 'title-jump-animation' : ''}
        >
          <Dropdown overlay={this.getDeliveryOptions(deliver_places, unit, translate, recursionIndex)} placement="bottomLeft">
            <button
              type="button"
              className="ant-btn ant-btn-default ant-dropdown-trigger"
              style={selectedIndexContent? {width: '100%', borderColor: '#1890ff'} : {width: '100%'} }
              >
              {selectedIndexContent ? (
                selectedIndexContent.value=='own_unit'?
                  translate(msg.own_unit, { unit })
                :
                  selectedIndexContent.value
                )  :
                translate(msg.selectDeliveryPlace)
              }
            </button>
          </Dropdown>
          {selectedIndexContent && selectedIndexContent.type == 'nested'  && (
            <>
            <div style={{ marginTop: '1rem', textAlign: 'center', fontWeight: 'bold' }} 
            >
              {translate(msg.nested_place, { place_name: selectedIndexContent.value })}
            </div>
              {
              this.setDeliveryOptions(
                (() => {
                  const place = deliver_places.find(
                    place => typeof place === 'object' && Object.keys(place)[0] === selectedIndexContent.value
                  );
                  if (place) {
                    return place[selectedIndexContent.value];
                  }
                  return [];
                })(),
              unit,
              translate,
              recursionIndex + 1
              )
            }
            </>
          )}
        </div>
      )
    }
    renderStep1 = (translate) => (
      <div>
        <List
          dataSource={this.props.order}
          renderItem={(dish) => (
            <List.Item style={{marginRight: '1vw'}}>
              <List.Item.Meta
                title={
                  <Text>
                    <span style={{color: 'black', fontSize: 'medium'}}>
                      {dish.quantity > 1 ? `${dish.quantity} x ` : ''}
                      {dish.name[this.props.currentLang] || dish.name[this.props.defaultLang]}
                    </span>
                    <span style={{float: 'inline-end'}}>${dish.quantity * dish.price.toFixed(2)}</span>
                  </Text>
                }
                description={
                  <>
                    {dish.selectedCondiments != null && Object.entries(dish.selectedCondiments).length > 0 && (
                      Object.entries(dish.selectedCondiments).map(([key, value]) => (
                        value.selected.length > 0 && (
                          <div key={key}>
                            <Text strong>{value.group_name[this.props.currentLang] || value.group_name[this.props.defaultLang]}: </Text>
                            <Text>{value.selected.map(sel_name => sel_name[this.props.currentLang] || sel_name[this.props.defaultLang]).join(', ')}</Text>
                          </div>
                        )
                      ))
                    )}
                    {dish.selectedExtras.length > 0 && (
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Text strong>Extras: </Text>
                        {dish.selectedExtras.map(extra => {
                          const extraText = `   - ${(extra.name[this.props.currentLang] || extra.name[this.props.defaultLang])}: +$${extra.price.toFixed(2)}`;
                          return <Text key={extra.name[this.props.defaultLang]}>{extraText}</Text>;
                        })}
                      </div>
                    )}
                  </>
                }
              />
            </List.Item>
          )}
        />
        <Divider />
      </div>
    );

    renderStep2 = (translate) => (
      <div>
        {(this.hasDelivery && this.hasPickup) ? (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Radio.Group 
              onChange={this.handleReceiveChange} 
              value={this.state.receive_selected} 
              optionType="button" 
              style={{display: 'flex', gap: '10px', fontWeight: 'bold'}}
              className={this.state.errorSelectReadyForDeliverOrPickup ? 'title-jump-animation' : ''}
            >
              {this.hasPickup && 
                <Radio.Button value='ready_for_pickup' style={{ whiteSpace: 'normal', height: 'auto'}}> 
                  {translate(msg.pickupButton, {'res_name': this.props.restaurant_title})}
                </Radio.Button>
              }
              {this.hasDelivery && (
                <Radio.Button value='ready_for_deliver' style={{ whiteSpace: 'normal', height: 'auto' }}>
                  {translate(msg.deliveryButton)}
                </Radio.Button>
              )}
            </Radio.Group> 
          </div>
        ) : (this.hasPickup) ? (
          <div>
            <h3>{translate(msg.pickupButtonOnly, {'res_name': this.props.restaurant_title})}</h3>
          </div>
        ) : (
          <div>
            <h3>{translate(msg.deliveryButtonOnly)}</h3>
          </div> 
        )}
        {this.state.receive_selected === 'ready_for_deliver' && 
          this.setDeliveryOptions(this.props.deliver_places, this.props.unit, translate)}
        <Input.TextArea
          value={this.state.notes}
          onChange={(e) => this.setState({notes: e.target.value})}
          placeholder={
            translate(this.state.receive_selected === 'ready_for_deliver' ? msg.deliveryNotes : msg.pickupNotes)
          }
          style={{marginTop: '2rem', marginBottom: '1rem', minHeight: '80px'}}
        />
      </div>
    );

   

    render() {
      const { order, visible, defaultLang, currentLang, totalPrice, deliver_places, unit, openTime, closeTime,  subTitleOpenHours, waitingTime, isMobile } = this.props;

      const translate = this.props.intl.formatMessage // Get translation function from props
      const modalTitle = this.state.isOpen ? 
                                  translate(msg.confirmOrderTitle) + ' (' + waitingTime + ')' :
                                  translate(msg.restaurantClosed)
      return (
        <Modal
          title={
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between'}}>
              <div>
                <Title level={4}>{modalTitle}</Title>
                <Text type="secondary">{subTitleOpenHours}</Text>
              </div>
              <CloseOutlined onClick={() => this.props.onCancel()} />
            </div>
          }
          visible={visible}
          onCancel={() => {this.props.onCancel()}}
          onOk={(e) => this.handleOk(e)}
          okText={translate(msg.confirmOrderButton)}
          cancelText={translate(msg.cancel)}
          closable={false}
          style={{
            // allow modal to be scrolled up and leave a space underneath
            maxHeight: '75vh',
            overflowY: 'auto',
            top: '10vh',
            display: 'flex'
          }}
          footer={ // Footer Buttons
            <div 
            className='confirm-modal-footer'
            style={ isMobile ? {flexDirection: 'column'} : {flexDirection: 'row'}}
            >
              <Title level={4} style={{margin: 0}}>{translate(msg.total, {total: totalPrice})}</Title>
              <Spin spinning={this.state.loading} delay={100}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Button type='danger' onClick={() => {
                    this.setState({ currentStep: 1 });
                    this.props.onCancel();
                  }}>
                    {translate(msg.cancel)}
                  </Button>
                  {this.state.currentStep === 1 ? (
                    <Button type="primary" onClick={()=>this.setState({ currentStep: 2 })}>
                      {translate(msg.ContinueStep)}
                    </Button>
                  ) : (
                    <Button type="primary" onClick={this.handleOk}>
                      {translate(msg.confirmOrderButton)}
                    </Button>
                  )}
                </div>
              </Spin>
            </div>
          }
        >
          {this.state.currentStep === 1 ? 
            this.renderStep1(translate) : 
            this.renderStep2(translate)}
        </Modal>
      )
    }
}

export default injectIntl(withRouter(ConfirmOrderModal));