import { SmartRequests } from "../../utilities";
import Axios from 'axios';
import * as mqttClient from './mqttClient';

const PAGES_COVERED_BY_MQTT = [
  'valet',
  'reservables'
]

// since mqtt sends the message to all clients at once
// we want to avoid all clients sending requests at the same time
// use it to delay the requests of units (bigger units will wait longer)
// most buildings have 30 floors which is ~ 150 seconds 
function timeForRequest(unit, mqttReload) {
  if (unit && mqttReload) {
    const floor = Math.floor(unit / 100);
    const door = unit % 100;
    return (floor * 5) + door;
  }
  return 0; 
}

export const FETCHING_FUNCTIONS = {         
  messages: function (unit, mqttReload, setLoading) {
    const waitSeconds = timeForRequest(unit, mqttReload);
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(pageFetchRequest('messages'));
        setLoading(true);
      }, waitSeconds * 1000); 
    }).then(function (response) {
      if (this.state.messages.length !== response.data.length ||
          !this.state.messages.every((msg, i) => msg.body === response.data[i].body && msg.status === response.data[i].status)) {
        this.setState({ messages: response.data });
      }
    }.bind(this))
    .catch(console.log)
    .finally(() => {
      setLoading(false);
    })
},
          
  deliveries: function (unit, mqttReload) {
    pageFetchRequest('q_deliveries?state=stored&page_size=10').then(function (response) {
      if (response.data.results && this.state.deliveries.length !== response.data.results.length ||
          !this.state.deliveries.every((delivery, i) =>
            delivery.id === response.data.results[i].id
            && delivery.state === response.data.results[i].state
            && delivery.storage_room_name === response.data.results[i].storage_room_name)) {
        this.setState({ deliveries: response.data })
      }
    }.bind(this))
      .catch(console.log)
  },

  valet: function (unit, mqttReload) {
    return Promise.all([
      pageFetchRequest('vehicles'),
      pageFetchRequest('vehicles/car_wash')
    ]).then(([vehiclesResponse, carWashResponse]) => {
      const vehiclesData = vehiclesResponse.data;
      const carWashData = carWashResponse.data;

      // Actualiza el estado para vehicles
      if (this.state.vehicles.length !== vehiclesData.length ||
        !this.state.vehicles.every((vehicle, i) =>
          vehicle.id === vehiclesData[i].id &&
          vehicle.state === vehiclesData[i].state &&
          vehicle.current_unit_id === vehiclesData[i].current_unit_id)) {
        this.setState({ vehicles: vehiclesData });
      }
      
      if (vehiclesData.length === 0) {
        this.setState({ hasNoCars: true });
      }

      // Actualiza el estado para carWash
      const hasDataChanged = JSON.stringify(carWashData) !== JSON.stringify(this.state.carWash);
      if (hasDataChanged) {
        this.setState({ carWash: carWashData });
      }

    }).catch(console.log);
  },

  requests: function (tag, unit, mqttReload, setLoading) {
    let url = 'requests_paginated?state=pending,processing'
    if (tag) {
      url += '&page_size=10&tag=' + encodeURIComponent(tag)
    }  
    setLoading(true)
    return pageFetchRequest(url).then(function (response) {
      if(response.data.results && this.state.requests.length !== response.data.results.length ||
                  !this.state.requests.every((request, i) =>
                  request.id === response.data.results[i].id
                  && request.state === response.data.results[i].state)) {
        this.setState({ requests: response.data}) 
          }
    }.bind(this))
      .catch(console.log)
      .finally(() => {
        setLoading(false)
      })
  },

  reservables: function (unit, mqttReload) {
    return Promise.all([
      pageFetchRequest('reservations?after_date=now'),
      pageFetchRequest('reservables')
    ]).then(([reservationsResponse, reservablesResponse]) => {
      const reservationsData = reservationsResponse.data;
      const reservablesData = reservablesResponse.data;

      // Actualiza el estado para reservations
      if (this.state.reservations.length !== reservationsData.length ||
        !this.state.reservations.every((request, i) =>
          request.id === reservationsData[i].id &&
          request.amount === reservationsData[i].amount &&
          request.state === reservationsData[i].state)) {
        this.setState({ reservations: reservationsData });
      }

      // Actualiza el estado para reservables
      this.setState({ reservables: reservablesData });

    }).catch(console.log);
  },

  weather: function (unit, mqttReload) {
    return pageFetchRequest('weather').then(function (response) {
      let data = response.data
      let weather = []
      let today
      let lastWeatherUpdate = ''
      try {
        weather = data.forecasts.map(f => ({ conditions: f.conditions, text: f.day_of_week + '\n' + f.high + 'º / ' + f.low + 'º' }))
        weather.unshift({ conditions: data.now.conditions, text: 'Now\n' + data.now.temp + 'º (' + data.now.humidity + ')' })
        today = { conditions: data.now.conditions, text: data.now.temp + 'º (' + data.now.humidity + ')' }
        lastWeatherUpdate = data.formatted_ts
      } catch (err) { }
      this.setState({ today, weather, lastWeatherUpdate })
    }.bind(this))
      .catch(console.log)
  },

  emergency: function(unit, mqttReload){
    return pageFetchRequest('emergency_settings').then( resp => {
      if (resp.status === 200) {
        this.setState({activeEmergency: resp.data.active_emergency})
      }}).catch( err => { 
        console.log("Error get emergency: " + err)
      })
  },

  polls: function(unit, mqttReload){
    const waitSeconds = timeForRequest(unit, mqttReload);
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(pageFetchRequest('poll?page_size=10&state=sent'));
      } , waitSeconds * 1000)}).then( resp => {
      if (resp.status === 200) {
        this.setState({polls: resp.data});
      }}).catch( err => {
        console.log("Error get poll: " + err)
      })
  },

  restaurant: function(unit, mqttReload, _setLoading, page_id){
    return pageFetchRequest(`food_order/${page_id}/orders`).then( resp=> {
      if (resp.status === 200) {
        this.setState(prevState => ({
          myOrders: {
            ...prevState.myOrders,
            [page_id]: resp.data
          }
        }))
      }}).catch( err => {
        console.log("Error get orders: " + err)
      })
  },
}

export const MQTT_TOPICS = Object.keys(FETCHING_FUNCTIONS)

let pageFetchSource
resetToken()

async function pageFetchRequest (page) {
  return SmartRequests.get(page, pageFetchSource.token)
}

export function cancellAllPageFetchingRequests (message) {
  pageFetchSource.cancel(message)
  resetToken()
}

function resetToken () {
  pageFetchSource = Axios.CancelToken.source()
}

export class PageData {

  constructor (dashBoardInstance, pageType, setLoading, page) {
    console.log('PageData constructor'+ pageType)
    this._dashBoardInstance = dashBoardInstance
    this._pageType = pageType
    this._pageId = page? page.page.id : null
    this._fetchFunction = this._getFunctionForPage(pageType).bind(this._dashBoardInstance)
    this._setLoading = setLoading
  }

  refresh = ({forced = false, tag = undefined, mqttReload = false, unit = undefined} = {}) => {
    if (forced || this._shouldItBeRefreshed()) {
      if(tag!==undefined){
        this._fetchFunction(tag, unit, mqttReload, this._setLoading)
      }else{
        this._fetchFunction(unit, mqttReload, this._setLoading, this._pageId)
      }
    }
  }

  typeMatches = (type) => {
    return type === this._pageType
  }
  pageIdMatches = (id) => {
    return id === this._pageId
  }

  _shouldItBeRefreshed = () => {
    const isDataSyncedByMQTT = PAGES_COVERED_BY_MQTT.includes(this._pageType)
    const isMQTTOnline = mqttClient.isConnected()
    const isRealTimeSynced = isDataSyncedByMQTT && isMQTTOnline
    return !isRealTimeSynced
  }

  _getFunctionForPage = () => {
    if (this._isPageValid()) {
      return FETCHING_FUNCTIONS[this._pageType]
    }
  }

  _isPageValid = () => {
    const allPageTypes = Object.keys(FETCHING_FUNCTIONS)
    return !!allPageTypes.includes(this._pageType)
  }
}

export function instantiateOneOfEach(dashboardInstance, pages, setLoading) {
  const ALL_PAGES = Object.keys(FETCHING_FUNCTIONS);

  let allInstances = pages.reduce((instances, page) => {
    if (ALL_PAGES.includes(page.page.type)) {
      instances.push(new PageData(dashboardInstance, page.page.type, setLoading, page));
    }
    return instances;
  }, []);

  allInstances.push(new PageData(dashboardInstance, "requests", setLoading, null));
  return allInstances;
}
