import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

class SmartLink extends Component {
  
  isActive = () => {
    let currentLevel = this.getCurrentLevel();
    var isActive = (
      !this.props.isDisabled &&
      currentLevel === this.props.level
    );
    console.log(`[SmartLink.isActive()] Level is ${currentLevel}, initial is ${this.props.level}. SmartLink is ` + (isActive ? 'active' : 'not active'));
    return isActive;
  }

  getCurrentLevel = () => {
    let components = this.props.location.pathname.split('/');
    let level
    if (false && components[components.length-1] === "") 
      level = components.length -1
    else
      level = components.length
    return level;
  }

  onClickEvent = (event) => {
    console.log(`Click on ${this.isActive() ? 'active' : 'non active'} SmartLink`);
    if (!this.isActive()) {
      event.preventDefault();
      if (this.props.onInactiveClick) {
        this.props.onInactiveClick(event);
      }
    } else {
      if (this.props.onClick) {
        this.props.onClick(event);
      }
    }
  }

  render() {
    return (
      <Link
        to={ this.props.to }
        className={ this.props.className } 
        onClick={ this.onClickEvent }
      >
        { this.props.children }
      </Link>
    );
  }
}

export default SmartLink = withRouter(SmartLink);
