import axios from 'axios';

import React from 'react';
import debounce from 'lodash.debounce';

import { Spin } from 'antd'

import PageUl from '../../../components/PageUl.js'
import {PaginationControl, reloadIfNeeded} from '../../../components/Pagination.js'

import { injectIntl } from "react-intl";

import msg from './messages'

import DeliveryDetailsModal from './DeliveryDetailsModal'
import * as utils from '../../../utilities/utils.js'

import { Icon } from 'antd'
import { Radio } from 'antd'
import { Badge } from 'antd'

import { Input } from 'antd'
const { Search } = Input


function showDeliveryDetails() {
  const t = this.props.intl.formatMessage
  if (this.state.showDetailsOf)
    return (
      <DeliveryDetailsModal
                modalCallback={
                  function() {
                    this.setState({'showDetailsOf': null});
                  }.bind(this)
                }

      reqData={this.props.reqData}
      delivery={ this.state.showDetailsOf } 
      > </DeliveryDetailsModal>
    )
  else
    return ''
}


function showDeliverySubtitle(del,t){
  if (del.state === "stored") {
    return (
      <p className="subtitle"> { t(msg.storedIn) + ' ' + del.storage_room_name } </p>
    )
  }
  return (
    <p className="subtitle"></p> 
  )
}


function showDelivery(del){
  const t = this.props.intl.formatMessage
  var tns = del.tn.split('\n')
  function openDeliveyDetails() {
    this.setState({"showDetailsOf": del})
  }
  return (
    <li key={del.id} className={`${del.state} li-request`} style={{"position":"relative", "clear": "both"}} >
      <p className="title">    { t(msg.from) + ' ' + del.sender }   </p> 
      { showDeliverySubtitle(del,t) }
      <p className="body">
        <p>{ del.courier_name + ' ' + t(msg.tracking) + ' ' + tns.join(',') } </p>     
        { (tns.length > 1)? <p> { t(msg.ammount) + ': ' + tns.length}</p> : '' }  
      </p> 
      <p className="date" >    { del.fmt_date }  </p> 
      <p className="moreInfo">
      <a onClick={openDeliveyDetails.bind(this)}> <Icon type="info-circle"/>&nbsp; { t(msg.info) } </a>
      </p>
        <p className="state">
        {t(msg[del.state])}
        </p>
    </li>)
}


class Deliveries extends React.Component{
  constructor(props) {
    super(props);    
    this.state = { 
      showDetailsOf: null,
      searching: false,
      pageOffset: 0,
    };
    // this.pageOffset=0;
    this.pageSize=10;
    this.category= 'stored'
    this.debouncedReloadIfNeeded = debounce( reloadIfNeeded.bind(this, 'q_deliveries?', this.setState.bind(this)) , 300)
  }

  updateOffset = (newOffset) => {
    this.setState({pageOffset: newOffset})
  }
  setSearchQuery(e) {
    this.query = e.target.value
    this.setState({ pageOffset: 0 });
    this.debouncedReloadIfNeeded()
  }
  setSearchCategory(e) {
      this.category = e.target.value
      if (this.category == 'all') {
        this.category = null
      }
      this.setState({ pageOffset: 0 });
      this.debouncedReloadIfNeeded()
    }
  render(){
    const t = this.props.intl.formatMessage
    if(this.props.newMqttMessage && this.props.mqttTopicMessage === "deliveries") {
      this.reload= true
      this.debouncedReloadIfNeeded()
      this.props.pageMessageReceivedCallback()
    }
    let dlist = this.props.deliveries
    if (this.state.searchResults) {
      dlist = this.state.searchResults
    }
    return (
      <div className='deliveries'>
        <PageUl>
        <div className="filterBar">
        <span>{t(msg.show)} </span>
            <Radio.Group 
            defaultValue="stored" 
            buttonStyle="solid"
            onChange={this.setSearchCategory.bind(this)}
            >
            <Radio.Button value="stored">{t(msg.stored)}</Radio.Button>
            <Radio.Button value="delivered">{t(msg.delivered)}</Radio.Button>
            <Radio.Button value="all">{t(msg.all)}</Radio.Button>
            </Radio.Group>
            &nbsp;
            <Search
            placeholder="#tracking"
            onChange={this.setSearchQuery.bind(this)}
            style={{ width: 120 }}
            allowClear
            />
         </div>
         <Spin spinning={this.state.searching} delay={500}>
         { (dlist.results && dlist.results.length > 0) ? (
            <>
              <PaginationControl t={this.props.intl.formatMessage} pageOffset={this.state.pageOffset} updateOffset={this.updateOffset} debouncedReloadIfNeeded={this.debouncedReloadIfNeeded.bind(this)} pageSize={this.pageSize} elements={dlist}/>
              {dlist.results.map( showDelivery.bind(this) )}
            </>   
           ) : (
            <li className='li-request'> <p className="body"> {t(msg.noDeliveriesMatch)} </p> </li>
           )
         }
          </Spin> 
        </PageUl>
        {showDeliveryDetails.bind(this)()}
      </div>
    )
  }
}

export default Deliveries = injectIntl( Deliveries )
