import React, { Component } from 'react'
import ChooserCell from './ChooserCell';
import { Modal } from 'antd';
import { reservations } from './helpers';
import { injectIntl } from "react-intl";
import msg from './messages';
import Scrollable from '../../../components/Scrollable'
import { ExclamationCircleTwoTone } from '@ant-design/icons';


class ItemChooser extends Component {
  constructor(props) {
    super(props);
    this.getAvailableSlots = this.getAvailableSlots.bind(this);
    var availability = this.getAvailableSlots();
    this.state = {
      'availability': availability,
      'isLocked': false,
    }
  }
  getAvailableSlots() {   
    var slots = reservations.getAvailableSlots(
      this.props.reservations,
      this.props.items, 
      reservations.arrayDateToDict(this.props.date),
      this.props.unitID,
      this.props.isByHour,
    );
    return slots;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.reservations !== this.props.reservations) {
      console.log('[ItemChooser:componentDidUpdate()] Reservations has changed, itemchooser is refreshing availability');
      var availability = this.getAvailableSlots();
      this.setState({
        'availability': availability,
      });
    }
  }
  setLock(isLocked) {
    console.log ('[ItemChooser:setLock()] Setting lockability to '+isLocked);
    this.setState({
      isLocked: isLocked
    });
  }
  handleClose() {
    if (!this.state.isLocked) {
      this.props.modalCallback();
    } else {
      console.log('[ItemChooser:handleClose()] Ignoring close attempt because a reservation is waiting...');
    }
  }

  commentMinTimeInAdvance = () => {
    const { min_time_in_advance, min_advance_unit } = this.props.groupData;
    let msg_comment = "";

    if (min_time_in_advance === 0) {
      msg_comment = this.props.t(msg.canCancelOneHour);
    } else if (min_advance_unit === 'days') {
      msg_comment = this.props.t(msg.canCancelDays, {minDays: min_time_in_advance, minHours: min_time_in_advance*24});
    } else {
      msg_comment = this.props.t(msg.canCancelHours, {minHours: min_time_in_advance});  
    }
    
    return (
      <div className='reservation-comment'>
        <ExclamationCircleTwoTone twoToneColor="red"/>
        <p>{msg_comment}</p>
      </div>
    )
  }

  render() {
    const t = this.props.intl.formatMessage
    return (
      <Modal
        closable
        maskClosable
        destroyOnClose
        visible={this.props.isVisible}
        footer={null}
        onCancel={this.handleClose.bind(this)}
        width={"80vw"}
      >
        <div
          className="item-selector-container"
        >
          <h1 className='item-list-title'>{t(msg.selectItem)}</h1>
          <div className="item-list">

            { this.commentMinTimeInAdvance() }
            
            <div style={{ overflowX: 'auto'}}>
              {
                this.props.groupData.items.map(
                  (value, index) => (
                    <ChooserCell
                      key={'chooser-cell-' + index}
                      item={value}
                      reservations={this.props.reservations}
                      unitID={this.props.unitID}
                      date={this.props.date}
                      availability={this.state.availability[value.id]}
                      myReservations={this.props.myReservations}
                      closures={this.props.closures}
                      isByHour={this.props.isByHour}
                      setLock={this.setLock.bind(this)}
                      addRequestData={this.props.addRequestData}
                      minTimeInAdvance={this.props.groupData.min_time_in_advance}
                      minAdvanceUnit={this.props.groupData.min_advance_unit}
                    />
                  )
                )
              }
                  </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ItemChooser = injectIntl(ItemChooser)