import { defineMessages } from "react-intl";


const msg = defineMessages({
    
    total: {
        id: "restaurant.total",
        defaultMessage: "Total: ${total}",
    },
    
    price: {
        id: "restaurant.price",
        defaultMessage: "Price",
    },

    confirm: {
        id: "restaurant.confirm",
        defaultMessage: "Confirm",
    },

    cancel: {
        id: "restaurant.cancel",
        defaultMessage: "Cancel",
    },

    required: {
        id: "restaurant.required",
        defaultMessage: "Required",
    },

    complete: {
        id: "restaurant.complete",
        defaultMessage: "Complete",
    },

    edit: {
        id: "restaurant.edit",
        defaultMessage: "Edit",
    },
    
    mainPageTitle: {
        id: "restaurant.mainPageTitle",
        defaultMessage: "Restaurant Menu",
    },
    
    emptyMenu: {
        id: "restaurant.emptyMenu",
        defaultMessage: "There are currently no dishes in this menu",
    },

    myOrdersButton: {
        id: "restaurant.myOrdersButton",
        defaultMessage: "My Orders",
    },
    
    cartTitle: {
        id: "restaurant.cartTitle",
        defaultMessage: "Your Order",
    },
    
    emptyCart: {
        id: "restaurant.emptyCart",
        defaultMessage: "No dishes in your cart yet",
    },

    hideCart: {
        id: "restaurant.hideCart",
        defaultMessage: "Hide Cart",
    },
    
    showCart: {
        id: "restaurant.showCart",
        defaultMessage: "Show Cart",
    },
    
    chooseQuantityOptions: {
        id: "restaurant.chooseQuantityOptions",
        defaultMessage: "Please choose {count} {plural, select, true {options} other {option}}",
    },
    
    chooseQuantityUpTo: {
        id: "restaurant.chooseQuantityUpTo",
        defaultMessage: "Please choose up to {count} {plural, select, true {options} other {option}}",
    },
    
    chooseQuantityAtLeast: {
        id: "restaurant.chooseQuantityAtLeast",
        defaultMessage: "Please choose at least {count} {plural, select, true {options} other {option}}",
    },
    
    chooseQuantityRange: {
        id: "restaurant.chooseQuantityRange",
        defaultMessage: 'Please choose between {min} and {max} options',
    },
    
    modifyModalTitle: {
        id: "restaurant.modifyModalTitle",
        defaultMessage: "Modify your {name}",
    },
    
    confirmOrderButton: {
        id: "restaurant.confirmOrderButton",
        defaultMessage: "Confirm Order",
    },
    
    confirmOrderTitle: {
        id: "restaurant.confirmOrderTitle",
        defaultMessage: "Confirm order",
    },

    deliveryButton: {
        id: "restaurant.deliveryButton",
        defaultMessage: "Deliver in ...",
    },
    deliveryButtonOnly: {
        id: "restaurant.deliveryButtonOnly",
        defaultMessage: "You must select a delivery option within the building",
    },
    
    pickupButton: {
        id: "restaurant.pickupButton",
        defaultMessage: "Pickup at {res_name}",
    },
    pickupButtonOnly: {
        id: "restaurant.pickupButtonOnly",
        defaultMessage: "The order must be picked up at {res_name}",
    },
    
    emptyOrder: {
        id: "restaurant.emptyOrder",
        defaultMessage: "No dishes in your order",
    },
    
    myOrdersTitle: {
        id: "restaurant.myOrdersTitle",
        defaultMessage: "My orders",
    },
    
    emptyMyOrders: {
        id: "restaurant.emptyMyOrders",
        defaultMessage: "No orders found matching criteria!",
    },

    deliveredAt: {
        id: "restaurant.deliveredAt",
        defaultMessage: "Delivered at {dateTime}",
    },

    estimatedReceiveTime: {
        id: "restaurant.estimatedReceiveTime",
        defaultMessage: "Estimated time {receiveMethod, select, ready_for_deliver {of delivery} ready_for_pickup {for pickup} other {}}: {time}",
    },

    menuChangedAlertMessage: {
        id: "restaurant.menuChangedAlertMessage",
        defaultMessage: "Menu Changed",
    },

    menuChangedAlertDescription: {
        id: "restaurant.menuChangedAlertDescription",
        defaultMessage: "{orderChanged, select, true {Your order may have been changed, please check your order.} other {The dishes in your order did not change, you can continue ordering!}}"
    }
    ,
    own_unit: {
        id: "restaurant.own_unit",
        defaultMessage: "Deliver to unit: {unit}",
    },
    selectDeliveryPlace: {
        id: "restaurant.selectDeliveryPlace",
        defaultMessage: "Select a delivery place",
    },
    nested_place: {
        id: "restaurant.nested_place",
        defaultMessage: "Please select a more specific location within {place_name}:",
    },

    pickupNotes: {
        id: "restaurant.pickupNotes",
        defaultMessage: "Add a note for the staff",
    },

    deliveryNotes: {
        id: "restaurant.deliveryNotes",
        defaultMessage: "Add a note to help the staff deliver your order, e.g. Phone number, special instructions, etc.",
    },
    restaurantOpenHours: {
        id: "restaurant.restaurantOpenHours",
        defaultMessage: "Restaurant open hours: {openTime} - {closeTime}",
    },
    restaurantClosed: {
        id: "restaurant.restaurantClosed",
        defaultMessage: "Restaurant is closed",
    },
    // For OrderChangedModal
    OrderChangedModalTitle: {
        id: "restaurant.OrderChangedModalTitle",
        defaultMessage: "There was a change in the menu of the restaurant while you were ordering",
    },
    
    TotalPrice: {
        id: "restaurant.TotalPrice",
        defaultMessage: "Total price: ",
    },
    ContinueStep: {
        id: "restaurant.ContinueStep",
        defaultMessage: "Continue to step 2",
    },
    DishNotAvailable: {
        id: "restaurant.DishNotAvailable",
        defaultMessage: "Dish no longer available",
    },

    OptionsModified: {
        id: "restaurant.OptionsModified",
        defaultMessage: "Options have been modified! (your selections still exist)",
    },

    OptionsDontExist: {
        id: "restaurant.OptionsDontExist",
        defaultMessage: "Some options you had selected don't exist anymore",
    },

    ExtrasModified: {
        id: "restaurant.ExtrasModified",
        defaultMessage: "Extras have been modified! (your selections still exist, but prices may have changed)",
    },

    ExtrasDontExist: {
        id: "restaurant.ExtrasDontExist",
        defaultMessage: "Some extras you had selected don't exist anymore",
    },

    MinimumSelectionsChanged: {
        id: "restaurant.MinimumSelectionsChanged",
        defaultMessage: "Minimum number of options has changed",
    },

    MaximumSelectionsChanged: {
        id: "restaurant.MaximumSelectionsChanged",
        defaultMessage: "Maximum number of options has changed",
    },

    veganTag:{
        id: "restaurant.veganTag",
        defaultMessage: "Vegan"
    },
    vegetarianTag:{
        id: "restaurant.VegetarianTag",
        defaultMessage: "Vegetarian"
    },
    glutenFreeTag:{
        id: "restaurant.GlutenTag",
        defaultMessage: "Gluten Free"
    },
    cancelledNote: {
        id: "restaurant.cancelledNote",
        defaultMessage: "Cancel Note: {cancelNote}"
    },
    createdAt: {
        id: "restaurant.createdAt",
        defaultMessage: "Created at: {fmt_created_at}"
    },

});

export default msg;
