import React, { Component } from 'react';
import msg from './messages'
import { injectIntl } from 'react-intl';
import { Button, message } from 'antd';

const daysOfWeek = ['Mondays', 'Tuesdays', 'Wednesdays', 'Thursdays', 'Fridays', 'Saturdays', 'Sundays'];
const daysOfMonth = Array.from({ length: 31 }, (_, i) => (i + 1).toString());

class ItemAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days_of_week: [],
      days_of_month: [],
      start_date: '',
      end_date: '',
      time_start: '',
      time_finish: '',

      type_of_timeframe: 'once',
    };
  }

  componentDidMount = () => {
      console.log("mounting itemAccess")

      this.t = this.props.intl.formatMessage

      const { timeframe_id, type_of_timeframe, start_date, end_date, time_start, time_finish, days_of_week, days_of_month} = this.props.infoItem;
      this.setState({
        timeframe_id: timeframe_id,
        type_of_timeframe: type_of_timeframe,
        start_date: start_date,
        end_date: end_date,
        time_start: time_start,
        time_finish: time_finish,
        days_of_week: days_of_week || [],
        days_of_month: days_of_month || [],
      })
    
  }

  handleDayChange = (e, actuallyType) => {
    const day = e.target.value;
    const isChecked = e.target.checked;

    this.setState((prevState) => {
        let selectedDays = actuallyType === "weekly" ? "days_of_week" : "days_of_month";
        if (isChecked) {
            return { [selectedDays]: [...prevState[selectedDays], day] };
        } else {
            return {
                [selectedDays]: prevState[selectedDays].filter((d) => d !== day),
            };
        }
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  renderInput = (label, name, type, value) => (
    <div className="form-item-autho">
      <label className="form-label-autho">{label}:</label>
      <input
        name={name}
        type={type}
        value={value}
        onChange={(e) => this.handleChange(e)}
        required
      />
    </div>
  );

  renderCheckboxGroup = (label, days, selectedDays) => (
    <div className="form-item-autho">
      <label className="checkbox-group-label">{label}:</label>
      <ul className="checkbox-list">
        {days.map((day) => (
          
          <li key={day} className="checkbox-list-item">
            
            <input
              id={`${this.props.infoItem.timeframe_id}-day-${day}`}
              type="checkbox"
              className="checkbox-input"
              value={day}
              checked={this.state[selectedDays].includes(day)}
              onChange={(e) => this.handleDayChange(e, this.state.type_of_timeframe)}
            />
            <label className={`day-label ${this.state[selectedDays].includes(day) ? 'checked' : ''}`} for={`${this.props.infoItem.timeframe_id}-day-${day}`}>
              {selectedDays === "days_of_week" ? this.t(msg[day]) : day}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );

  checkDatesFields = () => {
    const {type_of_timeframe, days_of_week, days_of_month, start_date, end_date, time_start, time_finish} = this.state;
    return this.checkDates(type_of_timeframe, days_of_week, days_of_month, start_date, end_date, time_start, time_finish);
  }

  checkDates = (frequencyOfDays, days_of_week, days_of_month, start_date, end_date, time_start, time_finish) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const startDateAndTime = new Date(`${start_date}T${time_start}`);
    const endDateAndTime = new Date(`${end_date}T${time_start}`);

    let errDates = "";

    if (frequencyOfDays === "once"){
      if (!start_date){
          errDates = this.t(msg.startDateMissing);
      } else {
          if (currentDate > startDateAndTime){
              errDates = this.t(msg.currentDateGreaterThanStartDate);
          } else {
              if (!(time_start && time_finish)){
                  errDates = this.t(msg.timeStartFinishMissing);
              } else {
                  const startTime = new Date(`1970-01-01T${time_start}`);
                  const finishTime = new Date(`1970-01-01T${time_finish}`);
                  if (finishTime < startTime){
                      errDates = this.t(msg.finishTimeEarlierThanStartTime);
                  }
              }
          }
      }
    } else {
        let days = frequencyOfDays === "weekly" ? days_of_week : days_of_month;

        if (!(start_date && end_date)){
            errDates = "You didn't select start date and finish date";
        } else {
            if ((startDateAndTime < currentDate) || (endDateAndTime < currentDate) || (startDateAndTime > endDateAndTime)){
                errDates = this.t(msg.startAndEndDateError);
            } else {
                if (days.length <= 0){
                    errDates = this.t(msg.noDaySelected);
                } else {
                    if (!(time_start && time_finish)){
                        errDates = this.t(msg.timeStartFinishMissing);
                    } else {
                        const startTime = new Date(`1970-01-01T${time_start}`);
                        const finishTime = new Date(`1970-01-01T${time_finish}`);
                        if (finishTime < startTime){
                            errDates = this.t(msg.finishTimeEarlierThanStartTime);
                        }
                    }
                }
            }
        }
    }

    return errDates;
  }

  getDataAuth = () =>{
    return {
      type_of_timeframe: this.state.type_of_timeframe,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      time_start: this.state.time_start,
      time_finish: this.state.time_finish,
      days_of_week: this.state.days_of_week,
      days_of_month: this.state.days_of_month,
    }
  }

  addAuth = () => {
    let error_message = this.checkDatesFields();
    if (error_message === ""){
      let dataAuth = this.getDataAuth();
      this.props.addAuth(dataAuth);
    } else {
      message.error(error_message);
    }
  }

  editAuth = () => {
    let error_message = this.checkDatesFields();
    if (error_message === ""){
      let dataAuth = this.getDataAuth();
      dataAuth.timeframe_id = this.state.timeframe_id;
      dataAuth.authorization_type = "recurrence";
      this.props.editAuth(dataAuth, this.props.autorization_type);
    } else {
      message.error(error_message);
    }
  }
  
  render() {
      
    const t = this.props.intl.formatMessage;
    const type_of_timeframe = this.state.type_of_timeframe;

    let isDefinedTypeTimeFrame = (type_of_timeframe === 'once' || type_of_timeframe === 'weekly' || type_of_timeframe === 'monthly');
    let msgStartDate = type_of_timeframe === 'once' ? msg.Date : msg.initialDate
    let showEndDate = (type_of_timeframe === 'weekly' || type_of_timeframe === 'monthly');
    let showDaysOfWeek = (type_of_timeframe === 'weekly');
    let showDaysOfMonth = (type_of_timeframe === 'monthly');

    const { action } = this.props;

    return (

        <div className="item-guest-entry">
            <div className="header-item">
                <div className='container-frequency-select'>
                    <label className="frequency-label">{t(msg.descFrequency)}</label>
                    <select
                        value={type_of_timeframe}
                        onChange={this.handleChange}
                        className="select"
                        name='type_of_timeframe'
                    >
                        <option value="once">{t(msg.once)}</option>
                        <option value="weekly">{t(msg.weekly)}</option>
                        <option value="monthly">{t(msg.monthly)}</option>
                    </select>
                </div>
            </div>

            <div className="line"></div>

            {isDefinedTypeTimeFrame && <h2>{t(msg["desc" + type_of_timeframe])}</h2>}
    
            {isDefinedTypeTimeFrame && this.renderInput(t(msgStartDate), "start_date", "date", this.state.start_date)}
    
            {showEndDate && this.renderInput(t(msg.finalDate), "end_date", "date", this.state.end_date)}
    
            {showDaysOfWeek && this.renderCheckboxGroup(t(msg.daysOfWeek), daysOfWeek, "days_of_week")}
            {showDaysOfMonth && this.renderCheckboxGroup(t(msg.daysOfTheMonth), daysOfMonth, "days_of_month")}

            {isDefinedTypeTimeFrame && this.renderInput(t(msg.checkInTime), "time_start", "time", this.state.time_start)}
            {isDefinedTypeTimeFrame && this.renderInput(t(msg.checkOutTime), "time_finish", "time", this.state.time_finish)}

            <Button 
              type="primary" 
              onClick={
                action === "add" ? () => this.addAuth() : () => this.editAuth()
              }
            >
              {t(msg.save)}
            </Button>

        </div>
    );    
  }
}


export default injectIntl(ItemAccess);
