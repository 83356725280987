import React from 'react';
import { PageFrame } from '../../components/PageFrame.js'
import { Link } from 'react-router-dom'

import { injectIntl } from "react-intl";

import msg from './messages'
import Header from '../PageView/Header.js';

class ErrorPage extends React.Component{
  render(){
    const t = this.props.intl.formatMessage
    return (
      <PageFrame className='error-page'>
        <Header pageInfo={{"content": null}}>{"Page Error"}</Header>
        <div className='error-body'>
          <h1>{t(msg.ops)}</h1>
          <span>{t(msg.error)}</span>
        </div>
      </PageFrame>
    )
  }
}

export default ErrorPage = injectIntl(ErrorPage)