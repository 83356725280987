import { CordovaCommunication } from '../../containers/Main/cordovaCommunication';

export const NATIVE_SOURCE = 'NATIVE';
export const LOCAL_SOURCE = 'LOCAL';
const GET_TRANSACTION = 'GET';
const SET_TRANSACTION = 'SET';
const REMOVE_TRANSACTION = 'REMOVE';

const pendingRequests = [ ]

var defaultSource = LOCAL_SOURCE;
var transactionId = 0;

function sendRequest(data) {
  CordovaCommunication.sendData(CordovaCommunication.STORAGE, data);
}

function newTransaction(type) {
  const newId = transactionId++;
  const newTransaction = {
    id: newId,
    type: type,
  };
  return newTransaction;
}

export function getDefaultSource() {
  return defaultSource;
}

export function setDefaultSource(source) {
  const isValidSource = [NATIVE_SOURCE, LOCAL_SOURCE].includes(source);
  if (isValidSource) {
    defaultSource = source;
  }
}

export const storageHandler = (response) => {
  const transaction = response.transaction;
  const value = response.value;
  const error = response.error;
  const pendingRequest = pendingRequests.find(({transaction: t}) => t.id === transaction.id);
  const pendingRequestIndex = pendingRequests.indexOf(pendingRequest);
  if (pendingRequestIndex >= 0) {
    pendingRequests.splice(pendingRequestIndex, 1);
    if (error) {
      pendingRequest.reject(error);
    } else {
      pendingRequest.resolve(value);
    }
  }
}

export const setItem = (key, value, source) => {
  return newStorageRequest(SET_TRANSACTION, key, value, source);
}

export const getItem = (key, source) => {
  return newStorageRequest(GET_TRANSACTION, key, undefined, source);
}

export const removeItem = (key, source) => {
  return newStorageRequest(REMOVE_TRANSACTION, key, undefined, source);
}

const newStorageRequest = async (transactionType, key, value, s) => {
  const source = s || defaultSource;
  let result;
  if (source === LOCAL_SOURCE) {
    result = await useLocalStorage(transactionType, key, value);
  } else if (source === NATIVE_SOURCE) {
    result = await useNativeStorage(transactionType, key, value).catch(nativeStorageErrorHandler);
  } else {
    throw `Wrong source: ${source}`;
  }
  return result;
}

const useLocalStorage = (transactionType, key, value) => {
  return new Promise((resolve, reject) => {
    if (transactionType === GET_TRANSACTION) {
      const storedValue = localStorage.getItem(key);
      resolve(storedValue);
    } else if (transactionType === SET_TRANSACTION) {
      localStorage.setItem(key, value);
      resolve();
    } else if (transactionType === REMOVE_TRANSACTION) {
      localStorage.removeItem(key);
      resolve();
    } else {
      reject('Wrong transaction type');
    }
  });
}

const useNativeStorage = (transactionType, key, value) => {
  return new Promise((resolve, reject) => {
    const transactionData = newTransaction(transactionType);
    const data = {
      transaction: transactionData,
      key: key,
      value: value
    }
    sendRequest(data);
    pendingRequests.push({
      transaction: transactionData,
      resolve: resolve,
      reject: reject
    });
  });
}

const nativeStorageErrorHandler = (error) => {
  const errorCode = error.code;
  let errorMessage;
  if (errorCode === 1) {
    errorMessage = '[CustomStorage.errorHandler()]! Native write failed';
  } else if (errorCode === 2) {
    console.log('[CustomStorage.errorHandler()] Item not found');
    return null;
  } else if (errorCode === 3) {
    console.log('[CustomStorage.errorHandler()]! Null reference');
    return null;
  } else if (errorCode === 4) {
    console.log('[CustomStorage.errorHandler()]! Undefined type');
    return null;
  } else if (errorCode === 5) {
    errorMessage = '[CustomStorage.errorHandler()]! Json error';
  } else if (errorCode === 6) {
    errorMessage = '[CustomStorage.errorHandler()]! Json error';
  }
  console.log(errorMessage);
  throw errorMessage;
}
