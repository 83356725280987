import Axios from 'axios'
import { URLEncode } from '../utils'
import * as CustomStorage from '../customStorage'

const OAUTH_PASSWORD_TYPE  = 'password'
const OAUTH_REFRESH_TYPE   = 'refresh_token'
const OAUTH_CLIENT_SECRET  = '44w8h2yc4ftmnba7jwq2mt7c3zgrpbq4f78jrm3pyz2b2gzsw6dd6vkaqz6kgxqvkmpexkrrm3cc6gc38yq6na3zs9epgrdf5zax'
const OAUTH_CLIENT_ID      = 'mirigi-app'
const OAUTH_SCOPE          = 'APP_CONTROL'
const OAUTH_USERNAME       = 'invitation_handler'

var passwordLess

export var axios
removeAuthenticationHeader()

// Device -- OAuth
export async function loginDevice(key) {
  await logoutDevice()
  return axios.post('/oauth/token', URLEncode({ 
      password      : key,
      username      : OAUTH_USERNAME,
      grant_type    : OAUTH_PASSWORD_TYPE,
      scope         : OAUTH_SCOPE,
      client_secret : OAUTH_CLIENT_SECRET,
      client_id     : OAUTH_CLIENT_ID
    })).then(async response => {
      const accessToken = response.data.access_token;
      const refreshToken = response.data.refresh_token;
      await setupOauth(accessToken, refreshToken);
    })
}

export async function logoutDevice() {
  if (await usesOauth()) {
    passwordLess = false
    await CustomStorage.removeItem('access_token')
    await CustomStorage.removeItem('refresh_token')
    removeAuthenticationHeader()
  }
}

export async function usesOauth() {
  if (passwordLess === undefined) {
    // set device
    let accToken = await CustomStorage.getItem('access_token')
    if (accToken) {
      setAuthorizationHeader('Bearer ' + accToken)
      passwordLess = true
    } else {
      passwordLess = false
    }
  }
  // Authentication.setPasswordLess(passwordLess);
  return passwordLess
}

export async function refreshToken() {
  const refToken = await CustomStorage.getItem('refresh_token')
  removeAuthenticationHeader()
  return axios.post('/oauth/token', URLEncode({ 
    refresh_token: refToken,
    grant_type: OAUTH_REFRESH_TYPE,
    client_secret: OAUTH_CLIENT_SECRET,
    client_id: OAUTH_CLIENT_ID
  })).then(async response => {
    const accessToken = response.data.access_token;
    const refreshToken = response.data.refresh_token;
    await setupOauth(accessToken, refreshToken);
  })
}

export async function setupOauth(accessToken, refreshToken) {
  await CustomStorage.setItem('access_token', accessToken);
  await CustomStorage.setItem('refresh_token', refreshToken);
  setAuthorizationHeader(`Bearer ${accessToken}`);
  passwordLess = true;
}

function setAuthorizationHeader(token) {
  axios = Axios.create({
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': token
    }
  })
}

function removeAuthenticationHeader() {
  axios = Axios.create({
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
}
