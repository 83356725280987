import React from 'react';
import PageUl from '../../../components/PageUl.js';
import { SmartRequests } from '../../../utilities/index.js';
import MarkdownRenderer from '../../MDRenderer';
import msg from './messages';
import { injectIntl } from "react-intl";
import { Spin } from 'antd';

function showMessage(msg){
  return (
      <li key= {msg.id} className={msg.status === 0? 'unreaded li-request': 'readed li-request'} onClick={() => this.handleCLick(msg.id, msg.status)}>
        <p className="title">{ msg.subject }</p> 
        <p className="subtitle">{ msg.from_name }</p> 
        <p className="body"><MarkdownRenderer markdown={ msg.body } /></p> 
        <p className="date">{ msg.fmt_date }</p> 
      </li>
  )
}

class Messages extends React.Component{

  handleCLick(msgId, msgStatus){
    SmartRequests.put('messages/' + msgId, {seen:(msgStatus === 0 ? 'true':'false' )}).then((response) => {
      this.props.toggleMessage(msgId)
    })
  }

  render() {
    return (
      <div className='messages'>
        <PageUl>
          {[
            <Spin key="spinner" spinning={this.props.loading} delay={500}>
              { this.props.messages && this.props.messages.length > 0 ?
              this.props.messages.map(showMessage.bind(this))
              : 
              <li className='li-request'> <p className="body"> {this.props.intl.formatMessage(msg.nothingToShow)} </p> </li>
                }
            </Spin>
          ]}
        </PageUl>
      </div>
    );
  }
}

export default  injectIntl(Messages)