import { defineMessages } from "react-intl";

const msg = defineMessages({

    commonExpenses: {
        id:'expenses.commonExpenses',
        defaultMessage:'common expenses'
    },

    viewMovements: {
        id:'expenses.viewMovements',
        defaultMessage:'View Movements'
    },

    pay: {
        id:'expenses.pay',
        defaultMessage:'Pay'
    },

    positiveBalance: {
        id:'expenses.positiveBalance',
        defaultMessage:'positive balance'
    },

    neutralBalance: {
        id:'expenses.neutralBalance',
        defaultMessage:'neutral balance'
    },

    negativeBalance: {
        id:'expenses.negativeBalance',
        defaultMessage:'negative balance'
    },

    selectPaymentMethods: {
        id:'expenses.selectPaymentMethods',
        defaultMessage:'Select a payment method'
    },

    next: {
        id:'expenses.next',
        defaultMessage:'Next'
    },

    currentBalance: {
        id:'expenses.currentBalance',
        defaultMessage:'Current balance'
    },

    pay: {
        id:'expenses.pay',
        defaultMessage:'Pay'
    },

    total: {
        id:'expenses.total',
        defaultMessage:'Total'
    },

    nextDueDate: {
        id:'expenses.nextDueDate',
        defaultMessage:'Next due date'
    },

    movements: {
        id:'expenses.movements',
        defaultMessage:'Movements'
    },

    description: {
        id:'expenses.description',
        defaultMessage:'Description'
    },

    amount: {
        id:'expenses.amount',
        defaultMessage:'Amount'
    },

    details: {
        id:'expenses.details',
        defaultMessage:'Details'
    },

    noDetails: {
        id:'expenses.noDetails',
        defaultMessage:'No details'
    },

    positiveAmountError: {
        id:'expenses.positiveAmountError',
        defaultMessage:'The amount to pay must be positive'
    },

    selectedPaymentMethodError: {
        id:'expenses.selectedPaymentMethodError',
        defaultMessage:'Select at least one payment method'
    },

    movementDetail: {
        id:'expenses.movementDetail',
        defaultMessage:'Movement detail'
    },

    date: {
        id:'expenses.date',
        defaultMessage:'Date'
    },

    download: {
        id:'expenses.download',
        defaultMessage:'Download'
    },

    helpMsg: {
        id: 'expenses.helpMsg',
        defaultMessage: 'After the payment is credited, you will have a {balanceMessage} of  {currency_symbol} {newBalance}',
    },

    currentNeutralMsg: {
        id:'expenses.currentNeutralMsg',
        defaultMessage:'Your balance is up-to-date, with no pending debts'
    },

    currentPositiveMsg: {
        id:'expenses.currentPositiveMsg',
        defaultMessage:'You have a positive balance of {currency_symbol} {currentBalance}'
    },

    currentNegativeMsg: {
        id:'expenses.currentNegativeMsg',
        defaultMessage:'You have a negative balance of {currency_symbol} {currentBalance}, make sure to cover this balance before {nextDueDate} to avoid additional charges'
    },   

    loadDataNull: {
        id:'expenses.loadDataNull',
        defaultMessage:'Error: Unable to load common expenses data. Please try again later'
    },

    pendingSub: {
        id:'expenses.pendingSub',
        defaultMessage:'Your payment is pending processing'
    },

    failSub: {
        id:'expenses.failSub',
        defaultMessage:'We are sorry, an error occurred while processing your payment'
    },

    successSub: {
        id:'expenses.successSub',
        defaultMessage:'Successful payment! We have received your payment successfully. You will soon receive an email with the payment receipt. Your current balance will not be updated until the building administration confirms it'
    },
    
    goDashboardPage: {
        id:'expenses.goDashboardPage',
        defaultMessage:'Go to principal page'
    },

    redirectBank: {
        id:'expenses.redirectBank',
        defaultMessage:'Redirecting to the bank'
    },

    pending: {
        id:'expenses.pending',
        defaultMessage:'pending'
    },

    error: {
        id:'expenses.error',
        defaultMessage:'error'
    },

    success: {
        id:'expenses.success',
        defaultMessage:'success'
    },

    paymentTracking: {
        id:'expenses.paymentTracking',
        defaultMessage:'payment tracking'
    },

    accountType: {
        id:'expenses.accountType',
        defaultMessage:'Account type'
    },

    owner: {
        id:'expenses.owner',
        defaultMessage:'Owner'
    },

    tenant: {
        id:'expenses.tenant',
        defaultMessage:'Tenant'
    },
    
    noExpensesFoundUser: {
        id:'expenses.noExpensesFoundUser',
        defaultMessage:'No expenses found for selected account'
    },

    selectAccountToPay: {
        id:'expenses.selectAccountToPay',
        defaultMessage:'Select the account to pay'
    },

    payingAccount: {
        id:'expenses.payingAccount',
        defaultMessage:'Paying account'
    },

    propietario: {
        id:'expenses.propietario',
        defaultMessage:'Owner'
    },

    inquilino: {
        id:'expenses.inquilino',
        defaultMessage:'Tenant'
    },

    accountMovementsMsg: {
        id:'expenses.accountMovementsMsg',
        defaultMessage:'Movements of the account of'
    },

    errorDefinedRole: {
        id:'expenses.errorDefinedRole',
        defaultMessage:'Error defining the account type'
    },

    

    



    

    
})


export default msg