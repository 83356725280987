import React from 'react'
import { Switch } from 'antd'


export default class Experimental extends React.PureComponent {

  state = {
    launcherMode: false
  }

  componentDidMount = () => {

  }

  render = () => {
    if (!this.props.enabled) {
      return null
    }

    return (
      <Switch defaultChecked onChange={onChange} />
    )
  }
}

function onChange(checked) {
  console.log(`switch to ${checked}`);
}


export class ClickHandler extends React.Component {

  timer = null
  state = {
    clickCounter: 0
  }

  _clickHandler = e => {
    this.setState(function (state) {
      const currentCount = state.clickCounter
      if (currentCount < this.props.targetCount) {
        this._resetTimer()
      } else {
        if (typeof this.props.onMultipleClicks === 'function') {
          alert('Experimental settings enabled')
          this.props.onMultipleClicks()
        }
      }
      return { clickCounter: currentCount + 1 }
    })
  }

  _resetTimer = () => {
    this._cancelTimer()
    this.timer = setTimeout(() => { this.setState({ clickCounter: 0 }) }, this.props.timeout)
  }

  _cancelTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = null
  }

  render = () => {
    return (
      <div onClick={this._resetTimer} >
        { this.props.children }
      </div>
    )
  }
}