import React, { Component } from 'react';


function brokenImage(event){
  event.target.style.display = 'none'
}

class VehicleLogoBar extends Component {
  render() {
    return (
      <div className="logo-bar">
        <img className="logo-make" src={this.props.src} alt='this.props.src' onError={brokenImage}></img>
      </div>
    );
  }
}

export default VehicleLogoBar;
