import { defineMessages } from "react-intl";

const msg = defineMessages({
  
  select_type: {
    id:'panic.select_type',
    defaultMessage: 'Chose which type of emergency are you experiencing'
  },
  confirm: {
    id:'panic.confirm',
    defaultMessage: 'Click again to confirm the **{emergency_type}** emergency'
  },
  cancel: {
    id:'panic.cancel',
    defaultMessage: 'Cancel'
  },
  noMatch:{
    id:'panic.noMatch',
    defaultMessage:'No matching criteria!'
  },
  emergency:{
    id:'panic.emergency',
    defaultMessage: 'emergencies'
  },
  contacts:{
    id:'panic.contacts',
    defaultMessage: 'contacts'
  },
  date:{
    id:'panic.date',
    defaultMessage: 'Date'
  },
  HelpRequested:{
    id:'panic.HelpRequested',
    defaultMessage: 'Help Requested'
  },
  helpRequestedDetails:{
    id: 'panic.helpRequestedDetails',
    defaultMessage: '<strong>{user}</strong> requested help for a <strong>{type}</strong> emergency'
  },
  details:{
    id:'panic.details',
    defaultMessage: 'Details'
  },
  settings_panic:{
    id:'panic.settings_panic',
    defaultMessage: 'Configure emergency contact'
  },
  fieldRequired:{
    id:'panic.fieldRequired',
    defaultMessage: 'This field is required!'
  },
  active:{
    id:'panic.active',
    defaultMessage: 'Active'
  },
  fire:{
    id:'panic.fire',
    defaultMessage: 'Fire'
  },
  security:{
    id:'panic.security',
    defaultMessage: 'Security'
  },
  medical:{
    id:'panic.medical',
    defaultMessage: 'Medical'
  },
  pending:{
    id:'panic.pending',
    defaultMessage: 'The emergency is waiting to be processed'
  },
  processing:{
    id: 'panic.processing',
    defaultMessage: 'The emergency is being handled by {handled_by}, you cannot cancel the emergency, only the staff can cancel it',
  },
  cancelAlertStaff:{
    id: 'panic.cancelAlertStaff',
    defaultMessage: 'We have notified the staff about the cancellation of the emergency!',
  },
  descAddEmergencyContact:{
    id: 'panic.descAddEmergencyContact',
    defaultMessage: 'Add contact information for emergency cases',
  },
  addEmergencyContact:{
    id: 'panic.addEmergencyContact',
    defaultMessage: 'Add Emergency Contact',
  },

  ErrorRequest: {
    id:'invitations.ErrorRequest',
    defaultMessage:'There was an error with the request'
  },
})


export default msg


