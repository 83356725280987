import React from 'react';
import { Link } from 'react-router-dom'
import { Icon, Divider } from 'antd';
import { injectIntl } from "react-intl";
import msg from './messages'
import UnitChooser from '../../components/UnitChooser'

class HiddenMenu extends React.PureComponent{

  render(){
    const t = this.props.intl.formatMessage
    return (
      <div className={'hidden-menu ' + (this.props.hidden? 'hidden':'visible') } > 
        <div className="inner">
          <div className="options">
            <Link className="logout-link" to="/" onClick={this.props.logoutFunction}> <Icon type="logout" />{t(msg.logout)}</Link>
            <Link className="settings-link" to="settings/"> <Icon type="setting" />{t(msg.settings)}</Link>
          </div>
          <Divider />
          <ul>
            {UnitChooser(this.props.units, this.props.setUnit)}
          </ul>
        </div>
      </div>
    )
  }
}

export default HiddenMenu =  injectIntl(HiddenMenu)
