

let observeCallbacks = {} // observeCallbacks[nodeId] = { added: "cb"; removed: "cb" }


export function addNodeObserverCB(id, onAddedCB, onRemvedCB) {
  observeCallbacks[id] = { addedCB: onAddedCB, removedCB: onRemvedCB}
}
export function removeNodeObserverCB(id) {
  observeCallbacks[id] = undefined
}

// Select the node that will be observed for mutations
export const OBSERVER_CLASS = "remarkableMqtt-observed"

// Options for the observer (which mutations to observe)
const config = { attributes: false, childList: true, subtree: true };

// Callback function to execute when mutations are observed
const callback = function(mutationsList, observer) {
    // Use traditional 'for loops' for IE 11
    mutationsList.forEach( mutation => {
        if (mutation.type === 'childList') {
          //console.log('domNodeObserver: A child node has been added or removed.', mutation.target.id, mutation);
          function processObserverByType(node, cbType) {
            if (node && !!node.id && node.classList && node.classList.contains(OBSERVER_CLASS)) {
              let obs = observeCallbacks[node.id]
              if (obs && obs[cbType] && !!node.id)
                obs[cbType](node)
            }
          }
          function processObserverAddedCB(node){
						//console.log('domNodeObserver: A child node has been added', node.id);
            processObserverByType(node, "addedCB")
          }
          function processObserverRemovedCB(node){
            processObserverByType(node, "removedCB")
          }

          mutation.addedNodes.forEach(node => {
              processObserverAddedCB(node)
              // second level
              node.childNodes.forEach( childNode => processObserverAddedCB(childNode) )
            })
          mutation.removedNodes.forEach(node => {
              processObserverRemovedCB(node)
              // second level
              node.childNodes.forEach( childNode => processObserverRemovedCB(childNode) )
            })
        } else if (mutation.type === 'attributes') {
          //console.log('domNodeObserver: The ' + mutation.attributeName + ' attribute was modified.');
        }
      })
};

// Create an observer instance linked to the callback function
const observer = new MutationObserver(callback);
// Start observing the target node for configured mutations
observer.observe(document.body, config);

//observer.disconnect();


export function createUniqueId(prefix) {
  return prefix + "." +  Date.now() + "." + Math.random().toString(36).substring(7) 
}
