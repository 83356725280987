import { injectIntl, defineMessages } from "react-intl";

const msg = defineMessages({

  requestCarWawh: {
    id: 'valet.requestCarWawh',
    defaultMessage: 'Request car wash'
  },

  request: {
    id: 'valet.request',
    defaultMessage: 'Request'
  },

  cancel: {
    id: 'valet.cancel',
    defaultMessage: 'Cancel'
  },

  ready: {
    id: 'valet.ready',
    defaultMessage: 'ready'
  },

  out: {
    id: 'valet.out',
    defaultMessage: 'out'
  },

  requested: {
    id: 'valet.requested',
    defaultMessage: 'requested'
  },

  carwash_requested: {
    id: 'valet.carwash_requested',
    defaultMessage: 'car wash requested'
  },

  washing: {
    id: 'valet.washing',
    defaultMessage: 'washing'
  },

  topark: {
    id: 'valet.topark',
    defaultMessage: 'ready to park'
  },

  parked: {
    id: 'valet.parked',
    defaultMessage: 'parked'
  },

  loading: {
    id: 'valet.loading',
    defaultMessage: 'loading'
  },

  vehicleIs: {
    id: 'valet.vehicleIs',
    defaultMessage: 'Vehicle is'
  },

})

export default msg

