import React from 'react';
import Board from '../../../components/Board.js';
import { injectIntl } from "react-intl";
import msg from './messages';

class Weather extends React.Component {
  state = {
    forecasts: []
  }

  extractDayAndText(text) {
    let [day, restOfText] = text.split("\n");
    return { day, restOfText };
  }

  render() {
    const { intl, weather } = this.props;
    const t = intl.formatMessage;

    return (
      <div className='weather'>
        <Board>
          {weather.map((forecast, index) => {
            const { day, restOfText } = this.extractDayAndText(forecast.text);

            return (
              <div className={"square " + forecast.conditions} key={index}>
                <p className="title">
                  {restOfText !== undefined ? t(msg[day], {day,restOfText}) : forecast.text}
                </p>
              </div>
            );
          })}
        </Board>
        <div className="weather-footer">
          {t(msg.lastUpdate)} {this.props.lastWeatherUpdate}
        </div>
      </div>
    );
  }
}

export default injectIntl(Weather);
