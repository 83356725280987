import React from 'react';
import { Button, Icon, InputNumber, Spin, Tooltip, message} from 'antd';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router-dom';
import PageFrame from '../../../components/PageFrame';
import Header from '../../PageView/Header';
import msg from './messages';
import PaymentMethods from './PaymentMethods';
import { SmartRequests, utils } from '../../../utilities';
import {getFullUrl} from '../../../utilities/smartRequests/requestQueueItem.js'
import Box from '../../../components/Box';
import MessageAlert from '../../../components/MessageAlert';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';


class Pay extends React.Component  {

    constructor(props) {
      super(props)
      this.state = {
        currentBalance: 0,
        amountToPay: 0,
        newBalance: 0,
        difference: 0,
        loading: true,
        isBalanceNull: false,
        showRightArrow: true,
        showLeftArrow: false,
        selectedCard: null,
        nextDueDate: "",
        warning_message: "",

        payment_methods: [],
        balance: {},

        //Messages
        balanceMessage: "",
        currentMsg: "",
        
      }
      this.msgRole = "";

    }
 
    componentDidMount() {
        console.log('Mounting Pay');
        this.t = this.props.intl.formatMessage;
        this.getPaymentsMethods();
    }

    componentDidUpdate(prevProps, prevState) {
        const container = document.querySelector('.items-payment-methods');
        if (container && this.state.showRightArrow && container.scrollWidth <= container.clientWidth) {
            this.setState({showRightArrow: false})
        }

        const prevRole = prevProps.match.params.role;
        const currentRole = this.props.match.params.role;

        //He is a tenant and writes 'owner' in the URL, or he writes anything else in the URL
        if (this.props.userRole && (this.props.userRole === "inquilino" && currentRole === "propietario") || (currentRole !== "propietario" && currentRole !== "inquilino")) {
            this.props.history.goBack();
        } else if (this.props.userRole && this.props.userRole === "propietario" && currentRole !== prevRole && (prevRole === "propietario" || prevRole === "inquilino")) {
            this.getPaymentsMethods();
        }

    }

    getPaymentsMethods = () => {

        this.selectedRole = this.props.match.params.role;

        let accountingRoleUrl = this.selectedRole ? `?selected_accounting_role=${this.selectedRole}` : "";

        SmartRequests.get(`accounting/pay${accountingRoleUrl}`).then( resp => {
            if (resp.status === 200) {
                if (resp.data.balance.amount !== null){
                    this.setInitialState(resp.data);
                } else {
                    this.setState({loading: false, isBalanceNull: true})
                }
                
            }
        }).catch( err => { 
            this.setState({loading: false, isBalanceNull: true})
            utils.showErrorMessage(err.response);
        });
    }

    setInitialState = (data) => {
        let currentMsg = this.getCurrentMsg(data.balance, data.currency_symbol);

        //Convert the data I receive into a string
        let amount = parseFloat(data.balance.amount);

        this.setState({
            payment_methods: data.payment_methods, 
            warning_message: data.warning_message,
            balance: data.balance,
            currency_symbol: data.currency_symbol,
            currentBalance: data.balance.amount.toFixed(2),
            amountToPay: data.balance.amount.toFixed(2),
            currentMsg: currentMsg,
            nextDueDate: data.balance.end_date,
            balanceMessage: this.props.intl.formatMessage(msg.neutralBalance),
            loading: false,
        });

        if (amount < 0){
            let newBalance = Math.abs(amount);
            this.setState({
                newBalance, 
                balanceMessage: this.props.intl.formatMessage(msg.positiveBalance),
            })
        }
    }

    getCurrentMsg = (balance, currency_symbol) => {
        const t = this.props.intl.formatMessage;
        let currentBalance = balance.amount.toFixed(2);

        let currentMsg = t(msg.currentNegativeMsg, {currentBalance, nextDueDate: balance.end_date, currency_symbol});

        if (balance.amount < 0){
            currentMsg = t(msg.currentPositiveMsg, {currentBalance: Math.abs(currentBalance), currency_symbol});
        } else {
            if (balance.amount === 0){
                currentMsg = t(msg.currentNeutralMsg);
            } else {
                currentMsg = t(msg.currentNegativeMsg, {currentBalance, nextDueDate: balance.end_date, currency_symbol});
            }
        }
        return currentMsg;
    }

    handleAmountChange = (value) => {
        if (typeof value === 'number' && !isNaN(value)) {
            const formattedValue = parseFloat(value.toFixed(2));
            this.setState({ amountToPay: formattedValue }, () => {
                this.updateBalanceValues();
            });
        }
    };
    
    updateBalanceValues = () => {
        let difference = (this.state.currentBalance - this.state.amountToPay).toFixed(2);
        difference = !isNaN(difference) ? parseFloat(difference) : parseFloat(this.state.currentBalance);

        let balanceMessage;

        if (difference === 0){
            balanceMessage = this.t(msg.neutralBalance);
        } else {
            if (difference < 0) {
                balanceMessage = this.t(msg.positiveBalance);
            } else {
                balanceMessage = this.t(msg.negativeBalance);
            }
        }
        let newBalance = difference < 0 ? Math.abs(difference) : difference;
        newBalance = newBalance !== Infinity ? newBalance : 0

        this.setState({ newBalance, balanceMessage });
    }

    onChange = (e) => {
        this.setState({ value: e.target.value });
    };

    handleCardClick = (method) => {
        if (this.state.selectedCard) {
          this.setState({ selectedCard: null });
        }
        this.setState({ selectedCard: method });
    };
   
    submitPaymentMethod = async () =>{
        
        let msgError = this.checkSelectedFields();

        if (!this.selectedRole) msgError = this.t(msg.errorDefinedRole);

        if (msgError === ""){
            let data = {};
            data.payment_method = this.state.selectedCard;
            //data.prefix_url = window.location.href.split('/').slice(0, -1).join('/')+'/transaction-info/'
            var redirect_url = await getFullUrl('accounting/payment_redirect')
            data.redirect_url = window.location.origin + redirect_url
            const amountToPayWithoutDecimal = Math.round(this.state.amountToPay * 100).toString(); //Parser to prosis
            data.amount_to_pay = amountToPayWithoutDecimal;
            if (this.selectedRole) data.selected_accounting_role = this.selectedRole;
            SmartRequests.post(`accounting/pay`, data).then( async resp => {
                if (resp.status === 200) {
                    console.log('redirecting')
                    var prefix_url = window.location.origin;
                    var prefix = prefix_url.replace('ws.mirigi.com', 'external.mirigi.com') //fix for ios and android
                    if (prefix==prefix_url) {
                        prefix=''}//let the prefix empty if the url is not ws.mirigi.com and window open handles the host
                    window.open(prefix+redirect_url+'?json_encoded=' + encodeURIComponent(JSON.stringify(resp.data)), '_top')
                }
            }).catch( err => { 
                utils.showErrorMessage(err.response);
            });
        } else {
            message.error(msgError);
        }
    }

    checkSelectedFields = () => {
        let msgError = "";
        if (this.state.amountToPay <= 0){
            msgError = this.t(msg.positiveAmountError);
        } else {
            if (!this.state.selectedCard) {
                msgError = this.t(msg.selectedPaymentMethodError);
            }
        }
        return msgError;
    }

    handleScroll = (event) => {
        const target = event.target;
        const hideRightArrow = target.scrollLeft + target.clientWidth + 1 >= target.scrollWidth;
        const hideLeftArrow = target.scrollLeft === 0;
    
        if (hideRightArrow) {
            this.setState({ showRightArrow: false });
        } else {
            this.setState({ showRightArrow: true });
        }
    
        if (hideLeftArrow) {
            this.setState({ showLeftArrow: false });
        } else {
            this.setState({ showLeftArrow: true });
        }
    }

    handleScrollClick = (value) => {
        const container = document.querySelector('.items-payment-methods');
        container.scrollBy({
            left: value, 
            behavior: 'smooth' 
        });
        
    }
    
    

  render() {

    const t = this.props.intl.formatMessage;

    if (this.selectedRole && (this.selectedRole === "propietario" || this.selectedRole === "inquilino")) {
        this.msgRole = `/ ${t(msg[this.selectedRole])}`;
    }

    const { currentMsg, warning_message, amountToPay, currentBalance, balanceMessage, newBalance, selectedCard, payment_methods, loading, isBalanceNull, currency_symbol, showRightArrow, showLeftArrow } = this.state;

    let inputNumberValue = !isNaN(amountToPay) && amountToPay !== "Infinity" ? amountToPay : 0; //Edge cases for the value of the input number


    return (
        <PageFrame>
            <Header pageInfo={{"content": null}}>{`${t(msg.pay)} ${this.msgRole}`}</Header>
            
            <div className='container-pay'>

                {loading && <Spin size="large"/>}

                {isBalanceNull && <MessageAlert status="warning" title={t(msg.loadDataNull)}/>}

                {!loading && !isBalanceNull &&
                    <Box className='container-box-pay'>
                        <div className='container-header-balance'>
                            <div className='container-total-input'>
                                <h2>{t(msg.total)}</h2>
                                <InputNumber
                                    size='large'
                                    min={0}
                                    value={inputNumberValue}
                                    onPressEnter={this.submitPaymentMethod}
                                    onChange={this.handleAmountChange}
                                    formatter={value => `${value}`}
                                    parser={value => {
                                        const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
                                        return value === '' ? '0' : (regex.test(value) ? value : inputNumberValue);
                                    }}
                                    style={{ width: '100%' }} 
                                    precision={2}
                                /> 
                            </div>
                            <Tooltip 
                                placement="top" 
                                title={currentMsg} 
                            >
                                <div className='container-balance'>
                                    <p>{t(msg.currentBalance) + ": " + currency_symbol + " " + currentBalance}</p>
                                    <Icon type="question-circle" className="icon-question-circle"/>
                                </div>
                            </Tooltip>
                        </div>

                        <p>{t(msg.helpMsg, {balanceMessage, newBalance, currency_symbol})}</p>

                        <div className="line"></div>

                        <div className='container-payment-methods'>
                            <h2>{t(msg.selectPaymentMethods)}</h2>
                            <div className='container-methods'>
                                <div className='left-arrow' style={{ visibility: showLeftArrow ? "visible" : 'hidden' }} onClick={() => this.handleScrollClick(-200)}><DoubleLeftOutlined /></div>
                                <div className='items-payment-methods' onScroll={this.handleScroll}>
                                    {payment_methods && payment_methods.map((method) => 
                                        <PaymentMethods 
                                            key={method}
                                            method={method} 
                                            onClickHandler={this.handleCardClick} 
                                            isSelected={method === selectedCard}
                                        />
                                    )}
                                
                                </div>
                                <div className='right-arrow' style={{ visibility: showRightArrow ? "visible" : 'hidden' }} onClick={() => this.handleScrollClick(200)}><DoubleRightOutlined /></div>
                            </div>
                        </div>
                        <div className='container-next-button'>
                            {warning_message !== "" ? <p>{warning_message}</p> : null}
                            <Button type='primary' className='next-button' onClick={this.submitPaymentMethod}>{t(msg.next)}</Button>
                        </div>
                    </Box>
                }
            </div>
        </PageFrame>
    );
  }
}

export default injectIntl(withRouter(Pay));
