import React from 'react';
import PageFrame from '../../../components/PageFrame.js'
import { Link } from 'react-router-dom'
import Header from '../Header'
import Fetching from '../../../components/Fetching.js'
import { message, Alert } from 'antd';

import { get_vehicle, post_vehicle_event, get_buildings } from './helpers.js'

import * as utils from '../../../utilities/utils.js'

import { injectIntl } from "react-intl";


import Vehicle from '../../Pages/Valet/Vehicle.js';

import msg from './messages'

const POLLING_INTERVAL = 5000

class Valet extends React.Component{
  state={
    fetching:true,
    building_style:''
  }

  componentDidMount = () => {
    get_buildings().then( response => {
      let buildings = response.data
      for (let building of buildings){
        if (building.name == this.props.building){
          this.setState({ building_style: building.style })
          break
        }
      }
    })
    this.refreshVehicleData()
      .finally( this.startPolling )
  }

  changeVehicleState = (id, newState) => {
    this.setState({ state: newState })
  }

  startPolling = () => {
    this.pollingID = setInterval( () => {
      this.refreshVehicleData()
    }, POLLING_INTERVAL )
  }

  stopPolling = () => {
    if (this.pollingID){
      clearInterval( this.pollingID ) 
      this.pollingID = undefined
    }
  }

  refreshVehicleData = () => {
    return get_vehicle(this.props.building, this.props.hash)
      .catch(error => {
        if (!this.hideMessageBanner) {
          this.hideMessageBanner = message.loading(`Could not reach server: ${error.message}. Retrying...`, 0)
        }
        if (error.response) {
          this.setState({fetching: false})
        }
        throw error
      }).then(response => {
        const data = response.data
        this.setVehicleData(data)
        if (this.hideMessageBanner) {
          this.hideMessageBanner()
          this.hideMessageBanner = null
        }
      })
      .catch(() => { })
  }

  setVehicleData = data => {
    const newState = { }
    const state = data.state
    const isVehicleStillAvailable = typeof(state) === 'string';
    if (isVehicleStillAvailable) {
      newState.fetching = false
      newState.color = data.color_name
      newState.ticket = data.current_ticket
      newState.make = data.make_name
      newState.make_id = data.make_id
      newState.model = data.model
      newState.tag = data.tag_number
      newState.building_name = data.building_name
      if (this.state.state !== state){
        newState.state = state
      }
    } else {
      newState.tag = null
    }
    this.setState(newState)
  }

  noFunction = (a,b,c) => {
  }

  requestCar = ( reqData, hash, owner, changeVehicleState ) => {
    post_vehicle_event(this.props.building, hash, 'request').then( () => {
      changeVehicleState(hash, 'requested')
    }).catch( () => {
      message.error('There was a problem with the request.')
      changeVehicleState(hash, 'parked')
    })
  }

  cancelRequest = ( reqData, hash, owner, changeVehicleState ) => {
    console.log('cancelRequest')
    post_vehicle_event(this.props.building, hash, 'cancel').then( () => {
      this.setState(currentState => {
        const isReady = currentState.state === 'ready'
        const isRequested = currentState.state === 'requested'
        if (isReady) {
          return { state: 'topark' }
        } else if (isRequested) {
          return { state: 'parked' }
        }
      })
    }).catch( () => {
      message.error('There was a problem with the request.')
      changeVehicleState(hash, 'requested')
    })
  }

  componentWillUnmount = () => {
    this.stopPolling()
  }

  render(){
    const t = this.props.intl.formatMessage
    if (this.state.fetching){
      return <Fetching enabled/>
    }

    var content = this.state.tag ? 
      <Vehicle 
        key                = { this.state.tag }
        car_wash           = { this.props.car_wash }
        tag_number         = { this.state.tag }
        make               = { this.state.make }
        color              = { this.state.color }
        state              = { this.state.state }
        owner              = { 'visitor' }
        make_id            = { this.state.make_id }
        id                 = { this.props.hash }
        focused            = { true }
        changeVehicleState = { this.changeVehicleState }
        refresh            = { this.noFunction }
        reqData            = { {} }
        quitFocus          = { this.noFunction }
        onFocus            = { this.noFunction }
        context            = { {
          focused_vehicle  : this.props.hash,
          requestCar       : this.requestCar,
          requestCarWash   : this.noFunction,
          cancelRequest    : this.cancelRequest,
          quitFocus        : this.noFunction,
          car_wash         : false
        } }
      />
      :
      <Alert
        message="Oops!"
        description="This vehicle is no longer available."
        type="info"
        showIcon
      />

    return (
      <div className="valet">
        <PageFrame> 
          <Header building={this.props.building} >Valet</Header>
          <div className="board">
            { content }
          </div>
        </PageFrame>
      </div>
    )
  }
}

export default Valet = injectIntl(Valet)
