import React, { Component } from 'react';
import { Spin, Icon } from 'antd';
import { injectIntl } from "react-intl";
import msg from './messages'
import { message } from 'antd'

const HUMAN_READABLE_STATE = {
  ready: msg.ready,
  out: msg.out,
  requested: msg.requested,
  carwash_requested: msg.carwash_requested,
  washing: msg.washing,
  topark: msg.topark,
  parked: msg.parked,
  loading: msg.loading
}

const antLoadingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />

class StateObject extends Component {
  render () {
    const t = this.props.translator

    var renderedComponent

    this.props.children !== 'loading'
      ? renderedComponent = <p className="vehicle-state">{ t(HUMAN_READABLE_STATE[this.props.children]) }</p>
      : renderedComponent = <Spin className="vehicle-state" indicator={antLoadingIcon} />

    return renderedComponent
  }
}

class VehicleInfo extends Component {
  requestCarWash () {
    const data = {
      owner: this.props.owner,
      id: this.props.id
    }
    const previousState = this.props.state
    this.props.onCarWashRequest(data)
      .catch(() => this.recoverStateOnFail(previousState))
    this.props.changeVehicleState(this.props.id, 'loading')
  }

  requestCar () {
    const data = {
      owner: this.props.owner,
      id: this.props.id
    }
    const previousState = this.props.state
    this.props.onCarRequest(data)
      .catch(() => this.recoverStateOnFail(previousState))
    this.props.changeVehicleState(this.props.id, 'loading')
  }

  cancelRequest () {
    const data = {
      owner: this.props.owner,
      id: this.props.id
    }
    const previousState = this.props.state
    this.props.onRequestCancel(data)
      .catch(() => this.recoverStateOnFail(previousState))
    this.props.changeVehicleState(this.props.id, 'loading')
  }

  recoverStateOnFail = (state) => {
    message.error(`Could not request vehicle ${this.props.tag_number}, please contact staff`)
    this.props.changeVehicleState(this.props.id, state)
  }

  render () {
    const t = this.props.intl.formatMessage
    return (
      <div className="vehicle-info">
        <p className="vehicle-tag">{this.props.tag_number}</p>
        <p className="vehicle-make">{this.props.make} ({this.props.color})</p>
        <StateObject translator={t} >{this.props.state}</StateObject>
        <p
          // hidden={ !['out', 'washing', 'topark'].includes(this.props.state) }
          className={ 'hidden-message' + (this.props.isDisplayingMessage? ' spontaneous-apparition': '') }
        >
          { t(msg.vehicleIs) + ' ' + t(HUMAN_READABLE_STATE[this.props.state]) }
        </p>
        <div className="button-holder" hidden={!(this.props.context.focused_vehicle === this.props.id)}>
          <button
            // visible if carwash enabled  and not requested, washing or out
            className="flat-button carwash" 
            onClick={this.requestCarWash.bind(this)}
            hidden={!this.props.context.car_wash.car_wash_available || ['out', 'washing', 'carwash_requested', 'requested', 'ready', 'topark', 'loading'].includes(this.props.state)}
          >

            { t(msg.requestCarWawh) }
          </button>
          <button 
            className="flat-button request" 
            onClick={this.requestCar.bind(this) }
            hidden={ ['out', 'washing', 'topark', 'requested', 'loading', 'ready'].includes(this.props.state) }
          >
            { t(msg.request) }
          </button>
          <button 
            className="flat-button cancel" 
            onClick={ this.cancelRequest.bind(this) }
            hidden={ ['out', 'washing', 'topark', 'parked', 'loading'].includes(this.props.state) }
          >
            { t(msg.cancel) }
          </button>
        </div>
      </div>
    );
  }
}

export default VehicleInfo = injectIntl(VehicleInfo)

  
