import React, { Component } from 'react';

import { Spin, Icon } from 'antd';

const antLoadingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
class LoadingComponent extends Component {
  render() {
    var renderedComponent;

    this.props.isLoading
    ? renderedComponent = <Spin indicator={antLoadingIcon} />
    : renderedComponent = this.props.children

    return (
      <div className="loading-component" style={this.props.style || {}}>
        {renderedComponent}
      </div>
    );
  }
}

export default LoadingComponent