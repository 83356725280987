import './styles/skins/default/index.css'
import './styles/skins/jadebeach/index.css'
import './styles/skins/jadesignature/index.css'
import './styles/skins/red/index.css'
import './styles/skins/textures-color/index.css'
import './styles/skins/montalban/index.css'
import './styles/skins/avenue89/index.css'
import './styles/skins/fifteenfifteen/index.css'
import './styles/skins/cybersuite/index.css'
import './styles/skins/gryphonhouse/index.css'
import './styles/skins/lomaverde/index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'

import 'antd/dist/antd.css'

import Main from './containers/Main'


ReactDOM.render((
    <HashRouter >
      <Main />
    </HashRouter>
), document.getElementById('root'));

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('../firebase-messaging-sw.js')
  .then(function(registration) {
    console.log('Registration successful, scope is:', registration.scope);
  }).catch(function(err) {
    console.log('Service worker registration failed, error:', err);
  });
}
