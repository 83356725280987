import React from 'react';
import { Button, Icon } from 'antd';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router-dom';
import PageFrame from '../../../components/PageFrame';
import Header from '../../PageView/Header';
import msg from './messages';
import MarkdownRenderer from '../../MDRenderer';

class Details extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount() {
    console.log('Mounting Details');
    this.t = this.props.intl.formatMessage;
    if(this.props.pageText === "[]()"){
        this.props.history.goBack();
    }
  }

  render() {
    const t = this.props.intl.formatMessage;

    return (
      <PageFrame>
        <Header pageInfo={{"content": null}}>{t(msg.movementDetail)}</Header>
        <div className='container-link-pdf'>
          <Button onClick={() => window.open(this.props.link, '_system')}>
            <Icon type="upload" /> {t(msg.download)}
          </Button>
        </div>
        <div className='container-pdf'>
          <MarkdownRenderer markdown={this.props.pageText}></MarkdownRenderer>
        </div>
      </PageFrame>
    );
  }
}

export default injectIntl(withRouter(Details));

