import { injectIntl, defineMessages } from "react-intl";

const msg = defineMessages({
  
  from: {
    id:'deliveries.from',
    defaultMessage: 'From'
  },

  storedIn: {
    id:'deliveries.storedIn',
    defaultMessage: 'Stored in'
  },
  deliveredAt: {
    id:'deliveries.deliveredAt',
    defaultMessage: 'Delivered'
  },
  info: {
    id:'deliveries.info',
    defaultMessage: 'More info'
  }
// S_DELIVERY_DELIVERED = _('delivered')
// S_DELIVERY_RETURNED = _('returned')
// S_DELIVERY_STORED = _('stored')      # initial
// S_DELIVERY_DELETED = _('deleted')
,
  /* delivery states */ 
  stored: {
    id:'delivery.stored',
    defaultMessage: 'Pending'
  }
  ,
  delivered: {
    id:'delivery.delivered',
    defaultMessage: 'Delivered'
  }
  ,
  returned: {
    id:'delivery.returned',
    defaultMessage: 'Returned'
  },
  deleted: {
    id:'delivery.deleted',
    defaultMessage: 'Deleted'
  }
  ,

//# events
//E_DELIVERY_DELIVER = _('deliver')
//E_DELIVERY_MOVE = _('move')
//E_DELIVERY_STORE = _('store')
//E_DELIVERY_RECEIVE = _('receive')        # initial
//E_DELIVERY_RETURN = _('return')
//E_DELIVERY_ATTACH = _('Information attach')  
//E_DELIVERY_DELETE = _('delete')
  deliver: {
    id:'delivery.event.deliver',
    defaultMessage: 'Deliver'
  }
  ,
  move: {
    id:'delivery.event.move',
    defaultMessage: 'Move'
  }
  ,
  store: {
    id:'delivery.event.store',
    defaultMessage: 'Store'
  }
  ,
  receive: {
    id:'delivery.event.receive',
    defaultMessage: 'Receive'
  },
  delete: {
    id:'delivery.event.delete',
    defaultMessage: 'Delete'
  },
  "Information attach": {
    id:'delivery.event.Informationattach',
    defaultMessage: 'Information attach'
  },
  return: {
    id:'delivery.event.return',
    defaultMessage: 'Return'
  }
  //filters
  ,
  all: {
    id:'deliveries.all',
    defaultMessage: 'All'
  }
  ,
  tracking: {
    id:'deliveries.tracking',
    defaultMessage: 'Tracking#'
  }
  ,
  loading: {
    id:'deliveries.loading',
    defaultMessage: 'Loading...'
  }
  ,
  show: {
    id:'deliveries.show',
    defaultMessage: 'Show'
  }
  ,
  ammount: {
    id:'deliveries.ammount',
    defaultMessage: 'Ammount'
  }
  ,
  noDeliveriesMatch: {
    id: "deliveries.noDeliveriesMatch",
    defaultMessage: "No deliveries found matching criteria!"
  }

})


export default msg


