import { defineMessages } from "react-intl";

const msg = defineMessages({

    pageInformation: {
    id:'pageview.pageInformation',
    defaultMessage:'Page Information'
  },
    
})


export default msg