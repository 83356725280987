
import * as CustomStorage from '../../../utilities/customStorage'
import BuildingInfo from '../buildingInfo';
import * as SmartRequests from '../../../utilities/smartRequests'

const CURRENT_KEY = 'CurrentToken';
const OLD_KEY = 'OldToken';

class CordovaToken {

    constructor(updateToken){
      this.onTokenCallback = updateToken;
    }

    onTokenCallback = (token) => console.log(`TOKEN RECIEVED: ${JSON.stringify(token)}`);

    setToken = async data => {
        console.log(`Setting token: ${JSON.stringify(data)}`);
        let token = {};
        if (await this.mustRefresh(data.key)) {
            await this.readNewToken(data.key);
            token.sendToServer = () => this.refreshToken({
                newToken: this.getCurrent(),
                oldToken: this.getOld(),
            });
        } else {
            token.sendToServer = () => this.registerToken({
                key: data.key,
                name: data.name,    
                service: data.service,
            });
        }
        this.onTokenCallback(token);
    }

    mustRefresh = async (key) => {
        let currentKey = await CustomStorage.getItem(CURRENT_KEY);
        return key === currentKey;
    }

    readNewToken = async (newKey) => {
        let currentKey = await CustomStorage.getItem(CURRENT_KEY);
        await CustomStorage.setItem(CURRENT_KEY, newKey);
        if (currentKey) {
            await CustomStorage.setItem(OLD_KEY, currentKey);
        }
    }

    getOld = async () => {
        return CustomStorage.getItem(OLD_KEY);
    }

    getCurrent = async () => {
        return CustomStorage.getItem(CURRENT_KEY);
    }

    registerToken = async (data) => {
        return SmartRequests.post('push_devices', data)
    }

    refreshToken = async (data) => {
        return SmartRequests.post('refresh_device', data)
    }

    onToken = (callback) => {
        this.onTokenCallback = callback;
    }
}

export default CordovaToken