import React from 'react';
import { Button, Spin } from 'antd';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router-dom';
import PageFrame from '../../../components/PageFrame';
import Header from '../../PageView/Header';
import { SmartRequests } from '../../../utilities';
import msg from './messages';
import MessageAlert from '../../../components/MessageAlert';

class TransactionInfo extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      typeOfIcon: "info",
      subtitle: "pendingSub",
      twoToneColor: "blue",
    }
  }

  componentDidMount() {
    console.log('Mounting TransactionInfo');
    this.t = this.props.intl.formatMessage;
    this.getInfoTransaction();

  }

  getInfoTransaction = () => {
    SmartRequests.get(`accounting/transaction/${this.props.id}`).then(resp => {
        if (resp.status === 200) {
          this.assignValues(resp.data);
        }
    }).catch(err => {
      this.assignValues(err.response.data);
    });
  }

  componentDidUpdate (prevProps, prevState) {
    if(this.props.location.pathname != prevProps.location.pathname){
      this.getInfoTransaction();
    }
  }

  assignValues = (data) => {
    let { status } = data;
    let subtitle, typeOfIcon;

    if (status === "pending"){
      typeOfIcon = "info";
      subtitle = "pendingSub"
    } else {
      if (status === "success"){
        typeOfIcon = "success";
        subtitle = "successSub"
      } else {
        typeOfIcon = "error";
        status="error"
        subtitle = "failSub"
      }
    }

    this.setState({status, typeOfIcon, subtitle})
  }

  goDashboardPage = () => {
    this.props.history.push({pathname: `/${this.props.reqData.building.name}/${this.props.unit}/dashboard`});
  }


  render() {
    const t = this.props.intl.formatMessage;
    const { status, typeOfIcon, subtitle } = this.state;
    if(this.props.newMqttMessage && this.props.mqttTopicMessage === "expenses_info") {
      this.getInfoTransaction();
      this.props.pageMessageReceivedCallback()
    }
    return (
        <PageFrame>
          <Header pageInfo={{"content": null}}>{t(msg.paymentTracking)}</Header>
          {!status ? <Spin size="large"/> : 
            <div className='container-trans-info'>
              <MessageAlert status={typeOfIcon} title={t(msg[status])} subTitle={t(msg[subtitle])} reqData={this.props.reqData}/>
              <Button style={{marginTop: "1rem"}} type='primary' onClick={this.goDashboardPage}>{t(msg.goDashboardPage)}</Button>
            </div>
          }
        </PageFrame>
    );
  }
}

export default injectIntl(withRouter(TransactionInfo));
