import React from 'react';
import { Icon, Slider } from 'antd';

import msg from './messages';
import { injectIntl } from "react-intl";
import { CordovaCommunication } from '../Main/cordovaCommunication';


class BrightnessSlider extends React.Component {
  state = {
    value: 0,
  }

  notifyCordova = (value) => console.log(`Recieved new value (${value}) of brightness, but handler is not yet ready`);

  componentDidMount = () => {
    this.closeBrightness = CordovaCommunication.setBrightnessHandlers(
      this.changeBrightness, 
      this.setCordovaBrightnessCallback
    ).close;
  }

  componentWillUnmount = () => {
    this.closeBrightness();
  }

  onChangeBrightness = (value) => {
    this.changeBrightness(value);
    this.notifyCordova(value / 100);
  }

  changeBrightness = value => {
    this.setState({ brightness: value });
  }

  setCordovaBrightnessCallback = (callback) => {
    this.notifyCordova = callback;
  }

  render = () => {
    const t = this.props.intl.formatMessage;
    return (
      <div className="frame">
        <h1>{t(msg.brightness)} <Icon type="bulb" /> </h1>
          <Slider {...this.props} onChange={this.onChangeBrightness} value={this.state.brightness} />
      </div>
    );
  }
}

export default BrightnessSlider = injectIntl(BrightnessSlider);
