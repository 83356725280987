import React from 'react';

const RestaurantDelivery = () => {
    return (
		<svg 
		width="24"
		height="24"
		xmlns="http://www.w3.org/2000/svg" width="28.001" height="27.999" viewBox="0 0 48.001 47.999">
			<path d="M47.275,31.758H38.053A11.372,11.372,0,0,0,30.7,23.192l-3.64-1.281a4.831,4.831,0,0,1-2.576-2.764,11.944,11.944,0,0,0,4.144-9.3A11.229,11.229,0,0,0,27.834,5.6,9,9,0,0,0,19.151,0a9,9,0,0,0-8.675,5.584,11.208,11.208,0,0,0-.8,4.267,11.953,11.953,0,0,0,4.15,9.3,4.645,4.645,0,0,1-2.579,2.781L7.605,23.223A11.41,11.41,0,0,0,0,33.967V47.272a.728.728,0,0,0,1.455,0v-13.3a9.953,9.953,0,0,1,6.634-9.373l2.95-1.041c1.049,2.438,5.6,4.246,7.385,4.873v3.332H14.587a.728.728,0,0,0-.728.727V47.272a.728.728,0,0,0,1.455,0V33.213H25.355V42.5a.728.728,0,0,0,1.1.625l4.475-2.686,4.475,2.686a.733.733,0,0,0,.374.1.7.7,0,0,0,.357-.1.723.723,0,0,0,.369-.633V33.213h10.04V47.272a.728.728,0,0,0,1.455,0V32.485A.727.727,0,0,0,47.275,31.758ZM19.153,1.454A7.646,7.646,0,0,1,26.106,5.34c-.011,0-.018.014-.026.021a21.639,21.639,0,0,0-3.754,1.354,13.065,13.065,0,0,1-2.844,1.023c-3.835.676-6.142-.651-7.469-2.025A7.609,7.609,0,0,1,19.153,1.454Zm-8.02,8.4a10.234,10.234,0,0,1,.331-2.638C13.1,8.661,15.725,9.88,19.735,9.169a14.312,14.312,0,0,0,3.177-1.123A18.907,18.907,0,0,1,26.691,6.7a10.022,10.022,0,0,1,.481,3.151c0,5.4-3.6,9.8-8.021,9.8S11.133,15.254,11.133,9.85ZM12.365,23a6.048,6.048,0,0,0,2.707-3.01,8.041,8.041,0,0,0,8.2-.02,6.2,6.2,0,0,0,2.675,3c-.627,1.5-4.139,3.26-6.792,4.17C16.5,26.242,13,24.494,12.365,23ZM35.053,41.221l-3.747-2.25a.731.731,0,0,0-.374-.1.721.721,0,0,0-.374.1l-3.747,2.25V33.213h8.242Zm.728-9.463h-15.9V28.426c1.786-.635,6.337-2.459,7.385-4.9l2.952,1.037a9.935,9.935,0,0,1,6.366,7.2Z" fill="#1a171b"/>
		</svg>
		)
}
export default RestaurantDelivery;
