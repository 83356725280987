import { defineMessages } from "react-intl";

const msg = defineMessages({

    nothingToShow: {
        id:'messages.nothingToShow',
        defaultMessage:'There is nothing to show'
    },
})

export default msg