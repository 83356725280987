import BuildingInfo from '../containers/Main/buildingInfo';
import { store as storeCredentials } from '../containers/Main/credentialsStorage'
import { setupOauth } from './smartRequests/authentication'
import * as SmartRequests from './smartRequests'
import { message } from 'antd';


// Variables
export const VERSION = 2

// Utils
export function URLEncode(parms){
    const res = new URLSearchParams();
    for (let key in parms){
      res.append(key, parms[key]);
    }
    return res
}

export async function handlePreviousPreferences(preferences) {
  try {
    await BuildingInfo.setCurrentBuilding(preferences.building);
    await BuildingInfo.setCurrentUnit(preferences.unit);

    console.log(`OAuth ${preferences.oauth ? 'is' : 'is not'} enabled`);
    if (preferences.oauth) {
      const accessToken = preferences.token.replace('Bearer ', '');
      const refreshToken = preferences.refreshToken;
      await setupOauth(accessToken, refreshToken);
    } else {
      setCookie(preferences.token)
      if (preferences.password && preferences.user) {
        const user = preferences.user
        const pass = preferences.password
        await storeCredentials({ user, pass })
      }
    }
    return true
  } catch(e) {
    return false
  }
}

export function setCookie(cookie){
  console.log(`Setting new cookie: ${cookie}`);
  document.cookie = cookie;
}

export function getPlatform() {
  const params = getUrlParams();
  if (params.platform_id) {
    return params.platform_id;
  }
  return 'browser';
}

export function isNativeStorageSupported() {
  const params = getUrlParams();
  return !!params.use_native_storage;
}

function getUrlParams() {
  const unparsedParams = window.location.search.substring(1) || "";
  const params = unparsedParams.split('&').reduce((acumulator, param) => {
    const [key, value] = param.split('=');
    const nextValue = acumulator;
    nextValue[key] = value;
    return nextValue;
  }, { });
  return params;
}

export function isAccessingAsGuest() {
  const location = document.location
  const hash = location.hash
  const doesPathMatchesGuestRoute = hash.match(/\#\/[^\/]*\/guest\/.*/)
  return !!doesPathMatchesGuestRoute
}

export function showErrorMessage(response) {
  const { status, statusText } = response;
  message.error(status + " " + statusText);
}

// Basic requests
export function get(unit, url, params){
  return SmartRequests.get(url, null , params)
}

