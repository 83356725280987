import * as utils from '../../../utilities/utils.js';
import { SmartRequests } from '../../../utilities/index.js';

const STATE_AFTER_EVENT = {
  'request':        'requested',
  'requestcarwash': 'carwash_requested',
  'cancel':         'parked',
}

function valetAction (rData) {
  // Request data (rData) will contain vehicle data and request type (carwash request, normal request or cancel request)
  const action = rData.action
  const urlpath = `vehicles/${rData.id}/events`
  const params = { }

  params.event = action
  return SmartRequests.post(urlpath, params)
    .then(function (response) {
      if (response.status === 200) {
        console.log(`[valet:actions.valetAction()]> Action '${rData.action}' performed over vehicle ${rData.id}`);
        if (rData.trigger) {
          rData.trigger(rData.id, STATE_AFTER_EVENT[action]);
        }
      }
    }).catch(function (error) {
      console.log(`Error on request transaction: ${error}`)
      throw error
    })
}

// Requests against certain car
export function requestCar (reqData, id, owner, trigger) {
  return valetAction({
    id: id,
    action: 'request',
    owner: owner,
    trigger: trigger
  })
}
export function requestCarWash (reqData, id, owner, trigger) {
  return valetAction({
    id: id,
    action: 'requestcarwash',
    owner: owner,
    trigger: trigger
  })
}
export function cancelRequest (reqData, id, owner, trigger) {
  return valetAction({
    id: id,
    action: 'cancel',
    owner: owner,
    trigger: trigger
  })
}

// Get all vehicle data
export function refresh () {
  return SmartRequests.get('vehicles').then((response) => {
    this.setState({ vehicles: response.data })
    console.log('[valet:actions.refresh()]> Car data successfully received')
  }).catch(
    (error) => console.log(`[valet:actions.refresh()]! Error refreshing car data: ${error}`)
  )
}

export function quitFocus (vehicleID) {
  if (this.state.focused_vehicle === vehicleID) {
    setFocus.bind(this)(null)
  }
}

export function setFocus (vehicleID) {
  this.setState(function (state, props) {
    let newValue = null
    if (!(vehicleID === state.focused_vehicle)) {
      newValue = vehicleID
    }
    console.log(`[valet:actions.setFocus()]> Focused vehicle changed from ${state.focused_vehicle} to ${newValue}`)
    return { focused_vehicle: newValue }
  })
}
