import { Button } from 'antd';
import React from 'react';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router-dom';
import msg from './messages';


class ItemPoll extends React.Component  {
    constructor(props) {
        super(props);
    }

  componentDidMount() {
    console.log('Mounting PollItem');
    this.t = this.props.intl.formatMessage;
    
  }

  completePoll = (id_poll) => {
    this.props.history.push({
      pathname:`/${this.props.building}/${this.props.unit}/dashboard/polls/${id_poll}`,
    });
  }


  render() {
    const t = this.props.intl.formatMessage;
    const {id, title, description, web_status, answered, end_date} = this.props.dataPoll;

    let pollFinished = web_status === "finished";
    let typeOfButton = pollFinished || answered === 1 ? "default" : "primary";
    let textOfButton = pollFinished || answered === 1 ? t(msg.viewResults) : t(msg.answerPoll);

    return (
      <div className='container-item'>
        <li className='li-poll li-request'>
          <div className='header-poll'>
            <h1>{title}</h1>
            <p>{(pollFinished ? t(msg.finished) : t(msg.Finishes)) + " " + end_date}</p>
          </div>
          <div>
            <p>{description}</p>
          </div>
          <div className='button-answer-poll'>
            <Button type={typeOfButton} onClick={() => this.completePoll(id)}>{textOfButton}</Button>
          </div>
        </li>
      </div>

    );
  }
}

export default injectIntl(withRouter(ItemPoll));