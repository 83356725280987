import React from 'react'
import Scrollable from './Scrollable';

export class PageFrame extends React.Component{
  render(){
    return(
      <div className={'page-frame ' + this.props.className}>
        <Scrollable>
          {this.props.children}
        </Scrollable>
      </div>
    )
  }
}

export default PageFrame;
