import * as CustomStorage from './customStorage';
import { isNativeStorageSupported } from '../utils';

const ALREADY_MIGRATED = 'ALREADY_MIGRATED';
const PING_TIMEOUT = 700;

export async function setup() {
  const isNativeAvailable = isNativeStorageSupported();
  if (isNativeAvailable) {
    CustomStorage.setDefaultSource(CustomStorage.NATIVE_SOURCE);
    if (!await alreadyMigrated()) {
      await makeMigration();
    }
  }
}

async function alreadyMigrated() {
  const migrated = await CustomStorage.getItem(ALREADY_MIGRATED);
  if (migrated) console.log('Custom storage was already migrated');
  return migrated;
}

async function makeMigration() {
  const localStorageKeys = Object.keys(window.localStorage);
  const migrationTasks = localStorageKeys.map(async key => {
    const value = localStorage.getItem(key);
    await CustomStorage.setItem(key, value);
    console.log(`MIGRATED ${key}:${value} to CustomStorage (${CustomStorage.getDefaultSource()})`);
  });
  await Promise.all(migrationTasks);
  await setAlreadyMigrated();
  localStorageKeys.forEach(key => {
    localStorage.removeItem(key);
  });
}

async function setAlreadyMigrated() {
  await CustomStorage.setItem(ALREADY_MIGRATED, true);
}
