import { RequestQueueItem } from "./requestQueueItem";
import * as CredentialStorage from '../../containers/Main/credentialsStorage';
import { usesOauth, refreshToken as refreshOauthToken, loginDevice, logoutDevice } from './authentication'

export { usesOauth, loginDevice, logoutDevice }

const requestQueue = []

var error = false
var isRetryingAuthentication = false

const dummyPromiseFactory = (name) => (() => new Promise((resolve, reject) => { reject(new Error(`No function provided on ${name}`)) }))

var login = dummyPromiseFactory('LOGIN')
var authError = dummyPromiseFactory('AUTH_ERROR')
var dismissError = dummyPromiseFactory('DISMISS_ERROR')

export function init (loginFunction, onAuthError, onDismissError) {
  login = loginFunction
  authError = onAuthError
  dismissError = onDismissError
}

const requestFactory = (method) => {
  return (url, data, params) => new Promise((res, rej) => {
    let request
    const resolve = (...args) => {
      res(...args)
      removeRequest(request)
    }
    const reject = rej.bind(this)
    request = new RequestQueueItem({ method, url, data, resolve, reject, params })
    requestQueue.push(request)
    if (!isRetryingAuthentication) {
      requestRunner(request)
    }
  })
}

export const get = requestFactory('GET')
export const post = requestFactory('POST')
export const Delete = requestFactory('DELETE')
export const put = requestFactory('PUT')
export const head = requestFactory('HEAD')

const removeRequest = (request) => {
  const index = requestQueue.indexOf(request);
  requestQueue.splice(index, 1);
}

async function authenticationErrorCatcher(e) {
  const request = e.request;
  if (request) {
    const status = request.status;
    if (status == 401 && !error) {
      error = true;
      cancelAllRequests();
      let askForNewSession
      if (await usesOauth()) {
        askForNewSession = refreshOauthToken()
      } else {
        askForNewSession = CredentialStorage.recover().then(({user, pass}) => {
          if (!(user && pass)) throw new Error('No credentials found in storage');
          return login(user, pass)
        })
      }
      isRetryingAuthentication = true
      askForNewSession
        .then(() => { error = false })
        .then(dismissError)
        .then(retryAllRequests)
        .catch(errorHandler)
        .finally(() => { isRetryingAuthentication = false })
    }
  } else if (error) {
    errorHandler();
  }
}

const errorHandler = () => {
  authError();
  clearQueue();
}

function cancelAllRequests() {
  requestQueue.forEach((request) => { request.cancel() });
}

function retryAllRequests() {
  requestQueue.forEach(request => { requestRunner(request) });
}

const requestRunner = (request) => {
  request.run().catch(authenticationErrorCatcher);
}

function clearQueue() {
  cancelAllRequests();
  while(requestQueue.length !== 0) {
    const request = requestQueue.pop();
    console.log("Rejected request:",request)
    try {
      request.reject()
    } catch (e) {
      console.log('Error when rejecting request', e)
    }
  }
  error = false
}
