import * as mqttClient from '../../Dashboard/mqttClient'
import { addNodeObserverCB, removeNodeObserverCB, createUniqueId, OBSERVER_CLASS } from './domNodeObserver.js'

class ButtonNode {
  constructor(topic) {  // Constructor
    this.id = createUniqueId("Button")
    this.unsubscribeFunction = null
    this.topic = topic
    this.lastValue = undefined
    const that = this
    console.log("remarkableMqtt: Creating a button node link!")
		const statusTopic = this.topic + "/status"
		console.log("remarkableMqtt:  subcribed to:", statusTopic)
		this.unsubscribeFunction = mqttClient.subscribeToTopic(statusTopic, (rtopic,msg) => {
				const fullState = JSON.parse(msg)
				that.lastValue = fullState["STATE"].toLowerCase()
				console.log("remarkableMqtt: button status updated: ", that.id, rtopic, this.lastValue)
        that.syncState()
			})
  }


	syncState() {
		if (this.elem) {
			const CLASS_ON = "ant-btn-primary"
			if (typeof(this.lastValue) !== "undefined" && (parseInt(this.lastValue) >= 1 || this.lastValue.toLowerCase() == "true"))  {
				if (this.elem.classList.contains(CLASS_ON)) 
					this.elem.classList.add(CLASS_ON);
			} else {
				if (this.elem.classList.contains(CLASS_ON)) 
					this.elem.classList.remove(CLASS_ON);
			}
		}
	}
  

  actionHandlerPress () {
    console.log("remarkableMqtt: Button pressed!")
    mqttClient.publish(this.topic + "/exe/PRESS", "{}")
  }
  actionHandlerRelease() {
    console.log("remarkableMqtt: Button release!")
    mqttClient.publish(this.topic + "/exe/RELEASE", "{}")
  }
  processMyEvent(event,actionHandler) {
    if (event.target.id !== this.id) return;
    // Don't follow the link
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    // doit
    actionHandler();
    return true;
  }


  link_open(topic, className = '', origin, related, attr = 'src') {
    console.log("remarkableMqtt: Creating a button node link_open function called!")

    addNodeObserverCB(this.id,  (elem) => {
        elem.addEventListener('mousedown', (e)=> this.processMyEvent(e,()=>this.actionHandlerPress()), false);
        elem.addEventListener('mouseup',   (e)=> this.processMyEvent(e,()=>this.actionHandlerRelease()), false);
        elem.addEventListener('keydown',   (e)=> this.processMyEvent(e,()=>this.actionHandlerPress()), false);
        elem.addEventListener('keyup',     (e)=> this.processMyEvent(e,()=>this.actionHandlerRelease()), false);
        this.elem = elem
				this.syncState()
      },
      (target) => {

        if (this.unsubscribeFunction) {
          this.unsubscribeFunction()
          this.unsubscribeFunction = null;
        }
				console.log("remarkableMqtt: Component removed for " , this.id)
      })

    return `<div class="remarkable-mqtt-wrapper"> 
    <button class="remarkable-mqtt ${className} ${OBSERVER_CLASS} ant-btn ant-btn-lg" id="${this.id}" >
    `;
  }
  link_close(topic, className = '', origin, related, attr = 'src') {
    return "</button></div>";
  }
}

export default ButtonNode
