import React from 'react';
import  { Component }  from 'react';
import * as mqttClient from '../../Dashboard/mqttClient'
import { addNodeObserverCB, removeNodeObserverCB, createUniqueId, OBSERVER_CLASS } from './domNodeObserver.js'
import  ButtonNode from './buttonNode.js'
import  StatusNode from './statusNode.js'


class DefaultNode {
  constructor(md) {  
  this.link_open = md.renderer.rules.link_open;
  this.link_close = md.renderer.rules.link_close;
  }
}


function isMqttAddress(topic) {
  return topic.indexOf('mqtt:') === 0;
}

let resultCache = {}
// e.g: 0/buildings/ikatu/units/rnd/controllers/C0330A1839-00085/resources/1301/1404/BUTTON/27019
//                                    1:building    2:unit               3:sn              4:aid  5:zid    6:rType 7:rid 
//let parseRE = new RegExp('^0/buildings/([^/]+)/units/([^/]+)/controllers/([^/]+)/resources/([^/]+)/([^/]+)/([^/]+)/([^/\?]+)\?\{0,1\}(.*)$');
//let parseRE = new RegExp('^0\/buildings\/([^\/]+)\/units\/([^\/]+)\/controllers\/([^\/]+)\/resources\/([^\/]+)\/([^\/]+)\/([^\/]*)\/([^\/\?]+)\?{0,1}(.*)$');
let parseRE = /^0\/buildings\/([^\/]+)\/units\/([^\/]+)\/controllers\/([^\/]+)\/resources\/([^\/]+)\/([^\/]+)\/([^\/]*)\/([^\/\?]+)\?{0,1}(.*)$/;
function parseResourceTopic(topic) {
	if (!resultCache[topic]) {
		if (topic.match(parseRE))
			resultCache[topic] =parseRE.exec(topic)
	}
	return resultCache[topic] || undefined
}

function getParsedResultTopicElement(topic, index) {
  
  var results = parseResourceTopic(topic) 
  console.log("remarkableMqtt: parsed topic results: ", results)
  if (!!results && results.length >= index) return results[index]
  else return undefined
}

function getResourceTypeFrom(topic) {
  console.log("remarkableMqtt: parsed topic: ", topic)
  return getParsedResultTopicElement(topic, 6)
}
function getResourceVariableFrom(topic) {
  console.log("remarkableMqtt: parsed topic: ", topic)
  return getParsedResultTopicElement(topic, 8)
}


const remarkableMqtt = (md, config = {}) => {
  let defaultNode = new DefaultNode(md); //FIXME is this correct? check how is done in youtube plugin
  md.renderer.rules.link_open = (tokens, idx, options, env) => {
    const href = tokens[idx].href;
    env._customNode = defaultNode;
    if (isMqttAddress(href)) {
      console.log("remarkableMqtt: parsing matched: ",href)
      const rawTopic = href.split(':')[1];
      const topic = rawTopic.split('?')[0];
      const rtype = getResourceTypeFrom(rawTopic)
      const variable = getResourceVariableFrom(rawTopic)
      console.log("remarkableMqtt: parsed topics: ",rawTopic, topic)
      console.log("remarkableMqtt: parsed type: ",rtype, "variable =", variable)

      if (variable && variable.length > 0) {
        console.log("remarkableMqtt: a variable node should be displayed: ",variable)
        env._customNode = new StatusNode(topic,variable);
      } else if (rtype == "BUTTON") {
        console.log("remarkableMqtt: parsed type: ",rtype)
        env._customNode = new ButtonNode(topic);
      }
    }
    return env._customNode.link_open(tokens, idx, options, env);
  };

  md.renderer.rules.link_close = (tokens, idx, options, env) => {
    let result = env._customNode.link_close(tokens, idx, options, env);
    env._customNode = undefined;
    return result
  };
};

export default remarkableMqtt;
