import React from 'react'
import { Spin, Icon } from 'antd'


class Fetching extends React.Component{
  render(){
    if(this.props.enabled){
      return (
        <div className={this.props.light? "loading-screen light" : "loading-screen"}>
          <Spin size='large'  indicator={<Icon type="loading" className='loading-icon' spin />} />
        </div>
      )
    }else{
      return '' 
    }
  }
}

export default Fetching