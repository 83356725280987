import React from 'react';
import msg from './messages.js';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router-dom';
import { Typography } from 'antd';
const { Text } = Typography;

class ChooseQuantity extends React.Component {
    render () {
        let textToShow;
        const min = this.props.min_quantity
        const max = this.props.max_quantity
        const cant_opciones = this.props.cant_opciones
        const highlight = this.props.highlight
        const animate = this.props.animate
        const max_quantity_reached = this.props.max_quantity_reached
        let text_style = {marginTop: '-2vh', marginBottom: '3vh'}

        const translate = this.props.intl.formatMessage // Get translation function from props

        if (highlight) {
        text_style = {color:'red', marginTop: '-2vh', marginBottom: '3vh'}
        } else if (max_quantity_reached) {
        text_style = {color:'green', marginTop: '-2vh', marginBottom: '3vh'}
        }
        
        if (min === max) {
        textToShow = translate(msg.chooseQuantityOptions, {count: min, plural: min > 1})
        } else if (min === 0) {
        textToShow = translate(msg.chooseQuantityUpTo, {count: max, plural: max > 1})
        } else if (max === cant_opciones){
        textToShow = translate(msg.chooseQuantityAtLeast, {count: min, plural: min > 1})
        } else {
        textToShow = translate(msg.chooseQuantityRange, {min: min, max: max})
        }
        return (
        <>
            <Text underline onAnimationEnd={() => this.props.onAnimationEnd()}className={animate ? 'title-jump-animation':''} style={text_style}>{textToShow}</Text>
        </>
        )
    }
}

export default injectIntl(withRouter(ChooseQuantity));