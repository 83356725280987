import { SmartRequests } from './utilities'
import * as customStorage from './utilities/customStorage'
import { CordovaCommunication } from './containers/Main/cordovaCommunication'

const INDEX_FILE = './'
const ETAG_HEADER = 'etag'
const CURRENT_ETAG = 'CURRENT_ETAG'
const LAST_ETAG = 'LAST_ETAG'

export async function downloadLast () {
  const lastETag = await customStorage.getItem(LAST_ETAG)
  await customStorage.setItem(CURRENT_ETAG, lastETag)
  CordovaCommunication.closeAllChannels()
  window.location.reload()
}

export async function checkForUpdates () {
  const lastETag = await refreshLastETag()
  const currentETag = await customStorage.getItem(CURRENT_ETAG)
  const differs = lastETag !== currentETag
  return differs
}

async function refreshLastETag () {
  const response = await SmartRequests.head(INDEX_FILE)
  const headers = response.headers
  const eTagHeader = headers[ETAG_HEADER]
  await customStorage.setItem(LAST_ETAG, eTagHeader)
  return eTagHeader
}

// Check that no other version has appeared while loading

async function assertLastVersion () {
  const anotherVersionAvailable = await checkForUpdates().catch(e => console.log(`Could not check etag. ${e}`))
  if (anotherVersionAvailable) {
    await downloadLast()
  }
}

assertLastVersion()
