import React from 'react';
import Stagger from 'react-css-stagger';

const PageUl = ({ children, delay }) => (
  <ul className="page-ul">
    <Stagger transition="page-ul" delay={200} initialDelay={delay || 200}>
      { isThereNothingToShow(children) ? NothingToShowComponent() : children }
    </Stagger>
  </ul>
)

function isThereNothingToShow(data) {
  if (!data) return true;
  if (Array.isArray(data) && data.length === 0) return true;
  return false;
}

const NothingToShowComponent = () => [<li className='li-request'> There is nothing to show </li>];

export default PageUl
