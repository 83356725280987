import React, { Component } from 'react';
//import './App.css';
import { refresh, requestCar, requestCarWash, cancelRequest, quitFocus, setFocus } from './Helpers';
import Board from '../../../components/Board.js'
import LoadingComponent from '../../../components/LoadingComponent'
import Vehicle from './Vehicle';

//fixme: remove style sheet
//import './basic-style.css';

class Valet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'car_wash': { },
      'vehicles': this.props.vehicles || [],
      'focused_vehicle': null,
    };

    // Bind this to member functions
    this.updateVehiclesState = this.updateVehiclesState.bind(this);
  }

  updateVehiclesState(states) {
    this.setState({vehicles: states});
  }

  render() {
    console.log('here:valet')
    const isLoading = (this.props.vehicles.length === 0) && !this.props.hasNoCars
    return (
      isLoading
      ? <LoadingComponent isLoading style={{"display": "flex", "justify-content": "center"}} />
      : <div className='valet'>
          <Board>
            {
              this.props.vehicles.map((item, index) =>
                <Vehicle 
                  key                = { item.id }
                  car_wash           = { this.props.car_wash }
                  tag_number         = { item.tag_number }
                  make               = { item.make }
                  color              = { item.color }
                  state              = { item.state }
                  owner              = { this.props.unit }
                  make_id            = { item.make_id }
                  id                 = { item.id }
                  focused            = { this.state.focused_vehicle === item.id }
                  onFocus            = { setFocus.bind(this) }
                  refresh            = { refresh.bind(this) }
                  quitFocus          = { quitFocus.bind(this) }
                  changeVehicleState = { this.props.changeVehicleState }
                  reqData            = { this.props.reqData }
                  context            = { {
                    'focused_vehicle': this.state.focused_vehicle,
                    'requestCar': requestCar.bind(this),
                    'requestCarWash': requestCarWash.bind(this),
                    'cancelRequest': cancelRequest.bind(this),
                    'quitFocus': quitFocus.bind(this),
                    'car_wash': this.props.car_wash
                  } }
                />
              )
            }
          </Board>
        </div>
    );
  }
}

export default Valet
