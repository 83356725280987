import React from 'react';

var allResetFunctions = [ ]

export const resetAllCameraConnections = () => {
  allResetFunctions.forEach(resetCamera => { resetCamera() })
}

function addCameraResetFunction (resetFunction) {
  allResetFunctions.push(resetFunction)
}

function removeCameraResetFunction (resetFunction) {
  let index = allResetFunctions.indexOf(resetFunction)
  allResetFunctions.splice(index)
}

class Camera extends React.Component{
  state = {
    source: '',
    rawSource: ''
  }

  componentDidMount = () => {
    addCameraResetFunction(this.resetConnection)
    try {
      let data = JSON.parse(this.props.page.data.replace(/'/g,'"').replace(/\(/g,'[').replace(/\)/g,']').replace(/\bNone\b/, 'null'))
      let source = data.urls[window.navigator.appName.toLowerCase()] || data.urls.default

      this.setState({
        source:  source[0] + '?timestamp=' + Math.floor(Date.now() / 1000),
        rawSource: source[0]
      })
      if (source[1]) {
        this.timmer =  setInterval(this.resetConnection, source[1] )
      }

    }
    catch(error) {
      console.log(error);
    }
  }

  resetConnection = () => {
    this.setState((state) => {
      let raw = state.rawSource
      return {soruce: raw + '?timestamp=' + Math.floor(Date.now() / 1000) }
    })
  }

  componentWillUnmount() {
    removeCameraResetFunction(this.resetConnection)
    if (this.timmer) {
      clearInterval(this.timmer) 
    }
  }

  render(){
    return (
      <div className='camera'>
        <img src={this.state.source} id="camera_steam" alt="camera_steam"/>
      </div>
    )
  }
}

export default Camera