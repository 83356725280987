import React, { Component } from 'react';

import { Icon } from 'antd';
import { Link } from 'react-router-dom';

import { dateHelpers } from './helpers';


import CalendarCell from './CalendarCell';
import * as moment from 'moment';

// Constants
const HOUR_SEPARATOR = ':';

class NavigationBar extends Component {
  render() {
    return (
      <div className="navigation-bar">
        <div>
          <Link to={'../'+dateHelpers.serializeDay(this.props.date.clone().add(-1, 'day'))+'/'} replace>
            <Icon type="left" />
          </Link>
        </div>
        <div>
          <span className="current-date">
          {
            this.props.date && this.props.date._isAMomentObject
            ? dateHelpers.humanReadableDate(this.props.date)
            : 'Invalid date'
          }
          </span>
        </div>
        <div>
          <Link to={'../'+dateHelpers.serializeDay(this.props.date.clone().add(1, 'day'))+'/'} replace>
            <Icon type="right" />
          </Link>
        </div>
      </div>
    );
  }
}

class ByHourCalendar extends Component {
  constructor(props) {
    super(props);

    console.log('[ByHourCalendar:constructor()] Creating by-hour calendar');

    this.setDate = this.setSelectedDate.bind(this);

    this.state = {
      'loadingTurns': [],
    };
  }

  setSelectedDate(date) {
    // Tell DatePicker that day has been selected
    var rData = {
      'date': date
    };
    console.log('[ByHourCalendar:setSelectedDate()] Seting date with following data: '+JSON.stringify(rData));
    this.props.AddRequestData(rData);
  }

  getByHourData() {
    let [o_h, o_m, o_s] = this.props.openTime.split(HOUR_SEPARATOR);
    var openTime = dateHelpers.parseDate(this.props.routerProps.match.params.startDate);
    openTime.set({
      hours: o_h,
      minutes: o_m,
      seconds: o_s
    });

    let [c_h, c_m, c_s] = this.props.closeTime.split(HOUR_SEPARATOR);
    var closeTime = dateHelpers.parseDate(this.props.routerProps.match.params.startDate);
    if (this.props.groupData.closes_next_day) {
      closeTime = closeTime.add(1, 'day');
    }
    closeTime.set({
      hours: c_h,
      minutes: c_m,
      seconds: c_s
    });

    return dateHelpers.getByHourCalendarRepresentation(
      openTime,
      closeTime,
      this.props.period,
      this.props.groupData.closes_next_day
    );
  }

  refreshLoadingTurns(prevProps) {
    if (prevProps.myReservations !== this.props.myReservations) {
      this.setState({
        loadingTurns: this.getLoadingTurns(),
      });
    }
  }

  getLoadingTurns() {
    let loadingTurns = {};
    let i = 0;
    do {
      let iteratedReservation = this.props.myReservations[i];
      if (iteratedReservation) {
        let iteratedDate = moment(iteratedReservation.start_date);
        let isLoading = iteratedReservation.state !== 'reserved';
        if (isLoading) {
          let turnIndex = dateHelpers.serializeDate(iteratedDate);
          loadingTurns[turnIndex] = true;
        }
        i++;
      }
    } while (i < this.props.myReservations.length);
    return loadingTurns;
  }

  isTurnLoading(date) {
    let dateIndex = dateHelpers.serializeDate(date);
    return this.state.loadingTurns[dateIndex];
  }

  isBlockedByConsecutive(date) {
    const consecutiveAllowed = (this.props.groupData.allow_consecutive === 1);
    var isBlocked = false;

    if (!consecutiveAllowed) {
      function areSticked(date1, date2, period) {
        var difference = Math.abs(date1.diff(date2, 'minutes'));
        return (difference === period);
      }

      let myReservations = this.props.myReservations;
      let i = 0;
      let reservation;
      let reservationDate;
      do {
        reservation = myReservations[i];
        if (reservation) {
          reservationDate = moment(reservation.start_date);
          if (
            reservation.group_id === this.props.groupData.id
            && areSticked(date, reservationDate, this.props.groupData.period)
          ) {
            isBlocked = true;
          }
        }
        i++;
      } while (i < myReservations.length && !isBlocked);
    }

    return isBlocked;
  }

  syncDateWithParent() {
    const unparsedDate = this.props.routerProps.match.params.startDate;
    const date = dateHelpers.parseDate(unparsedDate);
    
    if (!dateHelpers.isSameDate(this.props.date, date)) {
      this.setSelectedDate(date);
    }
  }

  componentWillUnmount() {
    this.props.resetDateAndHour();
  }

  componentDidUpdate(prevProps) {
    this.syncDateWithParent();
    this.refreshLoadingTurns(prevProps);
  }


  render() {
    this.showedNextDay = false;

    return (
      <div className="calendar"
           hidden={this.props.hidden}
      >
        <div className="cell-wrapper">
          <NavigationBar date={dateHelpers.parseDate(this.props.routerProps.match.params.startDate)} />
          {
            this.getByHourData().map(function(turnData, turnIndex) {
              return (
                  <div key={'week-'+turnIndex}>
                    <div>
                      <CalendarCell
                        isByHour
                        groupData={this.props.groupData}
                        isBlockedByConsecutive={this.isBlockedByConsecutive.bind(this)(turnData)}
                        isBlockedDOW={dateHelpers.isBlockedDOW(this.props.groupData, dateHelpers.parseDate(this.props.routerProps.match.params.startDate).day())}
                        date={turnData}
                        reservations={this.props.reservations}
                        unitID={this.props.unitID}
                        closures={this.props.closures}
                        AddRequestData={this.props.AddRequestData}
                        availability={this.props.availability}
                        isAllDayReservable={this.props.isAllDayReservable}
                        isItemSelectorRequired={this.props.isItemSelectorRequired}
                        isTurnLoading={this.isTurnLoading(turnData)}
                      />
                    </div>
                  </div>
              );
            }.bind(this))
          }
          <NavigationBar date={dateHelpers.parseDate(this.props.routerProps.match.params.startDate)} />
        </div>
      </div>
    ); 
  }
}

export default ByHourCalendar