import React from 'react';
import SmartLink from './SmartLink';
import msg from '../containers/Pages/Panic/messages';
import { injectIntl} from "react-intl";

class PanicSquare extends React.Component {

  
  render() {
    const t = this.props.intl.formatMessage

    let className = `square ${this.props.className || this.props.name || ''}`

    if (this.props.activeType === this.props.type){
      className = `${className} active`
    }
    

    return (
      <SmartLink  to="activate-alarm" level={this.props.level + 1} onClick={() => this.props.onClick(this.props.type)}>
        <div className={className} >
          {this.props.activeType === this.props.type 
            ? <div className="ribbon"><span>{t(msg.active)}</span></div> : null}
          <p className="title">{t(msg[this.props.title])}</p>
        </div>
        
      </SmartLink>
    )
  }
}

export default injectIntl(PanicSquare)
