import React from 'react';
import { Custom, Automation, Camera, Deliveries, 
  Messages, Reservables, Valet, Weather, Panic, Invitations, Polls, Expenses, FoodOrder } from '../Pages'

import Header from './Header.js'

import PageFrame from '../../components/PageFrame.js'
import Description from './Description';
import msg from './messages';
import { injectIntl } from "react-intl";


class PageView extends React.Component {

  state = {
    title: '...',
    pageTextShow: true,
  }

  componentWillMount = () => {
    console.log("Mounting pageview")
  }

  setTitle = (newTitle) => {
    this.setState({ title: newTitle })
  }

  componentDidMount = () => {
    console.log("mounting pageview")
    const onLoadFunction = this.props.onLoad
    if (typeof onLoadFunction === 'function') {
      onLoadFunction()
    }
  }

  componentDidUpdate = () => {
    console.log("PageView: componentDidUpdate")
  }

  showPageText = () => {
    this.setState({pageTextShow: true})
  }
  hidePageText= () => {
    this.setState({pageTextShow: false})
  }
  render() {
    let pageType = this.props.page && this.props.page.type || this.props.pageType
    let pageName = this.props.page && this.props.page.name || this.props.pageName
    let pageTitle = this.props.page && this.props.page.title || this.state.title
    let pageText = this.props.page && this.props.page.text || false
    let page
    const t = this.props.intl.formatMessage

    let idReq = this.props.match && this.props.match.params && this.props.match.params.id_req || "";

    const unit = this.props.unit
    if (unit != 6666 && pageType === 'restaurant') {
      pageType = 'custom'
    }

    switch (pageType) {

      case 'weather':
        page = <Weather
          unit={this.props.unit}
          page={pageName}
          weather={this.props.data.weather}
          lastWeatherUpdate={this.props.data.lastWeatherUpdate}
          reqData={this.props.reqData}
        />
        break;

      case 'automation':
        page = <Automation
          unit={this.props.unit}
          reqData={this.props.reqData}
          hidePageText={this.hidePageText}
          showPageText={this.showPageText}
        />
        break;

      case 'camera':
        page = <Camera
          page={this.props.page}
          reqData={this.props.reqData}
        />
        break;

      case 'deliveries':
        page = <Deliveries
          name={this.props.page.name}
          deliveries={this.props.data.deliveries}
          newMqttMessage={this.props.data.newMqttMessage}
          pageMessageReceivedCallback={this.props.methods.pageMessageReceivedCallback}
          mqttTopicMessage={this.props.data.mqttTopicMessage}
          reqData={this.props.reqData}
        />
        break;

      case 'messages':
        page = <Messages
          name={pageName}
          loading={this.props.loading}
          messages={this.props.data.messages}
          toggleMessage={this.props.methods.toggleMessage}
          reqData={this.props.reqData}
          unit={this.props.unit}
        />
        break;

      case 'reservables':
        page = <Reservables
          level={this.props.level}
          name={pageName}
          unit={this.props.unit}
          reservations={this.props.data.reservations}
          reservables={this.props.data.reservables}
          addReservation={this.props.methods.addReservation}
          removeReservation={this.props.methods.removeReservation}
          updateReservation={this.props.methods.updateReservation}
          reqData={this.props.reqData}
        />
        break;

      case 'emergency':
        page=  <Panic
          name={pageName}
          data={this.props.data}
          page={this.props.page}
          unit={this.props.unit}
          level={this.props.level}
          reqData={this.props.reqData}
          methods={this.props.methods}
          hidePageText={this.hidePageText}        
        />
      break;

      case 'valet':
        page = <Valet
          name={pageName}
          vehicles={this.props.data.vehicles}
          hasNoCars={this.props.data.hasNoCars}
          car_wash={this.props.data.carWash}
          unit={this.props.unit}
          changeVehicleState={this.props.methods.changeVehicleState}
          reqData={this.props.reqData}
        />
        break;

      case 'invitations': 
        page = <Invitations
          level={this.props.level}
          name={pageName} 
          page={this.props.page} 
          subpages={this.props.subPages}
          unit={this.props.unit}
          building={this.props.building}
          reqData={this.props.reqData}
          data={this.props.data}
          methods={this.props.methods}
          hidePageText={this.hidePageText}        
        />
      break;

      case 'expenses': 
        page = <Expenses
          level={this.props.level}
          name={pageName} 
          page={this.props.page} 
          subpages={this.props.subPages}
          unit={this.props.unit}
          building={this.props.building}
          newMqttMessage={this.props.data.newMqttMessage}
          pageMessageReceivedCallback={this.props.methods.pageMessageReceivedCallback}
          mqttTopicMessage={this.props.data.mqttTopicMessage}
          reqData={this.props.reqData}
          data={this.props.data}
          methods={this.props.methods}       
        />
      break;

      case 'polls': 
        page = <Polls
          level={this.props.level}
          name={pageName} 
          page={this.props.page} 
          unit={this.props.unit}
          building={this.props.building}
          reqData={this.props.reqData}
          data={this.props.data}
          methods={this.props.methods} 
          polls={this.props.data.polls}     
        />
      break;
      
      case 'restaurant':
        let restaurant_title = pageTitle
        pageTitle = ''
        page = <FoodOrder
          level={this.props.level}
          name={pageName} 
          restaurant_title={restaurant_title}
          page={this.props.page} 
          unit={this.props.unit}
          building={this.props.building}
          reqData={this.props.reqData}
          data={this.props.data}
          methods={this.props.methods} 
          hidePageText={this.hidePageText}        

        />
      break;

      default:
        if (this.props.isGuest) {
          page = <Custom
            isGuest
            loading={this.props.loading}
            building={this.props.building}
            pageName={this.props.pageName}
            parentPath={this.props.parentPath}
            level={this.props.level}
            data={this.props.data}
            pageview={{setTitle: this.setTitle}}
            url={this.props.url}
          />
        } else {
          page = <Custom
            loading={this.props.loading}
            level={this.props.level}
            name={pageName}
            key={this.props.page.id}
            page={this.props.page}
            subPages={this.props.subPages}
            unit={this.props.unit}
            reqData={this.props.reqData}
            parentPath={this.props.parentPath}
            data={this.props.data}
            methods={this.props.methods}
            initialData={this.props.initialData}
            url={this.props.url}
            idReq={idReq}
          />
        }

    }
    console.log(`[PageView.render()] Rendering page ${pageName} of type ${pageType}`)
    return (
      <PageFrame className={pageName}>
        <Header isGuest={this.props.isGuest} 
        pageInfo={{"title": t(msg.pageInformation), "content": !this.state.pageTextShow && pageText || null}} 
        >{ pageTitle }
        </Header>
        { pageText  && this.state.pageTextShow ? <Description text={pageText} /> : null }
        { page }
      </PageFrame>
    )
  }
}

export default injectIntl(PageView)
