import React from 'react';
import { Icon, Modal } from 'antd';
import MarkdownRenderer from '../MDRenderer'

class InfoButton extends React.Component {
  showInfo = () => {
    Modal.info(this.getModalSettings())
  }

  getModalSettings = () => {
    return {
      title: this.props.title,
      //content: this.props.content,
      content: <MarkdownRenderer className="body" markdown={this.props.content}/>,
      // footer: null,
      maskClosable: true,
      
    };
  }

  render = () => (
    <div className="info-button" onClick={this.showInfo}>
      <Icon className="icon" type="info-circle" />
    </div>
  )
}

export default InfoButton;
