import React from 'react';

const mountedScrollableStack = [ ]

function PUSH() {
  const setEnabled = this.setEnabled;
  const item = { setEnabled }
  mountedScrollableStack.push(item);
  refreshEnabledStatus();
}

function POP() {
  const removed = mountedScrollableStack.pop();
  refreshEnabledStatus();
  return removed;
}

function refreshEnabledStatus() {
  mountedScrollableStack.forEach((item, index) => {
    const isLast = index === (mountedScrollableStack.length - 1);
    let enabled = false;
    if (isLast) {
      enabled = true;
    }
    item.setEnabled(enabled);
  });
}

export default class Scrollable extends React.PureComponent {
  state = {
    enabled: false
  }

  componentWillMount = () => {
    const registerScrollable = PUSH.bind(this);
    registerScrollable();
  }

  componentWillUnmount = () => {
    this.setEnabled(false);
    const unregisterScrollable = POP.bind(this);
    unregisterScrollable();
  }

  setEnabled = (enabled) => {
    this.setState({ enabled });
  }

  getClassName = () => {
    if (this.state.enabled) {
      return 'enabled-scrollable';
    }
    return 'disabled-scrollable';
  }

  render = () => {
    return (
      <div className={this.getClassName()}>{ this.props.children }</div>
    );
  }
}
