import React from 'react';
import msg from './messages'
import MarkdownRenderer from '../../MDRenderer';
import { Button } from 'antd';

import InteractionRequest from './InteractionRequest';
import { withRouter } from 'react-router-dom';
import { ClockCircleTwoTone, ScheduleTwoTone } from '@ant-design/icons';


class Request extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        commenting: false,
        showEvents: false,
        events: {}
      };
      this.commentText = React.createRef();
    }


  componentDidMount ()  {
  }

  parseDate = (date) => {
    if (date) {
        const [datePart, timePart] = date.split("T");
        return { datePart, timePart };
    }
  }

  showStateEvent = (req, datePart, timePart, t) => {

    return (
      <div className='descriptions-req'>
        <p style={{ fontSize: 14}}>
          {`${t(msg.requestState)} "${t(msg[req.state])}".`}
        </p>
        <p style={{ fontSize: 14}}>
          {`${t(msg.create)} ${datePart}, ${timePart}.`}
        </p>
      </div>
    )
  }

  showIconState = (req) => {
    let icon = null;

    if (req.state === "pending") {
      icon = <ClockCircleTwoTone twoToneColor="#1890ff" style={{fontSize: 20}}/>; //blue
    } else if (req.state === "processing") {
      icon = <ScheduleTwoTone twoToneColor="#52c41a" style={{fontSize: 20}}/>; //green
    }

    return (
      <>
        {icon}
      </>
    )
  }


  render(){
    const { req, t } = this.props;

    const { datePart, timePart } = this.parseDate(req.date);
    
    return (
        <li key={req.id} className='li-request'>
          <div className='list-item-request'>
            <div className='header-item'>
              <div className='header-description'>
                <div className='header-title'>
                  <p className='title'>{ req.title }</p>
                  {this.showIconState(req)}
                </div>
                <div className='container-state'>
                  {this.showStateEvent(req, datePart, timePart, t)}
                </div>
              </div>
              <div className='container-actions'>
                {req.state === 'pending' && 
                  <Button 
                    type="primary" 
                    size="small"
                    onClick={() => this.props.cancelRequest(req.id)}
                  > 
                    {t(msg.cancelRequest)} 
                  </Button>
                }
              </div>
            </div>

            <div className='content-item'>

              <div className='details-item'>
                <div className='content-details'>
                  <MarkdownRenderer className="body" markdown={req.text}/>
                </div>
              </div>

              <InteractionRequest 
                events={this.state.events} 
                req={req}
                showEvents={this.showEvents}
                t={t}
                user_id={this.props.info.user_id}
                newMqttMessage={this.props.newMqttMessage}
              />
              
            </div>
          </div>
        </li>
     )
   }
}

export default withRouter(Request)
