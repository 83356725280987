import React from 'react';
import MarkdownRenderer from '../../MDRenderer';
import { withRouter } from 'react-router'
import { Form, Switch, Button, message } from 'antd';
import { injectIntl } from "react-intl";
import msg from './messages'
import CustomFormParser from './parser';
import { SmartRequests } from '../../../utilities/index.js';
import { CustomField } from './CustomField';

const FormItem = Form.Item


/* FIXME: form layout (columns) is controlled by antd. When the label is large enough it 
 * overflows the sizes specified below, so the input element covers part of the label.
 */
const formItemLayout = {
  // labelCol: {
  //   xs: { span: 24 },
  //   sm: { span: 5 },
  // },
  // wrapperCol: {
  //   xs: { span: 15 },
  //   sm: { span: 16 },
  // },
}

class CustomForm extends React.Component{
  constructor(props) {
    super(props); 
    this.state = {
      formData: [ ],
    }
  }

  componentWillMount = () => {
    console.log('mounting customform')
    this.t = this.props.intl.formatMessage

    const parser = new CustomFormParser(this.props.formRaw);
    let form = parser.parseAll();
    const initialData = this.props.initialData;

    form = this.updateForm(form, initialData);


    this.setState({
      formData: form,
    });

  }

  requiredInputRules(){
    return [
      {
        required: true, 
        message: this.t(msg.fieldRequired),
      },
    ]
  }


  updateForm(form, initialData){
    if (initialData) {
      for (const key in initialData) {
        if (initialData.hasOwnProperty(key)) {
          const index = parseInt(key, 10);
          const cleanedValue = initialData[key].replace(/\*\*/g, '');
          if (form[index] && form[index][1]) {
            form[index][1].value = cleanedValue;
          }
        }
      }
    }
    return form
  }
  

  createInput = (input, index) => {
    const { getFieldDecorator } = this.props.form;
    const REQUIRED_MESSAGE = ' _(required)_';
    let info = input[1]
    let name = input[0] + (info.required ? REQUIRED_MESSAGE : '')
    let field;

    if (info.type === 'markdown') {
      return <MarkdownRenderer markdown={name} />;
    }

    if (info.type === 'dropdown') {
      field = (
        <CustomField type="select"  onChange={this.changeHandler.bind(this, index, info.type)} options={info.options} />
      ) 
    } else if (info.type === 'textarea') {
      field = (
        <CustomField type={info.type} rows={3} id={'input_' + index} name={name}onChange={this.changeHandler.bind(this, index, info.type)}/>
      )
    } else if (info.type === 'textbox') {
      field = (
        <CustomField id={'input_' + index} name={name} onChange={this.changeHandler.bind(this, index, info.type)}/>
      )
    } else if (info.type === 'checkbox') {
      field = (
        <Switch id={'input_' + index} name={name} defaultChecked={false}   onChange={this.changeHandler.bind(this, index, info.type)} />
      )
    } else if (info.type === 'date') {
      field = (
        <CustomField id={'input_' + index} name={name} onChange={this.changeHandler.bind(this, index, info.type)} type={info.type} />
      );
    } else if (info.type === 'time') {
      field = (
        <CustomField 
          type={info.type}
          id={'input_' + index}
          name={name}
          format="HH:mm"
          onChange={this.changeHandler.bind(this, index, info.type)}
        />
      )
    }

    if (field) {
      return (
        <FormItem label={ <MarkdownRenderer className="form-label" markdown={name}/>} {...formItemLayout} key={"CustomFormItem-" + index} >
          {info.required ? getFieldDecorator(name, {rules: this.requiredInputRules(), initialValue:info.value})(field) : getFieldDecorator(name, {initialValue:info.value})(field)}
        </FormItem>
      );
    }

    return ''
  }

  submitHandler = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.submitData();
      } else {
        console.log(`Error while submitting form: ${err}`);
      }
    })
  }

  submitData = () => {
    
    this.props.block(true)
    
    let data = {}
    this.state.formData.forEach(el => {
      const name = el[0]
      const options = el[1]
      if (options.type !== 'markdown') {
        data[name] = options.value;
      }
    })
    
    SmartRequests.post(this.props.url , data ).then( (response) => {
      if (this.props.url === 'emergency_settings') {
        this.props.methods.updateContact()
      } else if ( "updateRequests" in this.props.methods) {
        this.props.methods.updateRequests(response.data);
      } 
      this.props.history.goBack();
      this.props.block(false);
      message.success(this.t(msg.SuccessRequest));
    }).catch(error => {
      message.error(this.t(msg.ErrorRequest));
      this.props.block(false);
    })

  }

  changeHandler = (i, type, value) => {
    let index = i
    this.setState( old => {
      let newData = old.formData.slice()
      newData[index][1].value = value
      return {formData: newData}
    })
  }
  

  render(){
    const t = this.t

    return (
      <div className="frame">
        <Form id="CustomForm" className="custom-form frame" onSubmit={this.submitHandler}>
          {this.state.formData.map( (input, index) => this.createInput(input, index) )}
          <div className="submit-button-wrapper"  >
            <Button className="submit-button" type="primary" htmlType="submit" > {t(msg.submit)} </Button>
          </div>
        </Form>
      </div>
    ) 
      
  }
}

// const HOCWrapper = WrappedComponent => {
//   return class extends React.Component {
//     render() {
//       const Wrapped1 = intlProps => { 
//         console.log(`INTL PROPS: ${JSON.stringify(intlProps)}`)
//         const Wrapped2 = formProps => {
//           console.log(`FORM PROPS: ${JSON.stringify(formProps)}`);
//           return <WrappedComponent {...intlProps} {...formProps} {...this.props} />
//         }
//         const Wrapper2 = Form.create()(Wrapped2)
//         return <Wrapper2 />
//       }
//       const Wrapper1 = injectIntl(Wrapped1);
//       return <Wrapper1 />;
//     }
//   }
// }

//export default CustomForm = withRouter(HOCWrapper(CustomForm))
export default withRouter(injectIntl(Form.create()(CustomForm)));