import * as utils from '../../../utilities/utils';
import * as customStorage from '../../../utilities/customStorage';

const ALREADY_RECOVERED_PREFERENCES = 'ALREADY_RECOVERED_PREFERENCES';

class CordovaInit {

  data = null;
  waiter = () => console.log('[CordovaInit.waiter()] Dummy waiter! :)');

  setData = async (data) => {
    this.data = data;
    await this.notifyData();
  }

  notifyData = async () => {
    const isFirstTime = await isFirstTimeCheckingPreferences();
    console.log(`[CordovaInit.notifyData()] Recieved data${isFirstTime ? '' : ' (ignoring)'}: ${JSON.stringify(this.data)}`);
    if (isFirstTime) {
      await this.waiter();
    }
  }

  waitForPreferences = () => new Promise((res, rej) => {
    const resolve = res.bind(this)
    const reject = rej.bind(this)
    isFirstTimeCheckingPreferences().then((isFirstTime) => {
      const isNotFirstTime = !isFirstTime 
      if (!(utils.getPlatform() === 'ios')) {
        reject('Preferences are read just on iOS devices')
      } if (isNotFirstTime) {
        reject('Preferences are read just once')
      } else if (this.data) {
        resolve(this.data)
      }
      const TIMEOUT = 500; // 500ms
      const promiseTimeout = setTimeout(() => {
        reject('Promise timed out')
      }, TIMEOUT);
      this.waiter = async () => {
        clearTimeout(promiseTimeout);
        resolve(this.data)
      }
    })
  })
}

export const finishPreferencesRecovery = async () => {
  await setAlreadyCheckedFlag(true)
}

export const abortPreferencesRecovery = async () => {
  await setAlreadyCheckedFlag(false)
}

const setAlreadyCheckedFlag = async (value) => {
  await customStorage.setItem(ALREADY_RECOVERED_PREFERENCES, !!value);
}

const isFirstTimeCheckingPreferences = async () => {
  return !await customStorage.getItem(ALREADY_RECOVERED_PREFERENCES);
}


export default new CordovaInit();
