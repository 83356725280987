import React from 'react';
import { Link } from 'react-router-dom';
import msg from '../containers/Pages/Panic/messages';
import { injectIntl } from "react-intl";
import { withRouter } from "react-router"
import { Button } from 'antd';


class HelpRequested extends React.Component  {
    constructor(props) {
      super(props)
      this.state = {
      }
  }

  getPathname = () =>{
    const { location } = this.props;
    const pathname = location.pathname;
    const segments = pathname.split('/').filter(segment => segment !== '');
    const ultimoSegmento = segments.length > 0 ? segments[segments.length - 1] : '';
    return ultimoSegmento;
  }

  render() {
    
    const t = this.props.intl.formatMessage;

    return (
      <div className='container-help-requested'>
        <div className="title-help-requested">
          <h1 >{t(msg.HelpRequested)}</h1>
        </div>
        <div>
          {this.getPathname() !== "activate-alarm" && <Link to={`/${this.props.building.name}/${this.props.unit}/dashboard/emergency/activate-alarm`}><Button type='primary'>{t(msg.details)}</Button></Link>}
          </div>
      </div>  
    );
  }
}

export default withRouter(injectIntl(HelpRequested)) ;