import { injectIntl, defineMessages } from "react-intl";

const msg = defineMessages({
  
  email: {
    id: 'accessOptions.email',
    defaultMessage: 'E-mail'
  },

  activateAccount:{
    id: 'accessOptions.activateAccount',
    defaultMessage: 'Activate Account'
  },

  activation:{
    id: 'accessOptions.activation',
    defaultMessage: 'Activation'
  },

  login:{
    id: 'accessOptions.login',
    defaultMessage: 'Log In '
  },

  resetPassword:{
    id: 'accessOptions.resetPassword',
    defaultMessage: 'Reset Password'
  },

  reset:{
    id: 'accessOptions.reset',
    defaultMessage: 'Reset'
  },

  ErrorIncorrectEmail:{
    id: 'accessOptions.ErrorIncorrectEmail',
    defaultMessage: 'The email is not correct.'
  },

  SuccessReseted:{
    id: 'accessOptions.SuccessReseted',
    defaultMessage: 'We have sent an email with instructions to reset your password.'
  },

  SuccessActivated: {
    id:'accessOptions.SuccessActivated',
    defaultMessage: 'The device is activated.'
  },

  ErrorActivated: {
    id:'accessOptions.ErrorActivated',
    defaultMessage: 'An error has occurred when activating device'
  },

  activate: {
    id:'accessOptions.activate',
    defaultMessage: 'Activate'
  },

  

})

export default msg

