import React from 'react';
import msg from './messages';



class MessageChat extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    
      this.classMsgSelf = this.props.user_id === this.props.event.user_id ? "self" : "other"

    }

    renderEventMessage = (event, index, msgClass, text) => {
        return (
            <div className={msgClass} key={event.id} id={`${event.id}-${index}`}>
                <div className='message'>
                    <p>{ text }</p>
                </div>
            </div>
        )
    }

    parseDate = (date) => {
        if (date) {
            const [datePart, timePart] = date.split("T");
            return { datePart, timePart };
        }
    }

    renderMessage = (event, index, t) => {

        let text = "";

        if (event.event === "escalate") {
            return <></>
        } else if (event.event === "create") {
            text = `${t(msg.createMessage)}`;
            let msgClass = "item-create";
            return this.renderEventMessage(event, index, msgClass, text);
        } else if (event.event === "assign") {
            text = `${t(msg.assignMessage)} ${event.escalation}`;
            let msgClass = "item-assign";
            return this.renderEventMessage(event, index, msgClass, text);
        } else if (event.event === "ack") {
            text = `${event.user_name} ${t(msg.ackMessage)}`;
            let msgClass = "item-ack";
            return this.renderEventMessage(event, index, msgClass, text);
        } else if (event.event === "cancel") {
            text = `${t(msg.cancelMessage)}`;
            let msgClass = "item-cancel";
            return this.renderEventMessage(event, index, msgClass, text);
        } else if (event.event === "close") {
            text = `${t(msg.closeMessage)}${event.text}`;
            let msgClass = "item-close";
            return this.renderEventMessage(event, index, msgClass, text);
        } else if (event.event === "reopen") {
            text =`${t(msg.reopenMessage)}`;
            let msgClass = "item-reopen";
            return this.renderEventMessage(event, index, msgClass, text);
        }

        const { datePart, timePart } = this.parseDate(event.date);

        return (
            <div className={`item-container ${this.classMsgSelf}`}>
                <div className={`item ${this.classMsgSelf}`} key={event.id} id={`${event.id}-${index}`}>
                    <div className='title-message'>
                        <h3>{event.user_name}</h3>
                    </div>

                    <div className='message'>
                        <p>{ event.text }</p>
                    </div>

                    <div className='date-message'>
                        <p>{`${timePart} ${datePart}`}</p>
                    </div>
                </div>
            </div>
        )
    }

    render(){
        
        const { event, index, t } = this.props;

        return this.renderMessage(event, index, t);
    }
}

export default MessageChat
