import React from 'react';
import UnitChooser from '../../components/UnitChooser';
import { Modal, Button } from 'antd';

class ChooseAnotherUnitOrLogoutModal extends React.Component {
  render = () => (
      <Modal
        className="choose-another-unit-or-logout-modal-component"
        visible={this.props.visible}
        closable={false}
        maskClosable={false}
        title="Something went wrong! :0"
        footer={[
          <Button type="danger" onClick={this.props.logout}> Logout </Button>
        ]}
        >
        <p> Please choose another unit: </p>
        {UnitChooser(this.props.units, this.props.setUnit)}
      </Modal>
  )
}

export default ChooseAnotherUnitOrLogoutModal;