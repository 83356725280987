import React from 'react';
import { Button, message } from 'antd'


class SignalButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = { active:false }
  }

  onClick() {
    this.setState({active:true})
    this.props.sendSignal(this.props.signal).then( resp => {
      if (resp.status === 200) { console.log('[automation:Zone.sendSignal()] Signal successfully sent')}
      this.setState({active:false})
    }).catch(err => { 
      console.log( '[automation:Zone.sendSignal()]! Could not send signal: ' + err )
      this.setState({active:false})
      message.error('There was a problem with the request ')
    })
  }

  render() {
    return (
      <Button onClick={this.onClick.bind(this)} className={'widget ' + (this.state.active? 'active' : '')}>
        {this.props.displayName}
      </Button>
    );
  }
}

export default SignalButton