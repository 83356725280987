import { defineMessages } from "react-intl";

const msg = defineMessages({
    Now: {
        id: "weather.Now",
        defaultMessage: "{day} {restOfText}",
    },

    mon: {
        id: "weather.mon",
        defaultMessage: "{day} {restOfText}",
    },

    tue: {
        id: "weather.tue",
        defaultMessage: "{day} {restOfText}",
    },

    wed: {
        id: "weather.wed",
        defaultMessage: "{day} {restOfText}",
    },

    thu: {
        id: "weather.thu",
        defaultMessage: "{day} {restOfText}",
    },

    fri: {
        id: "weather.fri",
        defaultMessage: "{day} {restOfText}",
    },

    sat: {
        id: "weather.sat",
        defaultMessage: "{day} {restOfText}",
    },

    sun: {
        id: "weather.sun",
        defaultMessage: "{day} {restOfText}",
    },

    lastUpdate: {
        id: "weather.lastUpdate",
        defaultMessage: "Last Update: ",
    },
});

export default msg;
