import React from 'react';
import { Icon, Spin, Table } from 'antd';
import { injectIntl } from "react-intl";
import { Route, withRouter } from 'react-router-dom';
import PageFrame from '../../../components/PageFrame';
import Header from '../../PageView/Header';
import { SmartRequests } from '../../../utilities';
import msg from './messages';
import Details from './Details';
import TransitionSwitch from '../../../components/TransitionSwitch';
import { FilePdfOutlined } from '@ant-design/icons';

class Transactions extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      link: "",
      movements: [],
      columns: [
        {
          title: '',
          dataIndex: 'transactionType',
          key: 'transactionType',
        },
        {
          title: this.props.intl.formatMessage(msg.description),
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: this.props.intl.formatMessage(msg.date),
          dataIndex: 'date',
          key: 'date',
        },
        {
          title: this.props.intl.formatMessage(msg.amount),
          dataIndex: 'amount',
          key: 'amount',
        },
        {
          title: this.props.intl.formatMessage(msg.details),
          dataIndex: 'details',
          key: 'details',
        },
      ],
      data: [],
      loading: true,
    }
    this.msgRole = "";
  }

  componentDidMount() {
    console.log('Mounting Transactions');
    this.t = this.props.intl.formatMessage;
    this.getPaymentsMovements();
  }

  componentDidUpdate(prevProps, prevState) {

    const prevRole = prevProps.match.params.role;
    const currentRole = this.props.match.params.role;

    if (this.props.userRole && (this.props.userRole === "inquilino" && currentRole === "propietario") || (currentRole !== "propietario" && currentRole !== "inquilino")) {
      this.props.history.goBack();
    } else if (this.props.userRole &&this.props.userRole === "propietario" && currentRole !== prevRole && (prevRole === "propietario" || prevRole === "inquilino")) {
      this.getPaymentsMovements();
    }
  }

  getPaymentsMovements = () => {
    this.selectedRole = this.props.match.params.role;

    let accountingRoleUrl = this.selectedRole ? `?selected_accounting_role=${this.selectedRole}` : "";

    SmartRequests.get(`accounting/movements${accountingRoleUrl}`).then(resp => {
      if (resp.status === 200) {
        const transformedData = this.transformedData(resp.data.movements, resp.data.currency_symbol);
        this.setState({ movements: resp.data.movements, data: transformedData, loading: false });
      }
    }).catch(err => {
      console.log("Error: " + err);
    });
  }

  viewDetailsPage = (link) => {
    this.setState({link: link});
    this.props.history.push({
        pathname: `/${this.props.reqData.building.name}/${this.props.unit}/dashboard/expenses/transactions/details`,
    });
  }

  transformedData = (movements, currency_symbol) => {
    return movements.map((movement, index) => ({
      key: index.toString(),
      transactionType: movement.amount_credit > 0 ? <Icon type="arrow-up" style={{ color: 'green', fontSize: '20px' }}/> : <Icon type="arrow-down" style={{ color: 'red', fontSize: '20px' }}/>,
      description: movement.description.trim(),
      date: movement.date,
      amount: movement.amount_credit > 0 ? `${currency_symbol} ${movement.amount_credit}` : `${currency_symbol} ${movement.amount_debit}`,
      details: movement.link ? <FilePdfOutlined className='container-icon-pdf' onClick={() => this.viewDetailsPage(movement.link)}/> : this.t(msg.noDetails),
    }));
  }

  render() {
    const t = this.props.intl.formatMessage;

    const { loading, columns, data, link } = this.state;

    if (this.selectedRole && (this.selectedRole === "propietario" || this.selectedRole === "inquilino")) {
      this.msgRole = `/ ${t(msg[this.selectedRole])}`;
    }


    return (
        <PageFrame>
          <Header pageInfo={{"content": null}}>{`${t(msg.movements)} ${this.msgRole}`}</Header>
          <div className='container-transactions'>
            {loading ? <Spin size="large"/> : 
              <div>
                <Table
                  columns={columns}
                  dataSource={data}
                  rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
                  size="small"
                />
              </div>
            }
          </div>
          <TransitionSwitch level={this.props.level + 1}>
            <Route path={`/:building/:unit/dashboard/expenses/transactions/details`} render={() => <Details pageText={`[](${link})`} link={link}/>}/>
          </TransitionSwitch>
        </PageFrame>
    );
  }
}

export default injectIntl(withRouter(Transactions));
