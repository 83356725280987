import { defineMessages } from "react-intl";

const msg = defineMessages({
    Search: {
      id:'polls.Search',
      defaultMessage:"Search"
    },

    pollSuccessfully: {
      id:'polls.pollSuccessfully',
      defaultMessage:"The poll response was added successfully!"
    },

    save: {
      id:'polls.save',
      defaultMessage:"Save"
    },

    close: {
      id:'polls.close',
      defaultMessage:"Close"
    },

    selectAtLeastOneOption: {
      id:'polls.selectAtLeastOneOption',
      defaultMessage:"Select at least one option"
    },

    NoPollsFound: {
      id:'polls.NoPollsFound',
      defaultMessage:"No polls found"
    },

    ChooseSomeOptions: {
      id:'polls.ChooseSomeOptions',
      defaultMessage:"Choose one or more options"
    },

    ChooseSingleOption: {
      id:'polls.ChooseSingleOption',
      defaultMessage:"Choose a single option"
    },

    inCourse :{
      id:'polls.inCourse',
      defaultMessage:'In Course'
    },
    
    finished:{
      id:'polls.finished',
      defaultMessage:'Finished'
    },

    rangeInstruction:{
      id:'polls.rangeInstruction',
      defaultMessage:'Slide the slider or enter a number within the range'
    },

    pollResultsMessageIncludingYourResponse:{
      id:'polls.pollResultsMessageIncludingYourResponse',
      defaultMessage:'Poll results, including your response'
    },

    pollResultsMessage:{
      id:'polls.pollResultsMessage',
      defaultMessage:'Poll results'
    },

    Finishes:{
      id:'polls.Finishes',
      defaultMessage:'Finishes'
    },

    answerPoll:{
      id:'polls.answerPoll',
      defaultMessage:'Answer the poll'
    },

    viewResults:{
      id:'polls.viewResults',
      defaultMessage:'View results'
    },

    confirmationMessage:{
      id:'polls.confirmationMessage',
      defaultMessage:`Upon confirmation, you won't be able to edit your response. Are you sure you want to proceed?`
    },

    cancel:{
      id:'polls.cancel',
      defaultMessage:`Cancel`
    },

    confirm:{
      id:'polls.confirm',
      defaultMessage:`Confirm`
    },

    confirmPoll:{
      id:'polls.confirmPoll',
      defaultMessage:"Confirm Poll"
    },

    VotingOptions:{
      id:'polls.VotingOptions',
      defaultMessage:"Voting Options"
    },

    Votes:{
      id:'polls.Votes',
      defaultMessage:"Votes"
    },

    weightedValue:{
      id:'polls.weightedValue',
      defaultMessage:"Weighted value"
    },

    NoResponsesPoll:{
      id:'polls.NoResponsesPoll',
      defaultMessage:"No responses were received for the poll"
    },

    resultsVisibilityEnabled:{
      id:'polls.resultsVisibilityEnabled',
      defaultMessage:"Thank you for completing the poll! Currently, results visibility is not available. If you wish to view the results, please contact the staff"
    },

    

})


export default msg