import { Button, Result } from 'antd'
import React from 'react'
import Box from './Box'
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';


class MessageAlert extends React.Component{
    
    capitalizeFirstLetter = (text) => {
        if (!text) {
            return null;
        }
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    render(){
        const t = this.props.intl.formatMessage;
        const className = `message-alert ${this.props.className || ''}`
        return (
            <Box className={className}>
                <Result 
                    status={this.props.status || "error"} 
                    title={this.capitalizeFirstLetter(this.props.title) || "Error"}
                    subTitle={this.props.subTitle || ""}
                />
            </Box>
        )
    }
}

export default injectIntl(withRouter(MessageAlert))