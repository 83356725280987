import React, { Component } from 'react';
import VehicleLogoBar from './VehicleLogoBar'
import VehicleInfo from './VehicleInfo'

class Vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state:   props.state,
      focused: props.focused,
      isBlinking: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.state !== this.props.state)
      this.props.quitFocus(this.props.id);
  }

  clickHandler() {
    if (this.props.state !== 'loading') {
      this.props.onFocus(this.props.id);
    } 
    if (['out', 'washing', 'topark', 'loading'].includes(this.props.state)) {
      this.displayMessage.bind(this)();
    }
  }

  messageTimer = null
  displayMessage() {
    if (!this.messageTimer) {
      this.setState({
        'isDisplayingMessage': true,
      });
      this.messageTimer = setTimeout(
        function hideMessage() {
          this.setState({
            'isDisplayingMessage': false,
          });
          delete this.messageTimer;
        }.bind(this),
        4000
      );
    }
  }

  render() {
    return (
      <div className={'vehicle ' + this.props.state} onClick={this.clickHandler.bind(this)} >
        <VehicleLogoBar src={'/static/img/makes-80px/' + this.props.make_id + '.png'}></VehicleLogoBar>
          <VehicleInfo 
            id                  = { this.props.id }
            tag_number          = { this.props.tag_number }
            make                = { this.props.make }
            state               = { this.props.state }
            color               = { this.props.color }
            owner               = { this.props.owner }
            make_id             = { this.props.make_id }
            focused             = { this.state.focused }
            car_wash            = { this.props.car_wash }
            quitFocus           = { this.props.context.quitFocus }
            onCarRequest        = { () => this.props.context.requestCar(this.props.reqData, this.props.id, this.props.owner, this.props.changeVehicleState) }
            onCarWashRequest    = { () => this.props.context.requestCarWash(this.props.reqData, this.props.id, this.props.owner, this.props.changeVehicleState) }
            onRequestCancel     = { () => this.props.context.cancelRequest(this.props.reqData, this.props.id, this.props.owner, this.props.changeVehicleState) }
            changeVehicleState  = { this.props.changeVehicleState }
            isDisplayingMessage = { this.state.isDisplayingMessage }
            context             = { this.props.context }
              />
      </div>
    );
  }
}

export default Vehicle;
