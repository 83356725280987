import React from 'react';

const NotificationContent = ({ message, imageUrl }) => (

    <div>
        <div>
            <p>{message}</p>
        {imageUrl && <img src={imageUrl} alt="notification" style={{ width: '50px', height: '50px', marginRight: '10px' }} />}
        </div>
    </div>

);

export default NotificationContent;