import React from 'react';
import debounce from 'lodash.debounce';
import * as utils from '../utilities/utils.js'
import { Pagination } from 'antd';


export function reloadIfNeeded(url, setState) {

    if (
      (this.reload) ||
      (this.query != this.lastQuery) ||
      (this.category != this.lastCategory || (typeof this.lastCategory === 'undefined' && this.category===null)) ||
      (this.state.pageOffset != this.lastPageOffset)
    ) {
        this.lastQuery      = this.query
        this.lastCategory   = this.category
        this.lastPageOffset = this.state.pageOffset
        this.reload= false
        // construct the queryString
        let args = 'page_size=' + this.pageSize
        if (this.props.page && this.props.page.name) {//FOR CUSTOM ONLY
            args += '&tag=' + encodeURIComponent(this.props.page.name);
        }    
        if (this.query && this.query.length > 0) {
        args += '&q=' + encodeURIComponent(this.query);
        }

        if (this.state.pageOffset && this.state.pageOffset > 0) {
        args += '&offset=' + this.state.pageOffset;
        }

        if (this.category && this.category.length > 0) {
        args += '&state=' + encodeURIComponent(this.category);
        }
        
        setState({ searching: true });
        const URL = url + args;

        return utils
        .get(this.props.reqData, URL)
        .then(function (resp) {
            if (resp && resp.data) {
            setState({ searchResults: resp.data });
            } else {
            setState({ searchResults: null });
            // FIXME: ERROR MESSAGE
            }
            setState({ searching: false });
        });
    }
}

export class PaginationControl extends React.Component {
    constructor(props) {
      super(props);
    }

    componentDidMount () {
      console.log("Mounting PaginationControl");
    }

    setOffset(page, size) {
      let newPageOffset = (page * size) - this.props.pageSize;
      this.props.updateOffset(newPageOffset);
      this.props.debouncedReloadIfNeeded();
    }

    handleChangePage = (page, size) => {
      this.setOffset(page, size);
    }

    render(){

      const { pageSize, elements } = this.props;
      const { page_size, total_nof_elements } = elements;

      if (!(elements && page_size && pageSize <= elements.total_nof_elements)) return null;
      
      return (
        <Pagination 
          defaultCurrent={1} 
          defaultPageSize={pageSize}
          total={total_nof_elements} 
          onChange={this.handleChangePage}
          size='small'
          style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: 30, flexWrap: "wrap"}}
        />
      )
    };

}
