import * as moment from 'moment';

const SUPPORTED_INPUT_TYPES = [
  'checkbox',
  'dropdown',
  'date',
  'time',
  'textarea',
  'textbox',
]
const TITLE_DATA_SEPARATOR_REGEX =  new RegExp(`(?=[^,]*?)(?:,\\s*(?=${SUPPORTED_INPUT_TYPES.join('|')}|\\[[^]*\\]))`, 'm');
//const TITLE_DATA_SEPARATOR_REGEX =  new RegExp(`(?<=,|^)\s*([^,]*(?:\[[^\]]*\])?[^,]*?)(?=(?:,\s*(?=${SUPPORTED_INPUT_TYPES.join('|')}|\[[^\]]*\]))|$)`, 'm');

const TITLE_DATA_SEPARATOR_2_REGEX =  /(?=[^,]*?)(?:\s*(?=\[))/m;
const ARRAY_REGEX = /\[[^]*?\]/mg;
const ARRAY_WITHIN_OPTIONS_REGEX = /(,\s*\[.*\]|\[.*\]\s*,|\[.*\])/;
const REQUIRED_REGEX = /required/i;
const REQUIRED_WITHIN_OPTIONS_REGEX = /(,\s*required|required\s*,|required)/i;
const TYPE_REGEX = new RegExp('(?:,{0,1}\s*)?(' + SUPPORTED_INPUT_TYPES.join('|') + '|\\[[^]*\\])', 'm')
const COMMA_SEPARATED_REGEX = new RegExp('[^,]+(,[^,]+)+')

class CustomFormParser {
  constructor (rawFormData) {
    if (rawFormData) {
      this.rawFormData = rawFormData.replace(ARRAY_REGEX, (content) => content.replace(/\n/g, ''));
      this.unparsedLines = this.rawFormData.split('\n');
    } else {
      this.rawFormData = '';
      this.unparsedLines = [];
    }
  }

  parseAll = () => {
    const allParsedLines = [ ];
    let markdownAcumulator = '';
    this.unparsedLines.forEach((line, index) => {
      if (this.stringIsUnparsedInput(line)) {
        if (markdownAcumulator !== '') {
          allParsedLines.push(this.parseMarkdownData(markdownAcumulator));
          markdownAcumulator = '';
        }
        const unparsedInput = line.trim().replace(/[\n\r\s]+/g, ' ');
        allParsedLines.push(this.parseInput(unparsedInput));
      } else {
        markdownAcumulator += `${line}\n`;
      }
    });
    if (markdownAcumulator !== '') {
      allParsedLines.push(this.parseMarkdownData(markdownAcumulator));
    }
    return allParsedLines;
  }

  parseMarkdownData = (rawMarkdown) => {
    return [
      rawMarkdown,
      {type: 'markdown'}
    ];
  }

  parseInput = rawInput => {
    let [title, data] = rawInput.split(TITLE_DATA_SEPARATOR_REGEX, 2);

    if (!title || !data) {
      [title, data] = rawInput.split(TITLE_DATA_SEPARATOR_2_REGEX, 2);
    }
    title = title.trim();
    const parsed = [title, this.parseType(data)];
    return parsed;
  }

  parseType = rawOptions => {
    const parsed = {};
    let unproccessedInformation = rawOptions;
    const optionsArray = unproccessedInformation.match(ARRAY_REGEX);
    if (optionsArray) { 
      parsed.options = this.getOptionsArray(optionsArray[0]);
      parsed.type = 'dropdown';
      unproccessedInformation = unproccessedInformation.replace(ARRAY_WITHIN_OPTIONS_REGEX, '');
    }
    const isRequired = unproccessedInformation.match(REQUIRED_REGEX);
    if (isRequired) {
      parsed.required = true;
      unproccessedInformation = unproccessedInformation.replace(REQUIRED_WITHIN_OPTIONS_REGEX, '');
    }
    const type = this.getType(unproccessedInformation);
    if (type) {
      parsed.type = type;
    } else {
      //console.log(`[CustomFormParser.parseType()]! Type was not found in input definition: ${rawOptions}`)
    }
    parsed.value = this.getDefaultValue(parsed);
    return parsed;
  }

  stringIsUnparsedInput = (line) => { 
    const hasCommaSepparatedKeywords = line.match(COMMA_SEPARATED_REGEX)
    const specifiesAType = line.match(TYPE_REGEX)
    return !!(hasCommaSepparatedKeywords && specifiesAType);
  }

  getOptionsArray = (rawOptions) => {
    const elements = rawOptions.slice(1, -1).split(',').map(element => element.trim());
    return elements;
  }

  getType = (rawOptions) => {
    let foundType;
    for (let i = 0; i < SUPPORTED_INPUT_TYPES.length && !foundType; i++) {
      const iteratedType = SUPPORTED_INPUT_TYPES[i];
      if (rawOptions.includes(iteratedType)) {
        foundType = iteratedType;
      }
    }
    return foundType;
  }

  getDefaultValue = (parsedOptions) => {
    let value;
    switch (parsedOptions.type) {
      case "dropdown":
        value = parsedOptions.options[0]
        break;
      case "checkbox":
        value = false;
        break;
      case "date":
        value = moment().format('YYYY-MM-DD');
        break;
      case "time":
        value = moment().format('YYYY-MM-DD');
        break;
      default:
        value = "";
    }
    return value;
  }
}

export default CustomFormParser;
