import { defineMessages } from "react-intl";

const msg = defineMessages({

  desconce: {
    id:'invitations.descOnce',
    defaultMessage:'Select a Date and Entry and Exit Times'
  },
  descweekly: {
    id:'invitations.descWeekly',
    defaultMessage:'Select Days of the Week, Entry/Exit Times, and Dates'
  },
  descmonthly: {
    id:'invitations.descMonthly',
    defaultMessage:'Select Days of the Month and Entry/Exit Times'
  },
  once: {
    id:'invitations.Once',
    defaultMessage:'Once'
  },
  weekly: {
    id:'invitations.Weekly',
    defaultMessage:'Weekly'
  },
  monthly: {
    id:'invitations.Monthly',
    defaultMessage:'Monthly'
  },
  Date: {
    id:'invitations.Date',
    defaultMessage:'Date'
  },
  initialDate: {
    id:'invitations.initialDate',
    defaultMessage:'Initial Date'
  },
  finalDate: {
    id:'invitations.finalDate',
    defaultMessage:'Final Date'
  },
  daysOfWeek: {
    id:'invitations.daysOfWeek',
    defaultMessage:'Days of Week'
  },
  daysOfTheMonth: {
    id:'invitations.daysOfTheMonth',
    defaultMessage:'Days of the Month'
  },
  checkInTime: {
    id:'invitations.checkInTime',
    defaultMessage:'Check-in Time'
  },
  checkOutTime: {
    id:'invitations.checkOutTime',
    defaultMessage:'Check-out Time'
  },
  save: {
    id:'invitations.save',
    defaultMessage:'Save'
  },
  guest_name:{
    id:'invitations.guest_name',
    defaultMessage:'Name'
  },
  guest_identification :{
    id:'invitations.guest_identification',
    defaultMessage:'Identification'
  },
  guest_phone :{
    id:'invitations.guest_phone',
    defaultMessage:'Phone: '
  },
  guest_email :{
    id:'invitations.guest_email',
    defaultMessage:'Email'
  },
  type_of_guest :{
    id:'invitations.type_of_guest',
    defaultMessage:'Type Of Guest'
  },
  type_of_authorization :{
    id:'invitations.type_of_authorization',
    defaultMessage:'Type Of Authorization'
  },
  authorization_type :{
    id:'invitations.authorization_type',
    defaultMessage:'Select when the guest can enter'
  },
  descFrequency :{
    id:'invitations.descFrequency',
    defaultMessage:'Select the guest entry frequency'
  },
  frequency :{
    id:'invitations.frequency',
    defaultMessage:'Frequency'
  },
  Always :{
    id:'invitations.Always',
    defaultMessage:'The guest can enter at any time'
  },
  AddNewGuest :{
    id:'invitations.AddNewGuest',
    defaultMessage:'Add New Guest'
  },
  AddGuest :{
    id:'invitations.AddGuest',
    defaultMessage:'Add Guest'
  },
  AddAutho :{
    id:'invitations.AddAutho',
    defaultMessage:'Add authorization'
  },
  editGuest :{
    id:'invitations.editGuest',
    defaultMessage:'Edit Guest'
  },
  family :{
    id:'invitations.family',
    defaultMessage:'Family'
  },
  friend :{
    id:'invitations.friend',
    defaultMessage:'Friend'
  },
  service :{
    id:'invitations.service',
    defaultMessage:'Service'
  },
  client :{
    id:'invitations.client',
    defaultMessage:'Client'
  },
  SuccessRequest: {
    id:'invitations.SuccessRequest',
    defaultMessage:'The request has been sent successfully'
  },

  ErrorRequest: {
    id:'invitations.ErrorRequest',
    defaultMessage:'There was an error with the request'
  },

  always: {
    id:'invitations.always',
    defaultMessage:'Always authorized to enter'
  },

  not_allowed: {
    id:'invitations.not_allowed',
    defaultMessage:'Not authorized to enter'
  },

  recurrence: {
    id:'invitations.recurrence',
    defaultMessage:'Authorized to enter during the following intervals'
  },
  undefinedField: {
    id:'invitations.undefinedField',
    defaultMessage:'Undefined Field'
  },
  auth_details: {
    id:'invitations.auth_details',
    defaultMessage:'Guest authorization schedule'
  },
  noGuests: {
    id:'invitations.noGuests',
    defaultMessage:'No guests'
  },
  in: {
    id:'invitations.in',
    defaultMessage:'Check In'
  },
  out: {
    id:'invitations.out',
    defaultMessage:'Check out'
  },
  notesByStaff: {
    id:'invitations.notesByStaff',
    defaultMessage:'Notes by Staff'
  },
  authorization_not_allowed: {
    id:'invitations.authorization_not_allowed',
    defaultMessage:'Authorization not defined'
  },
  authorization_always: {
    id:'invitations.authorized_no',
    defaultMessage:'Is always authorized'
  },
  authorized_Yes: {
    id:'invitations.authorized_yes',
    defaultMessage:'Authorized at this moment'
  },

  startDateMissing: {
    id:'invitations.startDateMissing',
    defaultMessage: "You didn't select a start date"
  },

  currentDateGreaterThanStartDate: {
    id:'invitations.currentDateGreaterThanStartDate',
    defaultMessage:'The current date is greater than the start date'
  },

  timeStartFinishMissing: {
    id:'invitations.timeStartFinishMissing',
    defaultMessage:"You didn't select start time and finish time"
  },

  finishTimeEarlierThanStartTime: {
    id:'invitations.finishTimeEarlierThanStartTime',
    defaultMessage:"The departure time is earlier than the start time"
  },

  dateIssue: {
    id:'invitations.dateIssue',
    defaultMessage:"The start or end date is before the current date, or the start date is greater than the end date"
  },
  
  noDaySelected: {
    id:'invitations.noDaySelected',
    defaultMessage:"You didn't select any day"
  },

  showEvents: {
    id:'invitations.showEvents',
    defaultMessage:"Log Entries"
  },

  editAuth: {
    id:'invitations.editAuth',
    defaultMessage:"Edit authorization"
  },

  editAuthos: {
    id:'invitations.editAuthos',
    defaultMessage:"Edit authorizations"
  },
  editGuestAuthos: {
    id:'invitations.editGuestAuthos',
    defaultMessage:"Edit authorizations for "
  },

  edit: {
    id: 'invitations.edit',
    defaultMessage:"Edit"
  },

  delete: {
    id:'invitations.delete',
    defaultMessage:"Delete"
  },

  staffAutho: {
    id:'invitations.staffAutho',
    defaultMessage:"Staff that authorized"
  },

  isAuthorized: {
    id:'invitations.isAuthorized',
    defaultMessage:"Is Authorized"
  },

  noEvents: {
    id:'invitations.noEvents',
    defaultMessage:"No events registered!"
  },

  Search: {
    id:'invitations.Search',
    defaultMessage:"Search"
  },

  pending: {
    id:'invitations.pending',
    defaultMessage:"pending"
  },

  guestSaveSuccessfully: {
    id:'invitations.guestSaveSuccessfully',
    defaultMessage:"The guest information was saved successfully!"
  },

  requiredFields: {
    id:'invitations.requiredFields',
    defaultMessage:"Please fill in the required fields"
  },

  deleteAutho: {
    id:'invitations.deleteAutho',
    defaultMessage:"Delete authorization"
  },

  authoAddedSuccess: {
    id:'invitations.authoAddedSuccess',
    defaultMessage:"Authorization was added successfully"
  },

  newAuthoForm: {
    id:'invitations.newAuthoForm',
    defaultMessage:"New Authorization Form"
  },

  saveAndContinue: {
    id:'invitations.saveAndContinue',
    defaultMessage:"Save and continue editing rules"
  },

  close: {
    id:'invitations.close',
    defaultMessage:"Close"
  },

  saveAndBack: {
    id:'invitations.saveAndBack',
    defaultMessage:"Save and back"
  },

  startAndEndDateError: {
    id:'invitations.startAndEndDateError',
    defaultMessage:"The start or end date is before the current date, or the start date is greater than the end date"
  },

  authDeleteSuccess: {
    id:'invitations.authDeleteSuccess',
    defaultMessage:"Authorization was successfully deleted"
  },

  Mondays: {
    id:'invitations.Mondays',
    defaultMessage:"Monday"
  },

  Tuesdays: {
    id:'invitations.Tuesdays',
    defaultMessage:"Tuesday"
  },

  Wednesdays: {
    id:'invitations.Wednesdays',
    defaultMessage:"Wednesday"
  },

  Thursdays: {
    id:'invitations.Thursdays',
    defaultMessage:"Thursday"
  },

  Fridays: {
    id:'invitations.Fridays',
    defaultMessage:"Friday"
  },

  Saturdays: {
    id:'invitations.Saturdays',
    defaultMessage:"Saturday"
  },

  Sundays: {
    id:'invitations.Sundays',
    defaultMessage:"Sunday"
  },

  guest: {
    id:'invitations.guest',
    defaultMessage:"guest"
  },

  pressToAddAuthorization: {
    id:'invitations.pressToAddAuthorization',
    defaultMessage:"Press to add authorization"
  },
})


export default msg