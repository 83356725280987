import React from 'react';
import { Link } from 'react-router-dom'
import { Form, Input, Icon, Button, message } from 'antd';
import { injectIntl } from "react-intl";
import t from './messages'
import * as utils from '../../../utilities/utils.js'
import Header from './Header.js'
import msg from './messages'
import { SmartRequests } from '../../../utilities';

const FormItem = Form.Item

class Activate extends React.PureComponent{
  
  constructor(props) {
    super(props);
    this.state = { activation:'', mail:''}
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.t = this.props.intl.formatMessage
  }

  handleChange(event) {
    this.setState({[event.target.name]:event.target.value})
  }

  handleSubmit(event) {
    event.preventDefault();
    SmartRequests.post('/account/activate', { key: this.state.activation.trim(), ident: this.state.mail.trim() })
      .then( response => {
      message.success('The user has been activated. You will receive an email with your password.')
    }).catch((error) => {
      message.error('An error occurred in the activation')
    });
  }


  render() {
    const t = this.t
    return (
      <div  className="activate option-page">
        <div class="back-link">
          <Link to="../login" ><Icon type="login" /><p>{t(msg.login)}</p></Link>
        </div>
        <Form onSubmit={this.handleSubmit}> 
          <Header>{t(msg.activateAccount)}</Header>
          <FormItem>
            <Input 
              type="text" 
              name="mail" 
              id="mail" 
              placeholder={t(msg.email)}
              onChange={this.handleChange}
              prefix={<Icon type="mail" />}  
            />
          </FormItem>
          <FormItem>
            <Input 
              type="text" 
              name="activation" 
              id="activation" 
              placeholder={t(msg.activation)}
              onChange={this.handleChange}
              prefix={<Icon type="key" />}  
            />
          </FormItem>
          <Button type="primary" htmlType="submit" >{t(msg.activate)}</Button>
        </Form>
      </div>
    )
  }
}


export default Activate =  injectIntl(Activate)