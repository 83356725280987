import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { SmartRequests } from '../../utilities'
import Valet from './Valet'
import PageView from '../PageView'
import Fetching from '../../components/Fetching';
import BuildingInfo from '../Main/buildingInfo';

const INITIAL_PATH_DEEPNESS = 5 // also the number of slashes ('/')


class Guest extends React.Component {

  state = {
    theme: '',
    foundUnitAlreadyLoggedIntoBuilding: undefined,
    shouldIMoveToDashboard: false,
  }

  componentDidMount() {
    this.loadStyle()
    this.checkForLogginStatus()
  }

  loadStyle = () => {
    let match = this.props.match
    let building = match.params.building
    let url = `/${building}/resident/guest/ws/2/building`
    SmartRequests.get(url).then(response => {
      let data = response.data
      let building = data.building
      let theme = building.style
      this.setThemeName(theme)
    }).catch(e => {
      console.log(`Error requesting building info. Default style will be used. ${e}`)
    })
  }

  setThemeName = (theme) => {
    this.setState({ theme })
  }

  checkForLogginStatus = () => {
    let foundUnitAlreadyLoggedIntoBuilding
    const match = this.props.match
    const buildingInPath = match.params.building
    BuildingInfo.getStoredBuildings().then(buildings => {
      const buildingFoundInStorage = buildings.find((building) => building.name === buildingInPath)
      if (buildingFoundInStorage) {
        const url = '/resident/ws/info'
        SmartRequests.head(url, null, { ignoreAuthError: true }).then((response) => {
          let error = response.error
          if (error) {
            console.log(error)
            foundUnitAlreadyLoggedIntoBuilding = false
          } else {
            foundUnitAlreadyLoggedIntoBuilding = buildingFoundInStorage.units[0]
          }
          this.setState({ foundUnitAlreadyLoggedIntoBuilding })
        }).catch((error) => {
          console.log(`Could not check login status, entering as guest... ${error}`)
          foundUnitAlreadyLoggedIntoBuilding = false
          this.setState({ foundUnitAlreadyLoggedIntoBuilding })
        })
      } else {
        console.log(`Building not found into user's logged buildings list, entering as guest...`)
        foundUnitAlreadyLoggedIntoBuilding = false
        this.setState({ foundUnitAlreadyLoggedIntoBuilding })
      }
    })
  }

  getCurrentUrlMappedToResidentUrl = () => {
    // FIXME: refactor me when guest valet is also into 
    const location = this.props.location
    const routeMatch = this.props.match
    const path = location.pathname
    const building = routeMatch.params.building
    const theRegex = new RegExp(`${building}/guest/page/(.*)`)
    const pageSubpath = path.match(theRegex)[1]
    const residentUrl = `/${building}/${this.state.foundUnitAlreadyLoggedIntoBuilding}/dashboard/${pageSubpath}`
    return residentUrl
  }

  render() {
    const pathMatchesCustomPages = this.props.location.pathname.match(/^\/[^\/]*\/guest\/page\//)
    if (this.state.foundUnitAlreadyLoggedIntoBuilding === undefined) {
      return <Fetching enabled />
    } else if (this.state.foundUnitAlreadyLoggedIntoBuilding && pathMatchesCustomPages) {
      let pageUrl = this.getCurrentUrlMappedToResidentUrl()
      return <Redirect to={pageUrl} />
    }

    return (
      <div id='theme-container' className={[this.state.theme, 'guest'].join(' ')}>
        <Switch className="class-test">
          <Route path='/:building/guest/valet/:hash' strict render={ route => 
            <Valet
              building={route.match.params.building}
              hash={route.match.params.hash}
            /> 
          } />
          <Route path='/:building/guest/page/:pageName/' strict render={ route => 
              <PageView
                pageType='custom'
                isGuest
                building={route.match.params.building} 
                pageName={route.match.params.pageName}
                level={INITIAL_PATH_DEEPNESS}
                parentPath={'/:building/guest/page/'}
              />
          } />
          <Redirect to="/access/login"/>
        </Switch>
      </div>
    )
  }
}

export default withRouter(Guest)
