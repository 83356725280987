import * as moment from 'moment';
import { SmartRequests } from '../../../utilities/index.js';

const DATE_FORMAT = 'YYYYMMDDHHmm';
const DAY_FORMAT  = 'YYYYMMDD';
const HOUR_FORMAT = 'HHmm';
export

const SHORT_MONTHS = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];
const DAY_OF_WEEK = [
  'su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'
];

const RESERVED_FOR_NOBODY  = 0,
      RESERVED_FOR_ME      = 1,
      RESERVED_FOR_ANOTHER = 2,
      RESERVED_FOR_CLOSURE = 3;
export { RESERVED_FOR_NOBODY };
export { RESERVED_FOR_ME };
export { RESERVED_FOR_ANOTHER };
export { RESERVED_FOR_CLOSURE };

const ERROR = -1;
export { ERROR };

/* Returns an array containing all dates of the week on certain index */
function getWeekArray(monthDate, index) {
  var weekArray = [];

  // Get sunday of week specified by index
  var dateIterator = monthDate.clone();
  dateIterator
    .set(monthDate.format('D')-monthDate.day(), 'days'); // Now it points to sunday
  dateIterator
    .set(dateIterator.format('D')+index*7, 'days');      // Now current week is the one specified by index
  dateIterator
    .startOf('day');                                     // Now hours, minutes and seconds are set to zero


  // Populate weekArray with dates of month; a day will be null if it is not in selected month
  for (var dayIndex = 0; dayIndex < 7; dayIndex++) {
    if (monthDate.format('MM') === dateIterator.format('MM')) {
      weekArray.push(dateIterator.clone());
    } else { // If iterated day is not in selected month...
      weekArray.push(null);
    }
    dateIterator.set(dateIterator.format('D')+1, 'days');
  }

  return weekArray;
}

export const dateHelpers = {
  /* This takes a date and returns a string with format of server */
  'serializeDate': function serializeDate(date) {
    return date.format(DATE_FORMAT);
  },

  /* This takes a formatted string and returns a javascript date */
  'parseDate': function unserializeDate(dateString) {
    return moment(dateString, DATE_FORMAT);
  },

  'serializeDay': function serializeDay(date) {
    return date.format(DAY_FORMAT);
  },

  'serializeHour': function serializeHour(date) {
    return date.format(HOUR_FORMAT);
  },

  'unserializeDay': function unserializeDay(dateString) {
    return moment(dateString, DAY_FORMAT);
  },

  /* This returns a human-readable string with the month and year */
  // This is supoused to be used in navigation bar of calendar
  'humanReadableMonthDate': function humanReadableMonthDate(date) {
    return SHORT_MONTHS[date.getMonth()]+' '+date.getFullYear();
  },
  'humanReadableDate': function humanReadableDate(date) {
    return date.format('D MMM YYYY');
  },

  'getMonthDate': function getMonthDate(year, month) {
    return moment(year, month, 1);
  },

  /* Returns an array cantaining all weeks (an array of 7 dates) of the month */
  'getCalendarRepresentation': function getCalendarRepresentation(monthDate) {
    var calendarRepresentation = []; // Value to be returned
    var weeksInMonth = 6; // FIXME: read it dynamically

    for (var weekIndex = 0; weekIndex <= weeksInMonth; weekIndex++) {
      calendarRepresentation.push(getWeekArray(monthDate, weekIndex));
    }

    return calendarRepresentation;
  },

  /* Returns an array containing all turns (an array with begin and start dates) of the day in certain reservable */ 
  'getByHourCalendarRepresentation': function getByHourCalendarRepresentation(openTime, closeTime, period, closes_next_day=false) { // period is expressed in minutes
    var calendarRepresentation = [];

    if (period === 0) {
      console.log('[amenities:helpers:getByHourCalendarRepresentation]! period is 0');
      return [];
    }

    var dateDelimiter1 = openTime.clone(),
        dateDelimiter2 = openTime.clone();

    const openTimeDay = openTime.day()
    dateDelimiter2.add(period, 'minutes');

    let startShifting=false
    let index=0

    while (dateDelimiter2 <= closeTime ) {
      if (startShifting){
        calendarRepresentation.splice(index, 0, dateDelimiter1.clone());
        index += 1;
      } else {
        calendarRepresentation.push(dateDelimiter1.clone());
      }
      dateDelimiter1.add(period, 'minutes');
      if (closes_next_day && openTimeDay !== dateDelimiter1.day()){
        startShifting = true
        dateDelimiter1.subtract(1, "days");
      }
      dateDelimiter2.add(period, 'minutes');
    }

    return calendarRepresentation;
  },
  'isSameDate': function isSameDate(date1, date2) {
    const safe_date2 = (Array.isArray(date2) && date2.length === 2)? date2[0]: date2;
    const comparation = (
      date1 && safe_date2 &&
      date1.format('YYYYMMDD') === safe_date2.format('YYYYMMDD')
    );
    return comparation;
  },
  'isBlockedDOW': function isBlockedDOW(groupData, day) {
    return groupData['dow_'+DAY_OF_WEEK[day]] === 0;
  },
  'setHour': function setHour(date, hour) {
    var pattern = /(\d{2})/g;
    var hours = pattern.exec(hour)[0];
    var minutes = pattern.exec(hour)[0];

    var newDate = date.clone();

    newDate.startOf('date');
    newDate.add(hours, 'hours');
    newDate.add(minutes, 'minutes');

    return newDate;
  },
  'getEnd': function getEnd(date, period) {
    return date.clone().add(period, 'minutes');
  }, 
}

function serializeDate(date) {
  return date.format(DATE_FORMAT);
};

export const reservations = {
  // return values 0: no, 1: yep, 2: by another, -1: error
  'getReservationLevel': function getReservationLevel(closures, _reservations, date, unitNumber, isByHour) {
    const cellHasData = date.start && date.start._isAMomentObject; 
    if (!cellHasData) return RESERVED_FOR_NOBODY;

    for (var i in closures) {
      var closureDate = moment(closures[i].start_date);
      if (
        dateHelpers.isSameDate(closureDate, date.start) &&
        (!isByHour || closureDate.format(DATE_FORMAT) === date.start.format(DATE_FORMAT))
      ) {
        return RESERVED_FOR_CLOSURE;
      }
    }

    var reservationByAnotherFound = false;

    const indexForToday = reservations._dateToString(date.start);
    const reservationsForToday = _reservations[indexForToday];

    // loop over reservations
    for (var i in reservationsForToday) {
      var reservation = reservationsForToday[i];
      if (isByHour && reservation.period === 0) {
        return ERROR;
      }
      const startDate = moment(reservation.start_date);
      //const endDate = moment(reservation.end_date);

      // check if reservation's date is the same of specified date
      if (
        dateHelpers.isSameDate(startDate, date.start) &&
        (!isByHour || startDate.format(DATE_FORMAT) === date.start.format(DATE_FORMAT))
      ) {
        // there is a reservation for this day
        // check owner...
        if (unitNumber === reservation.for_unit_number) {
          return RESERVED_FOR_ME;
        } else {
          reservationByAnotherFound = true;
        }
      }
    }
    return (
      reservationByAnotherFound
      ? RESERVED_FOR_ANOTHER
      : RESERVED_FOR_NOBODY
    );
  },
  // returns the classname from output of getReservationLevel
  'getStyledClass': function getStyledClass(reservationLevel, isBlocked) {
    if (isBlocked) 
      return 'closed';
    switch (reservationLevel) {
      case RESERVED_FOR_NOBODY:
        return 'nobody';
      case RESERVED_FOR_ME:
        return 'forme';
      case RESERVED_FOR_ANOTHER:
      case RESERVED_FOR_CLOSURE:
        return 'closed';
      default:
        if (reservationLevel !== ERROR)
          console.log('[helpers:getClasses()]! Unknown reservation level: '+reservationLevel);
        return 'error';
    }
  },
  'getAllReservationsForDay': function getAllReservationsForDay(_reservations, date, unitNumber) {
    var foundReservations = [];
    //reservations.forEach(
    //  function(reservations, index, reservation) {
    //    const reservationDate = moment(reservation.startDate);
    //    if (dateHelpers.isSameDate(reservationDate, date)) {
    //      foundReservations.push(reservation);
    //    }
    //  }
    //);
    const reservationIndex = reservations._dateToString(date);
    foundReservations = _reservations[reservationIndex];
    return foundReservations;
  },
  '_dateToString': function _dateToString(date) {
    var theString = '';
    if (date && date._isAMomentObject) {
      theString = date.format('DD/MM/YYYY');
    } else {
      console.log('[amenities:reservations._dateToString()]! Date '+date+' is not valid');
    }
    return theString;
  },
  'sortByDay': function sortByDay(_reservations) {
    var sorted = {};
    _reservations.forEach(
      function(reservation, index, _reservations) {
        const reservationDate = moment(reservation.start_date);
        const indexKey = reservations._dateToString(reservationDate);
  
        if (!sorted[indexKey])
          sorted[indexKey] = [];
        
        sorted[indexKey].push(reservation);
      }
    );
    return sorted;
  },
  'computeAvailability': function computeAvailability(_reservations, closures, groupData, monthDate, unitNumber) {
    var availability = {}; // the returned thing 
    var isByHour = (groupData.period !== 0);

    // TODO: read closures
    var closuredDays = {};
    if (Array.isArray(closures)) {
      closures.forEach(
        function (closure) {
          var dayIndex = dateHelpers.serializeDay(moment(closure.start_date));
          var hourIndex = isByHour? dateHelpers.serializeHour(moment(closure.start_date)): '0000';
          if (!closuredDays[dayIndex]) {
            closuredDays[dayIndex] = {};
          }
          if (!closuredDays[dayIndex][hourIndex]) {
            closuredDays[dayIndex][hourIndex] = closure;
            console.log('[helpers:computeAvailability()] Found closure at day '+dayIndex+', hour '+hourIndex);
          }
        }
      );
    }

    var firstDayMonth = monthDate.clone().startOf('month');
    var lastDayMonth = monthDate.clone().endOf('month').startOf('day');
    
    for (var iteratedDate = firstDayMonth; iteratedDate.isSameOrBefore(lastDayMonth); iteratedDate.add(1, 'day')) {

      var dateKey = iteratedDate.format(DAY_FORMAT);
      if (groupData.closes_next_day) {
        var nextDateKey =  iteratedDate.clone().add(1, 'day').format(DAY_FORMAT) 
        availability[nextDateKey] = {};
      }

      if (!availability[dateKey]) {
        availability[dateKey] = {}
      }

      var iteratedDateClose = iteratedDate.clone();
      if (groupData.closes_next_day) {
        iteratedDateClose = iteratedDateClose.add(1, 'day');
      }
      var hoursToCheck;
      if (isByHour) {
        let [o_h, o_m, o_s] = groupData.open_time.split(':');
        let [c_h, c_m, c_s] = groupData.close_time.split(':');
        hoursToCheck = dateHelpers.getByHourCalendarRepresentation(
          iteratedDate.clone().set({
            hours: o_h,
            minutes: o_m,
            seconds: o_s,
          }),
          iteratedDateClose.set({
            hours: c_h,
            minutes: c_m,
            seconds: c_s,
          }),
          groupData.period,
        );
      } else {
        hoursToCheck = [iteratedDate];
      }
      let currentDay = dateKey
      for (var hourIndex = 0; hourIndex < hoursToCheck.length; hourIndex++) {
        var hourKey = hoursToCheck[hourIndex].format(HOUR_FORMAT);
        if (groupData.closes_next_day && iteratedDate.day() !== hoursToCheck[hourIndex].day()) {
            currentDay =nextDateKey
        }
        var isClosured = (closuredDays[currentDay] && closuredDays[currentDay][hourKey] !== undefined);
        var availableSlots = reservations.getAvailableSlots(
          _reservations, 
          groupData.items, 
          {start: hoursToCheck[hourIndex]}, 
          unitNumber, 
          isByHour
          );

        availability[currentDay][hourKey] = {};
        Object.keys(availableSlots).forEach(
          
          function(itemID) {
            if (isClosured || availableSlots[itemID].closed) {
              availability[currentDay][hourKey][itemID] = RESERVED_FOR_CLOSURE;
              console.log('[helpers:computeAvailability()] Setting day '+currentDay+hourKey+' as closured');
            } else if (availableSlots[itemID].forme !== 0) {
              availability[currentDay][hourKey][itemID] = RESERVED_FOR_ME;
            } else if (
              (availableSlots[itemID].foranother !== 0 && availableSlots[itemID].foranother === availableSlots[itemID].maximum)
              || (availableSlots[itemID].foranother !== 0 && availableSlots[itemID].maximum === 0)
            ) {
              // console.log('## TRACE ## for another: '+availableSlots[itemID].foranother+', maximum: '+availableSlots[itemID].maximum);
              availability[currentDay][hourKey][itemID] = RESERVED_FOR_ANOTHER;
            } else {
              availability[currentDay][hourKey][itemID] = RESERVED_FOR_NOBODY;
            }
          }
        );
        
      }
    }
    
    return availability;
  },
  'getAvailableSlots': function getAvailableSlots(_reservations, items, date, unitID, isByHour) {
    const index = reservations._dateToString(date.start);
    const reservationsForDay = _reservations[index];
    var maxAmounts = {};
    var amounts = {};

    // var THE_ID = prompt('Dame el id uvu');

    var isInRange;
    if (isByHour) {
      isInRange = function inRangeByHour(reservation) {
        var reservationDate = moment(reservation.start_date);
        var condition = (dateHelpers.serializeDate(reservationDate) === dateHelpers.serializeDate(date.start));
        //console.log('Is in range '+condition+'. Reservation date: '+dateHelpers.serializeDate(reservationDate)+', date.start: '+dateHelpers.serializeDate(date.start));
        return condition;
      }
    } else {
      isInRange = function inRangeAllDay(reservation) {
        //var reservationDate = moment(reservation.start_date);
        //return dateHelpers.isSameDate(reservationDate, date.start);
        return true;
      }
    }

    items.forEach(
      function(item, itemIndex) {
        maxAmounts[item.id] = item.amount;
        amounts[item.id] = {
          maximum: item.amount,
          foranother: 0,
          forme: 0,
          closed: false,
        }
      }
    );

    
    if (reservationsForDay) {
      reservationsForDay.forEach(
        function(reservation, index, _reservations) {
          if (isInRange(reservation)) {
            var itemID = reservation.reservable_id;
            if (reservation.state === "closed") {
              amounts[itemID].closed = true;
            } else if (reservation.for_unit_number === unitID) {
              amounts[itemID].forme += reservation.amount;
            } else {
              amounts[itemID].foranother += reservation.amount;
            }
          } else {
            console.log('[amenities:reservations.getAvailableSlots()] Reservation out of range found. Ignoring...');
          }
        }
      );
    }
    


    return amounts;
  },
  'arrayDateToDict': function arrayDateToDict(date) {
    // The propouse of this function is to reorder this.props.date into a new dict.
    // This auxiliar function is intended to be used in reservations.getReservationLevel argumments
    var dict = {};
    if (Array.isArray(date) && date.length === 2) {
      dict['start'] = date[0];
      if (date[1]) dict['end'] = date[1];
    } else {
      dict['start'] = date;
    }
    return dict;
  },
  //// getAvailableSlots returns all available reservations of group in certain date
  //'getAvailableSlots': function(reservationsData, groupID, date) {
  //},
  'getReservationID': function getReservationID(itemID, _reservations, start, isByHour) {
    var foundReservation;
    var index = 0;
    var compareDates;
    if (isByHour) {
      compareDates = function (date1, date2) {
        var d1 = dateHelpers.serializeDate(date1);
        var d2 = dateHelpers.serializeDate(date2);
        return (d1 === d2);
      };
    } else {
      compareDates = dateHelpers.isSameDate;
    }
    const indexForDay = reservations._dateToString(start);
    const reservationsForDay = _reservations[indexForDay] || [];
    while (index < reservationsForDay.length && !foundReservation) {
      var reservation = reservationsForDay[index];
      var startDate = moment(reservation.start_date);
      if (reservation.reservable_id === itemID && compareDates(startDate, start)) {
        foundReservation = reservation;
      }
      index++;
    }
    return foundReservation?foundReservation.id:null;
  },
  'sortByReservable': function sortByReservable(reservations) {
    var sorted = {};
    reservations.forEach(
      function(reservation, index) {
        var resID = reservation.reservable_id;
        if(sorted[resID] === undefined) {
          sorted[resID] = [];
        }
        sorted[resID].push(reservation);
      }
    );
    return sorted;
  },
  'getToken': function getToken(res) {
    // Token is an identifier containing date and id of the reservation
    var isByHour = (res.period !== 0);
    var startDate = moment(res.start_date);
    var dayIndex = dateHelpers.serializeDay(startDate);
    var hourIndex = isByHour? dateHelpers.serializeHour(startDate): '0000';
    var itemID = res.reservable_id;
    var reservationToken = dayIndex + hourIndex + '_' + itemID;
    return reservationToken;
  },
  'clone': function clone(res) {
    var cloned = {
      id:               res.id,
      description:      res.description,
      start_date:       res.start_date,
      end_date:         res.end_date,
      fmt_start_date:   res.fmt_start_date,
      fmt_end_date:     res.fmt_end_date,
      period:           res.period,
      symbol_price:     res.symbol_price,
      item_description: res.item_description,
      name:             res.name,
      item_name:        res.item_name,
      for_unit_number:  res.for_unit_number,
      amount:           res.amount,
      reservable_id:    res.reservable_id,
      group_id:         res.group_id,
      state:            res.state,
    };
    return cloned;
  },
};

export const remote = {
  'makeReservation': function makeReservation(reqData, groupID, amount, start, end, callback) {
    const URL = 'reservables/'+groupID+'/reservations';

    var reservationData = {};
    if (end) {
      reservationData.s = serializeDate(start);
      reservationData.e = serializeDate(end);
    } else {
      // if all-day reservation, hour is set to 0000
      reservationData.s = serializeDate(start.clone().startOf('day'));
    };
    if (!isNaN(amount) && amount!==null) {
      reservationData.amount = amount;
    }

    SmartRequests.post(URL, reservationData)
      .then(function(resp) {
        if (resp.status === 200) {
          console.log('[amenities:helpers.makeReservation()] Reservation succesfully requested');
          if (callback) callback(resp.data);
        }
      })
      .catch(function(err) {
        console.log('[amenities:helpers.makeReservation()]! '+err);
        if (callback) callback({err: err});
      });
  },
  'fetchReservables': function fetchReservables(reqData, callback) {
    const URL = 'reservables';

    SmartRequests.get(URL).then(function(resp) {
      callback(resp);
    });
  },
  'fetchReservations': function fetchReservations(reqData, groupID, callback, date) {
    const URL = 'reservables/'+groupID+'/reservations'+(date? '?after_date='+date.format(DATE_FORMAT): '');

    SmartRequests.get(URL).then(function(resp) {
      callback(resp);
    });
  },
  'cancelReservation': function cancelReservation(reqData, reservationID, callback) {
    const URL = 'reservations/'+reservationID+'/events';
    var reservationData = {};
    reservationData.event = 'cancel';
    SmartRequests.post(URL, reservationData)
      .then(function(resp) {
        if (resp.status === 200) {
          console.log('[amenities:helpers.cancelReservation()] Reservation successfully cancelled');
          if (callback) callback({});
        }
      })
      .catch(function(err) {
        console.log('[amenities:helpers.cancelReservation()]! '+err);
        if (callback) callback({err: err});
      });
  },
  'fetchClosures': function fetchClosures(reqData, groupID, callback) {
    const URL = 'reservables/'+groupID+'/closures';
    SmartRequests.get(URL)
      .then(function(resp) {
        console.log('[amenities:helpers.fetchClosures()] Closures succesfully fetched');
        if (callback) callback(resp);
      });
  },

  'canCancelReservation': function canCancelReservation(min_time_in_advance, min_advance_unit,start_date) {
    let canCancel = true;
    if (min_time_in_advance === 0) {
      canCancel = moment() <= start_date.subtract(1, 'hours');
    } else {
      canCancel = moment() <= start_date.subtract(min_time_in_advance, min_advance_unit);
    }
    return canCancel;
  }
};

