import React from 'react';
import MDRenderer from '../MDRenderer';

class Description extends React.Component {
    render = () => (
        <div className="page-description">
            <MDRenderer className="text frame"> {this.props.text} </MDRenderer>
        </div>
    )
}

export default Description;