import React from 'react';
import { Route, Link } from 'react-router-dom'
import { Form, Input, Icon, Button } from 'antd';
import { injectIntl } from "react-intl";
import TransitionSwitch from '../../components/TransitionSwitch.js'
import Activate from './AccessOptions/Activate.js'
import Reset from './AccessOptions/Reset.js'
import PasswordLess from './AccessOptions/PasswordLess.js'
import msg from './messages'
import * as CustomStorage from '../../utilities/customStorage';
import { isNativeStorageSupported } from '../../utilities/utils.js';

const FormItem = Form.Item
const LEVEL = 3
const USER_NAME_ITEM = 'USER_NAME_ACCESS';

// It manages the user accounts. Responsible for Login, activations or reset password.
class LogIn extends React.Component{

  constructor(props) {
    super(props)
    this.state = {
      username: "",
      password: "",
      fetched: false
    }
  }

  componentDidMount = () => {
    this.recoverUserName();
  }

  handleChange = (event) => {
    this.setState({[event.target.name]:event.target.value.trim()})
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const username = this.state.username;
    const password = this.state.password;
    this.props.loginFunction(username, password, this.props.history).catch((e) => console.error("Could not login:",e)).finally(() => {
      this.setState({fetched:true});
      this.storeUserName();
    });
  }

  storeUserName = () => {
    if (isNativeStorageSupported()) {
      const username = this.state.username;
      CustomStorage.setItem(USER_NAME_ITEM, username);
    }
  }

  recoverUserName = () => {
    if (isNativeStorageSupported()) {
      CustomStorage.getItem(USER_NAME_ITEM).then(username => {
        if (username) {
          this.setState({ username });
        }
      });
    }
  }

  render = () => {
      const t = this.props.intl.formatMessage
      return (  
        <div className="access"> 
          <div class="header" > <img src={require('../../images/logos/mirigi.png')} alt='mirigi' /> </div>
          <Form onSubmit={this.handleSubmit} className="login-form"> 
            <h1> {t(msg.login)} </h1>
            <FormItem >
              <Input 
                prefix={<Icon type="user" />}  
                placeholder={t(msg.email)} 
                name="username" 
                type="email"
                onChange={this.handleChange} 
                value={this.state.username}
              />
            </FormItem>
            <FormItem >
              <Input 
                prefix={<Icon type="lock" />}  
                placeholder={t(msg.password)} 
                name="password" 
                type="password" 
                onChange={this.handleChange} 
              />
            </FormItem>
            <Button type="primary" htmlType="submit" >{t(msg.login)}</Button>
          </Form>
          <div className="footer">
            <Link className="footer-link" to={'reset/'}>{t(msg.forgotPassword)}</Link>
            <br/>
            <Link className="footer-link"  to={'activate/'}>{t(msg.newAccount)}</Link>
            <br/>
            <Link className="footer-link" to={'device/'}>{t(msg.activateDevice)}</Link>
          </div>
          <div className="child-view">
            <TransitionSwitch level={LEVEL}>
              <Route path={'/access/reset'}    render={() => <Reset /> }/>
              <Route path={'/access/activate'} render={() => <Activate /> }/>
              <Route path={'/access/device'} render={() => <PasswordLess 
                login={this.props.loginFunction} 
                pLessLogin={this.props.pLessLogin} 
                history={this.props.history} />
              }/>
            </TransitionSwitch>
          </div>
        </div>
      )
  }
}

export default LogIn =  injectIntl(LogIn)
