import { Card } from 'antd';
import React from 'react';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router-dom';
const { Meta } = Card;



class PaymentMethods extends React.Component  {

    constructor(props) {
      super(props)
      this.state = {
        imagePath: require(`../../../styles/skins/default/img/${props.method.replace(/\s+/g, '_').toLowerCase()}.png`),
      }
    }

    componentDidMount() {
        console.log('Mounting PaymentMethods');
        this.t = this.props.intl.formatMessage;
    }
    
    render() {
      const { method, isSelected } = this.props;
      const { imagePath } = this.state;

      const cardClassName = isSelected ? "card-selected" : "card";
    
      return (
        <Card
          hoverable
          className={cardClassName}
          cover={<img alt="example" src={imagePath} className='card-image'/>} 
          onClick={() => this.props.onClickHandler(method)}
        >
          <Meta title={<span style={{ whiteSpace: 'pre-wrap'}}>{method}</span>} />
        </Card>
      );
    }
}

export default injectIntl(withRouter(PaymentMethods));
