import React from 'react';
import msg from './messages.js';
import { injectIntl } from "react-intl";

class MenuHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLeftScroll: false,
      showRightScroll: false
    };

    this.categoryContainerRef = React.createRef();
  }

  componentDidMount() {
    this.updateScrollButtons();
    window.addEventListener('resize', this.updateScrollButtons);
    if (this.categoryContainerRef.current) {
      this.categoryContainerRef.current.addEventListener('scroll', this.updateScrollButtons);
    }
  }

  componentDidUpdate = prevProps => {
    if (this.props.dishes!==prevProps.dishes){
        this.updateScrollButtons();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScrollButtons);
    if (this.categoryContainerRef.current) {
      this.categoryContainerRef.current.removeEventListener('scroll', this.updateScrollButtons);
    }
  }

  updateScrollButtons = () => {
    if (this.categoryContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = this.categoryContainerRef.current;
      this.setState({
        showLeftScroll: scrollLeft > 0,
        showRightScroll: scrollLeft < scrollWidth - clientWidth - 10
      });
    }
  }

  scroll = (direction) => {
    if (this.categoryContainerRef.current) {
      const scrollAmount = this.categoryContainerRef.current.clientWidth * 0.6;
      this.categoryContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  }

  render() {
    const { dishes, defaultLang, currentLang, selectedTags, subTitleOpenHours } = this.props;
    const { showLeftScroll, showRightScroll } = this.state;
    const translate = this.props.intl.formatMessage 
    const filterTags = [
      { id: 'veggie', label: translate(msg.vegetarianTag) },
      { id: 'vegan', label: translate(msg.veganTag) },
      { id: 'gluten_free', label: translate(msg.glutenFreeTag) }
    ];

    return (
        <header className="menu-header">

        <div className="menu-header__container">
          <div className="menu-header__category-section">
            {showLeftScroll && (
              <button
                onClick={() => this.scroll('left')}
                className="menu-header__scroll-button menu-header__scroll-button--left"
              >
                <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" fill="none">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </button>
            )}
            
            <div
              ref={this.categoryContainerRef}
              className="menu-header__category-container"
            >
              {dishes.map(category => (
                <button
                  key={category.category[defaultLang]}
                  onClick={() => this.props.handleCategoryClick(category.category[defaultLang])}
                  className="menu-header__category-button"
                >
                  {category.category[currentLang] || category.category[defaultLang]}
                </button>
              ))}
            </div>

            {showRightScroll && (
              <button
                onClick={() => this.scroll('right')}
                className="menu-header__scroll-button menu-header__scroll-button--right"
              >
                <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" fill="none">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            )}

            <div 
              className={`menu-header__scroll-indicator ${
                showRightScroll ? 'menu-header__scroll-indicator--visible' : ''
              }`}
            />
          </div>

          <div className="menu-header__tag-section">
            {filterTags.map(tag => (
              <button
                key={tag.id}
                onClick={() => this.props.handleTagClick(tag.id)}
                className={`menu-header__tag-button ${
                    selectedTags.includes(tag.id) 
                      ? 'menu-header__tag-button--selected' 
                      : ''
                  }`}
              >
                {tag.label}
              </button>
            ))}
          </div>
        </div>
        <h4 type="secondary" 
        style={{
          margin: '1rem 1rem 0 1rem',
          fontWeight: 'bold'
        }}> {subTitleOpenHours}</h4>

      </header>
    );
  }
}

export default injectIntl(MenuHeader);