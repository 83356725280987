
const TOUCH_PANEL_MODE = 'touch_panel_mode';
const REMOTE_MANAGED = 'remote_managed';


class MDMConfig {
  constructor(setTouchPanelMode) {
    this.setTouchPanelMode = setTouchPanelMode;
  }

  set = (data) => {
    const touchPanelModeEnabled = data[TOUCH_PANEL_MODE];
    this.setTouchPanelMode(touchPanelModeEnabled);
  }
}

export default MDMConfig;