import React from 'react';
import msg from '../../Invitations/messages';
import { injectIntl } from "react-intl";


export const CustomField = injectIntl(class extends React.PureComponent {
  render = () => {
    return (
      <div className="field-wrapper">
        <Field type={this.props.type} id={this.props.id} name={this.props.name} onChange={this.props.onChange} value={this.props.value} innerRef={this.props.innerRef} options={this.props.options} intl={this.props.intl} />
      </div>
    )
  }
})

class Field extends React.PureComponent {
  customChangeEvent = (evt) => {
    if (typeof this.props.onChange === 'function') {
      const value = evt.target.value;
      this.props.onChange(value);
    }
  }

  render = () => {
    const type = this.props.type || 'text';
    if (['date', 'checkbox', 'text', '', 'time', 'textbox'].includes(type)) {
      return (
        <input type={type} id={this.props.id} name={this.props.name} onChange={this.customChangeEvent} ref={this.props.innerRef} className="ant-input" value={this.props.value} />
      );
    } else if (this.props.type === 'textarea') {
      return (
        <textarea id={this.props.id} name={this.props.name} onChange={this.customChangeEvent} ref={this.props.innerRef} className="ant-input" value={this.props.value}/>
      );
    } else if (type === 'select') {
      return (
        <select className="ant-input" id={this.props.id} name={this.props.name} onChange={this.customChangeEvent} ref={this.props.innerRef} value={this.props.value}>
          {this.props.options.map(option => (
            <option key={option} value={option}>
              {option in msg ? this.props.intl.formatMessage(msg[option]) :
              option}
            </option>
          ))}
        </select>
      );
    } else {
      console.error(`[CustomField] Unsupported field type: "${type}". Will render a disabled input`);
      return <input disabled />;
    }
  }
}

