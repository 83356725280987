import React from 'react';
import { notification as antNotification } from 'antd';

import CordovaNotificationHandler from '../Main/cordovaCommunication/cordovaNotificationHandler';
import NotificationContent from './NotificationContent';

const MAX_NOTIFICATIONS_STACK = 2;
const SECONDS_UNTIL_NOTIFICATION_CLOSED = 300; // 5 minutes

class Notifications extends React.Component {

    state = {
        openedNotifications: [ ]
    }

    componentDidMount = () => {
        antNotification.config({
            placement: 'bottomRight',
            duration: SECONDS_UNTIL_NOTIFICATION_CLOSED,
        });

        const notifications = CordovaNotificationHandler.getIncomingForegroundNotification();
        this.stopListeningNotifications = notifications(this.handleNewData);
    }

    componentWillUnmount = () => {
        this.stopListeningNotifications();
    }

    handleNewData = (notification) => {
        if (notification) {
            this.insertNewNotification(notification);
            console.log('[Notifications.componentDidMount()] Refreshing notifications');
        }
    }

    insertNewNotification = (notification) => {
        this.setState((state) => {
            const currentOpenedNotifications = state.openedNotifications;
            var newOpenedNotifications = currentOpenedNotifications.slice();
            if (state.openedNotifications.length >= MAX_NOTIFICATIONS_STACK) {
                const notificationToRemove = newOpenedNotifications.shift();
                antNotification.close(this.getNotificationKey(notificationToRemove));
            }
            newOpenedNotifications.push(notification);
            return { openedNotifications: newOpenedNotifications };
        }, () => this.showNotification(notification));
    }

    showNotification = (notification) => {
        antNotification.info({
            key: this.getNotificationKey(notification),
            message: notification.title,
            description: (
                <NotificationContent
                    message={notification.message}
                    imageUrl={notification.image} 
                />
            ),
            onClick: this.openNotification.bind(this, notification),
            onClose: this.removeNotification.bind(this, notification),
        });
    }

    openNotification(notification) {
        CordovaNotificationHandler.openNotification(notification);
        this.removeNotification(notification);
    }

    removeNotification(notification) {
        this.setState((state) => {
            const newOpenedNotifications = state.openedNotifications.slice();
            const notificationIndex = newOpenedNotifications.indexOf(notification);
            newOpenedNotifications.splice(notificationIndex);
            return { openedNotifications: newOpenedNotifications };
        }, () => {
            antNotification.close(this.getNotificationKey(notification));
            CordovaNotificationHandler.removeSingleNotification(notification);
        });

    }

    getNotificationKey = (notification) => {
        return JSON.stringify(notification);
    }

    render = () => (
        <div className="notifications-wrapper"> </div>
    )
}

export default Notifications;
