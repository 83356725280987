import { injectIntl, defineMessages } from "react-intl";

const msg = defineMessages({
  
  logout: {
    id: 'dashboard.logout',
    defaultMessage: 'Log out'
  },
  
  settings: {
    'id':'dashboard.settings',
    'defaultMessage':'Settings'
  },
  
  ErrorInitialization: {
    'id':'dashboard.ErrorInitialization',
    'defaultMessage':'An error has ocurred'
  },
  
  ErrorTimeout: {
    'id':'dashboard.ErrorTimeout',
    'defaultMessage':'The server is taking to long to respond, please try again in sometime'
  },

  closeTextUpdate: {
    'id':'dashboard.closeTextUpdate',
    'defaultMessage':'Now!'
  },

  titleCountdown: {
    'id':'dashboard.titleCountdown',
    'defaultMessage':'Press the button to apply now, or it will be automatically applied in:'
  },

  updateFound: {
    'id':'dashboard.updateFound',
    'defaultMessage':'A new version of the app is available.'
  },

  
})

export default msg