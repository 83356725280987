import { injectIntl, defineMessages } from "react-intl";

const msg = defineMessages({

  cancel: {
    id:'reservables.cancel',
    defaultMessage:'Cancel'
  },

  contactFrontDesk: {
    id:'reservables.contactFrontDesk',
    defaultMessage:'Contact front desk'
  },

  until: {
    id:'reservables.until',
    defaultMessage:'until'
  },

  reservationUnavailable: {
    id: 'reservables.reservationUnavailable',
    defaultMessage: 'reservation is unavailable '
  },

  consecutiveNotAllowed: {
    id: 'reservables.consecutiveNotAllowed',
    defaultMessage: 'consecutive reservations are not allowed'
  },

  closed: {
    id: 'reservables.closed',
    defaultMessage: 'Closed'
  },
  reservedForYou: {
    id: 'reservables.reservedForYou',
    defaultMessage: 'is reserved for you'
  },
  modalCancel: {
    id: 'reservables.modalCancel',
    defaultMessage: '(tap/click to cancel)'
  },
  wasReservedForYou: {
    id: 'reservables.wasReservedForYou',
    defaultMessage: 'was reserved for you'
  },

  reservedForAnother: {
    id: 'reservables.reservedForAnother',
    defaultMessage: 'reserved for another'
  },

  available: {
    id: 'reservables.available',
    defaultMessage: 'Available'
  },
  modalReserve: {
    id: 'reservables.modalReserve',
    defaultMessage: '(tap/click to reserve)'
  },
  each: {
    id: 'reservables.each',
    defaultMessage: 'each'
  },
  selectItem: {
    id: 'reservables.selectItem',
    defaultMessage: 'Select Item'
  },

  priceNotSpecified: {
    id: 'reservables.priceNotSpecified',
    defaultMessage: 'Price not specified'
  }, 

  price: {
    id: 'reservables.price',
    defaultMessage: 'Price'
  }, 

  cancelSuccessfully: {
    id: 'reservables.cancelSuccessfully',
    defaultMessage: 'The reservation was canceled successfully'
  }, 

  errorCanceling: {
    id: 'reservables.errorCanceling',
    defaultMessage: 'Error when canceling the reservation'
  },

  exceededReservations: {
    id: 'reservables.exceededReservations',
    defaultMessage: 'Exceeded the maximum number of reservations'
  },

  canCancelOneHour: {
    id: 'reservables.canCancelOneHour',
    defaultMessage: 'Reservations can be canceled up to 1 hour in advance'
  },

  canCancelDays: {
    id: 'reservables.canCancelDays',
    defaultMessage: 'Reservations can be canceled up to {minDays} days ({minHours} hours) in advance'
  },
  canCancelHours: {
    id: 'reservables.canCancelHours',
    defaultMessage: 'Reservations can be canceled up to {minHours} hours in advance'
  },
  cannotCancel: {
    id: 'reservables.cannotCancel',
    defaultMessage: 'The reservation cannot be canceled, please contact the staff if you want to cancel it'
  },
  

})


export default msg

// import { injectIntl } from "react-intl";

// import msg from './messages'

// const t = this.props.intl.formatMessage

// this.t = this.props.intl.formatMessage
