import React from 'react';
import MarkdownRenderer from '../../MDRenderer'
import { Route, withRouter } from 'react-router-dom'
import Board from '../../../components/Board.js'
import Square from '../../../components/Square.js'
import TransitionSwitch from '../../../components/TransitionSwitch.js'
import LoadingComponent from '../../../components/LoadingComponent';

import PageView from '../../PageView'
import PageUl from '../../../components/PageUl.js'
import CustomForm from './CustomForm.js'
import Request from './Request.js'
import { SmartRequests } from '../../../utilities'
import Description from '../../PageView/Description';
import Fetching from '../../../components/Fetching';
import { reloadIfNeeded, PaginationControl } from '../../../components/Pagination.js'

import debounce from 'lodash.debounce';
import { Spin, message } from 'antd'

import { injectIntl } from "react-intl";

import msg from './messages'

import { Radio } from 'antd'
import { Input } from 'antd'
import BuildingInfo from '../../Main/buildingInfo.js';
import { isInteger } from 'lodash';
const { Search } = Input

class Custom extends React.Component {
  constructor(props) {
    super(props); 
    this.state = { 
      fetchingData: true,
      subPages: [],
      pageName: '',
      pathMatchesExactly: false,
      searching: false,
      searchResults: null,
      pageOffset: 0,
      dataRequest: null,
      newMqttMessage: false,
    };
    this.requests = []
    this.query= ''
    this.category= 'pending,processing'
    this.pageSize=10;
    this.info={};
    this.debouncedReloadIfNeeded = debounce( reloadIfNeeded.bind( this, 'requests_paginated?', this.setState.bind(this)) , 300)
  }

  
  cancelRequest = (request_id)=> {
    //FIXME: ADD CONFIRMATION
    SmartRequests.post('request/' + request_id + '/events', {event:'cancel'} ).then( resp => {
        if (resp.status === 200) {
          message.success(this.props.intl.formatMessage((msg.successCancelRequest)))
          this.reload= true
          this.debouncedReloadIfNeeded(); 
        }
    })
  }
  updateRequests= (req) => {
    this.reload= true
    this.debouncedReloadIfNeeded(); 
  }

  checkIdRequest = () => {
    let param = parseInt(this.props.idReq);
    
    if (isInteger(param)) {
      SmartRequests.get('request/' + param).then( resp => {
        if (resp.status === 200) {
          if (resp.data.request) {
            this.setState({dataRequest: resp.data})
          } else {
            this.setState({dataRequest: null})
          }
        }
      }).catch(err => {
        console.log("Error: ", err)
        this.setState({dataRequest: null})
      })
    } else {
      this.setState({dataRequest: null})
    }
  }
    

  syncPageData = () => {
    if (this.props.isGuest) {
      let isExactMatch = this.props.match.isExact
      if (isExactMatch) {
        this.requestGuestPageData()
        let pathMatchesExactly = true
        this.setState({ pathMatchesExactly, mustSyncAgain: false })
      } else {
        // do not request page info, but assume there is a child named as its child in the route
        let myUrl = this.props.match.url
        let fullUrl = this.props.location.pathname
        let nextKnownChildRegex = new RegExp(`^${myUrl}([^\/]*)/.*`)
        let theNextKnownChild = fullUrl.match(nextKnownChildRegex)[1]
        let subPages = [
          { name: theNextKnownChild, title: theNextKnownChild }
        ]
        this.setState({ subPages, mustSyncAgain: true })
      }
    }
  }

  requestGuestPageData = () => {
    let pageText = false
    let subPages = []
    let title = ''
    let url = `/${this.props.building}/resident/guest/ws/2/pages/${this.props.pageName}`
    SmartRequests.get(url).then((response) => {
      console.log(`This is the response: ${JSON.stringify(response)}`)
      let okStatus = response.status === 200
      let data = response.data
      if (okStatus && data) {
        let page = data.page
        if (page) {
          pageText = page.text || false
          subPages = data.inner_pages || []
          title = page.title
          if (this.props.pageview && this.props.pageview.setTitle) {
            this.props.pageview.setTitle(title)
          }
          console.log(page)
        }
      } else {
        console.log(`Request failed with error code: ${response.status}. Data: ${JSON.stringify(data)}`)
      }
    }).catch(err => {
      console.log(`Could not fetch page. ${err}`)
    }).finally(() => {
      this.setState({ pageText, subPages, title, fetchingData: false })
    })
  }

  updateOffset = (newOffset) => {
    this.setState({pageOffset: newOffset})
  }
  setSearchQuery(e) {
    this.query = e.target.value
    this.setState({ pageOffset: 0 });
    this.debouncedReloadIfNeeded()
  }
  setSearchCategory(e) {
      this.category = e.target.value
      if (this.category == 'all') {
        this.category = null
      }
      this.setState({ pageOffset: 0 });
      this.debouncedReloadIfNeeded()
    }
  componentDidUpdate = (prevProps, prevState) => {
    console.log('Updating custom')
    if (prevProps.idReq !== this.props.idReq ) {
      this.checkIdRequest();
    }
    
    if (this.state.mustSyncAgain && this.props.match.isExact) {
      this.syncPageData()
    }
  }

  componentDidMount = () => {
    console.log('Mounting custom')
    if (!this.props.isGuest) {this.setInfo()}
    this.syncPageData()
    this.checkIdRequest();
  }

  setInfo = async () => {
    this.info = await BuildingInfo.getInfo();
  }

  render() {
    if (this.state.fetchingData && this.props.isGuest && this.pathMatchesExactly) {
      return <div style={{position: 'fixed', top: 0, bottom: 0, right: 0, left: 0, 'zIndex': 101}}> <Fetching enabled /> </div>
    }
    let newMqttMessage= false
    if(this.props.data && this.props.data.newMqttMessage && this.props.data.mqttTopicMessage === "requests") {
      this.reload = true
      this.debouncedReloadIfNeeded()
      this.props.methods.pageMessageReceivedCallback()
      newMqttMessage= true
    }
    const t = this.props.intl.formatMessage
    let form, subPages, pageName, pageTitle, parentPath
    this.requests= this.props.data ? this.props.data.requests : []
    if (this.state.searchResults) {
      this.requests = this.state.searchResults
    }
    form = this.props.page ? this.props.page.form : null
    subPages = this.props.subPages ? this.props.subPages : this.state.subPages
    pageName = this.props.name || this.props.pageName
    pageTitle = this.props.page ? this.props.page.title : this.state.title
    parentPath = this.props.parentPath || ''

    
              
    return (  
      <div className='custom'>
        {this.state.pageText ? <Description text={this.state.pageText} /> : null}
        {(form)?  <CustomForm block={this.props.methods.block} formRaw={form} unit={this.props.unit} page={this.props.page.id} methods={this.props.methods} initialData={this.props.initialData} url={this.props.url}/>: ''}
        <Board>
          {subPages.map((childPage, i) => 
            <Square 
              title={childPage.page.title} 
              name={childPage.page.name}
              key={childPage.page.id}
              level={this.props.level + 1}
              pageData={childPage.page.data}
            />)
          }
        </Board>
        {this.props.page && this.props.page.name in this.props.data.tags && (<PageUl >
            <div className="filterBar">
              {!this.state.dataRequest && 
                <Radio.Group 
                defaultValue="pending,processing"
                buttonStyle="solid"
                onChange={this.setSearchCategory.bind(this)}
                >
                  <Radio.Button value="pending,processing">{t(msg.ongoing)}</Radio.Button>
                  <Radio.Button value="all">{t(msg.all)}</Radio.Button>
                </Radio.Group>
              }
            &nbsp;
            <Search
            placeholder="title"
            onChange={this.setSearchQuery.bind(this)}
            style={{ width: 120 }}
            allowClear
            /></div>
          
        <Spin spinning={this.state.searching || this.props.loading} delay={500}>
        {this.requests.length!==0 && this.requests.page_size > 0 && !this.props.isGuest? (
          <div>
            {this.state.dataRequest && this.state.dataRequest.request ?
              <Request key={this.state.dataRequest.request.id} req={this.state.dataRequest.request} info={this.info} t={t} cancelRequest={this.cancelRequest.bind(this)} newMqttMessage={newMqttMessage}/>
            : 
              <>
                <PaginationControl 
                  t={this.props.intl.formatMessage} 
                  pageOffset={this.state.pageOffset} 
                  updateOffset={this.updateOffset} 
                  debouncedReloadIfNeeded={this.debouncedReloadIfNeeded.bind(this)}
                  pageSize={this.pageSize}
                  elements={this.requests}
                />
                {this.requests.results.map(req => <Request key={req.id} req={req} info={this.info} t={t} cancelRequest={this.cancelRequest.bind(this)} newMqttMessage={newMqttMessage}/>)}
              </>
            }
          </div>):  
              <li className='li-request'> 
                <p> {t(msg.noRequestsMatch)} </p> 
              </li>
        }</Spin>
        {<br></br>}
        {<br></br>}
        </PageUl>)}
        <TransitionSwitch level={this.props.level + 1}>
        {
          subPages.map(
            (childPage, i) =>  {
              if (this.props.isGuest) {
                let myPath = `${this.props.parentPath}${pageName}/`
                return <Route key={i} path={`${myPath}${childPage.page.name}/`} render={({match}) => [
                    <PageView
                      pageType='custom'
                      isGuest
                      building={this.props.building}
                      pageName={childPage.page.name}
                      parentPath={myPath}
                      level={this.props.level + 1}
                      data={this.props.data}
                      url={'request/'  + childPage.page.id}
                    />
                  ]
                } />
              } else {
                return <Route key={i} path={'/:building/:unit/dashboard/'+ parentPath + this.props.name + '/' + childPage.page.name} render={({match}) => {

                  return(
                    <PageView 
                      page={childPage.page}
                      key={childPage.page.id}
                      name={childPage.page.name}
                      subPages={childPage.subPages}
                      unit={this.props.unit}
                      data={this.props.data}
                      reqData={this.props.reqData}
                      parentPath={parentPath + this.props.name + '/'}
                      methods={{
                        ...this.props.methods,
                        updateRequests: this.updateRequests.bind(this)}}
                      level={this.props.level + 1}
                      url={'request/' + childPage.page.id}
                      
                    />)
                }
                }/>
              }
            }
          )
          
        }
        </TransitionSwitch>
        
      </div>
      
    )
  }
}

export default withRouter(injectIntl(Custom))
