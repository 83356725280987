import React from 'react';
import Stagger from 'react-css-stagger';


class Board extends React.Component{
  
  render(){
    return (
      <Stagger className='board' transition="board" delay={150} initialDelay={this.props.delay || 200}>
        {this.props.children.map( chil => <div className="board-item" key={chil.key} >{chil}</div>)}
      </Stagger>
    )
  }
}

export default Board