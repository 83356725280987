import React from 'react'
import { Modal } from 'antd'
import { setRendererInstance } from './ZoomableImage'

// Static zoom: the one that is in the state
// Dynamic zoom: the one calculated from initial and current touch positions

export class ZoomableImagesRenderer extends React.Component {

    state = {
        src: null,
        config: { }
    }

    componentDidMount = () => {
        setRendererInstance(this)
    }

    _setRendererImage = (src, config) => {
        this.setState({ src, config })
    }

    render = () => (
        <Modal closable visible={!!this.state.src} onCancel={() => this._setRendererImage(null)} footer={null} title={null} >
            <ZoomArea src={this.state.src} />
        </Modal>
    )
}

class ZoomArea extends React.Component {
    state = {
        zoomFactor: 1,
        initialTouches: null,
        currentTouches: null
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.src !== prevProps.src) {
            this.setState({ zoomFactor: 1 })
        }
    }

    componentWillMount = () => {
        this.ref = React.createRef()
        this.imageRef = React.createRef()
    }

    componentDidMount = () => {
        this.ref.current.addEventListener('touchstart', this._touchStartHandler)
        this.ref.current.addEventListener('touchmove', this._touchMoveHandler, { passive: true })
        this.ref.current.addEventListener('touchend', this._touchStopHandler, { passive: true })
        this.ref.current.addEventListener('touchcancel', this._touchCancelHandler, { passive: true })

        this.forceUpdate() // to update style after the refs are available
    }

    componentWillUnmount = () => {
        this.ref.current.removeEventListener('touchstart', this._touchStartHandler)
        this.ref.current.removeEventListener('touchmove', this._touchMoveHandler)
        this.ref.current.removeEventListener('touchend', this._touchStopHandler)
        this.ref.current.removeEventListener('touchcancel', this._touchCancelHandler)
    }

    _touchStartHandler = (event) => {
        const targetTouches = event.targetTouches
        if (targetTouches.length === 2) {
            const firstTouch = targetTouches[0]
            const secondTouch = targetTouches[1]
            const initialTouches = [firstTouch, secondTouch]
            this.setState({ initialTouches })
        } else if (targetTouches.length > 2) {
            event.preventDefault()
            this._touchCancelHandler(event)
        }
    }

    _touchMoveHandler = (event) => {
        const targetTouches = event.targetTouches
        if (targetTouches.length === 2) {
            const firstTouch = targetTouches[0]
            const secondTouch = targetTouches[1]
            const currentTouches = [firstTouch, secondTouch]
            this.setState({ currentTouches })
        }
    }

    _touchStopHandler = (event) => {
        const targetTouches = event.targetTouches
        const changedTouches = event.changedTouches
        if ((targetTouches.length + changedTouches.length) === 2) {
            this.setState(function (state) {
                const currentDynamicZoomFactor = this._getCurrentDynamicZoomFactor(state)
                const prevZoomFactor = state.zoomFactor
                let zoomFactor = prevZoomFactor + currentDynamicZoomFactor
                zoomFactor = zoomFactor < this.props.minZoomFactor ? this.props.minZoomFactor :
                             zoomFactor > this.props.maxZoomFactor ? this.props.maxZoomFactor :
                             zoomFactor
                return { zoomFactor }
            })
        }
        this._touchCancelHandler(event)
    }

    _touchCancelHandler = (event) => {
        const initialTouches = null
        const currentTouches = null
        this.setState({ initialTouches, currentTouches })
    }

    _getImageStyle = () => {
        const zoomFactor = this.state.zoomFactor
        const dynamicZoomFactor = this._getCurrentDynamicZoomFactor(this.state)
        let totalZoomFactor = zoomFactor + dynamicZoomFactor
        totalZoomFactor = totalZoomFactor < this.props.minZoomFactor ? this.props.minZoomFactor :
                          totalZoomFactor > this.props.maxZoomFactor ? this.props.maxZoomFactor :
                          totalZoomFactor
        let marginTopValue = '50%'
        let marginLeftValue = '50%'
        let marginBotValue = '-50%'
        let marginRightValue = '-50%'
        if (this.imageRef.current && totalZoomFactor > 1) {
            const imageWidth = this.imageRef.current.clientWidth
            // const imageHeight = this.imageRef.current.clientHeight
            marginLeftValue = imageWidth / 2
            marginRightValue = -marginLeftValue
        }
        if (this.ref.current && this.imageRef.current) {
            const baseHeight = this.ref.current.offsetHeight
            const imageHeight = this.imageRef.current.clientHeight
            const heightFactor = imageHeight / baseHeight
            marginTopValue = baseHeight / 2
            if (heightFactor > 1) {
                marginTopValue = imageHeight / 2
                marginBotValue = -marginTopValue
            }
        }

        const style = {
            width: `calc(100% * ${totalZoomFactor})`,
            height: 'auto',
            'margin-top': marginTopValue,
            'margin-left': marginLeftValue,
            'margin-right': marginRightValue,
            'margin-bottom': marginBotValue
        }
        return style
    }

    _getCurrentDynamicZoomFactor = (state) => {
        if (state.currentTouches && state.initialTouches) {
            const baseWidth = this.ref.current.offsetWidth
            let distanceX = state.initialTouches[0].pageX - state.initialTouches[1].pageX
            let distanceY = state.initialTouches[0].pageY - state.initialTouches[1].pageY
            const initialDistance = Math.sqrt(distanceX**2 + distanceY**2) / 2
            distanceX = state.currentTouches[0].pageX - state.currentTouches[1].pageX
            distanceY = state.currentTouches[0].pageY - state.currentTouches[1].pageY
            const currentDistnce = Math.sqrt(distanceX**2 + distanceY**2) / 2
            const distanceDiff = currentDistnce - initialDistance
            const zoomFactor = state.zoomFactor * distanceDiff / baseWidth // (baseWidth * state.zoomFactor / distanceDiff)
            return zoomFactor
        }
        return 0
    }

    render = () => {
        return (
            <div ref={this.ref} id="zoomable-images-renderer">
                <img ref={this.imageRef} src={this.props.src} style={this._getImageStyle()} />
            </div>
        )
    }
}

ZoomArea.defaultProps = {
    maxZoomFactor: 4,
    minZoomFactor: .75
}
