import * as mqttClient from '../../Dashboard/mqttClient'
import { addNodeObserverCB, removeNodeObserverCB, createUniqueId, OBSERVER_CLASS } from './domNodeObserver.js'

class StatusNode {
  constructor(topic,variable) {  // Constructor
    this.id = createUniqueId("status")
    this.unsubscribeFunction = null
    this.topic = topic
    this.variable = variable
    this.value    = undefined
    this.elem     = undefined
		const statusTopic = this.topic + "/status"
    const that = this
		this.unsubscribeFunction = mqttClient.subscribeToTopic(statusTopic, (rtopic,msg) => {
				const fullState = JSON.parse(msg)
				console.log("remarkableMqtt: statusButton: new status", that.elem,"variable",that.variable,"id:" ,that.id, "=", msg);
				that.value = fullState[that.variable]
        if (that.elem) {
           that.elem.innerHTML = that.value
        } 
			})
  }
  
  link_open(topic, className = '', origin, related, attr = 'src') {
    addNodeObserverCB(this.id,  (elem) => {
       this.elem = elem
			 console.log("remarkableMqtt: statusButton: component added", this.elem, " current status", this.value);
       if (this.value) 
				 elem.innerHTML = this.value
      },
      (target) => {
        if (this.unsubscribeFunction) {
          this.unsubscribeFunction()
          this.unsubscribeFunction = null;
        }
      })

    return ``;
  }
  link_close(topic, className = '', origin, related, attr = 'src') {
    return `<span class="${OBSERVER_CLASS} " id="${this.id}" ></span>` 
  }
}

export default StatusNode
