import React from 'react';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router-dom';
import { Button, Divider, Checkbox, Modal, Typography, Tag } from 'antd';
import { MinusOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';

import { ZoomableImage } from '../../../components/ZoomableImage'
import msg from './messages';
import ChooseQuantity from './ChooseQuantity';
import { isEqual } from 'lodash';

const { Title, Paragraph } = Typography;

class ModifyModal extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        selectedCondiments: {},
        selectedExtras: [],
        canClose: false,
        highlightTitles: false, // makes titles red if mandatory to select options. Turns off when min quantity is selected
        animateTitles: false, // makes titles jump on button click
      };
      this.confirmMandatory = this.confirmMandatory.bind(this)
      this.canClose = this.canClose.bind(this)
      this.translate = this.props.intl.formatMessage // Get translation function from props
    }
  
    componentDidMount() {
      const { dish, defaultLang } = this.props;
      let initialCondiments = {};
      //only do if selectedCondiments is empty json
      if (dish.option_groups != null && Object.keys(dish.selectedCondiments).length === 0) {
        dish.option_groups.forEach(category => {
          initialCondiments[category.option_group_name[defaultLang]] = {group_name: category.option_group_name, selected: []}; // doesn't select anything
        });
      } else {
        initialCondiments = dish.selectedCondiments;
      }
      this.setState({ selectedCondiments: initialCondiments, selectedExtras: dish.selectedExtras, highlightTitles: false, animateTitles: false, currentQuantity: dish.quantity }, () => this.canClose());
    }
  
    handleCondimentChange = (option_group_name, value, max_quantity) => {
      const { defaultLang } = this.props
      let selected = null
      if (this.state.selectedCondiments[option_group_name[defaultLang]].selected.find(element => isEqual(element, value))) {
        selected = this.state.selectedCondiments[option_group_name[defaultLang]].selected.filter(element => !isEqual(element, value))
      } else {
        selected = [...this.state.selectedCondiments[option_group_name[defaultLang]].selected, value]
      }

      this.setState(prevState => ({
        selectedCondiments: {
          ...prevState.selectedCondiments,
          [option_group_name[defaultLang]]: {group_name: option_group_name, selected: selected}
        }
      }), () => this.canClose());
    }
  
    handleExtraChange = (extra) => {
      const defaultLang = this.props.defaultLang
      this.setState(prevState => {
        const extraAlreadyExists = prevState.selectedExtras.find((e) => (
          e.name[defaultLang] == extra.name[defaultLang]
        ))
        const newExtras = extraAlreadyExists
          ? prevState.selectedExtras.filter(e => e.name[defaultLang] !== extra.name[defaultLang])
          : [...prevState.selectedExtras, extra];
        return { selectedExtras: newExtras };
      });
    }
  
    canClose = () => {
      const { dish, defaultLang } = this.props;
      let canClose = true;
      dish.option_groups.forEach(option_group => { // Makes sure all mandatory fields have options selected
        let min_quantity = option_group.min_quantity;
        let max_quantity = option_group.max_quantity;
        let group_name = option_group.option_group_name[defaultLang];
        if ((min_quantity > 0 && this.state.selectedCondiments[group_name].selected.length < min_quantity)
            || this.state.selectedCondiments[group_name].selected.length > max_quantity) {
          canClose = false // Some mandatory field has no options selected
        }
      });
      this.setState({ canClose: canClose })
    }
  
    confirmMandatory = (exitAction, extra) => {
      let canClose = this.state.canClose;
  
      if (exitAction === 'accept') { // If confirm button is clicked
        if (canClose) { // Confirms changes if all mandatory fields have options selected
          const {selectedCondiments, selectedExtras, quantity} = extra
          this.props.onOk(selectedCondiments, selectedExtras, quantity)
        } else {
          // Highlights and animates titles of the fields that are mandatory and don't have enough options selected
          this.setState({highlightTitles: true, animateTitles: true})
        }
      } else {
        if (this.props.firstTime) { // If modal is opened because a new product is being added to cart. If cancel, product is not added to cart.
          this.props.cancelAddToOrder()
        } else { // If modal is opened by modify button on product already in cart. If cancel, changes are discarded.
          this.props.onCancel()
        }
      }
    }
  
    render() {
      const { dish, visible, defaultLang, currentLang } = this.props;
      const { selectedCondiments, selectedExtras, currentQuantity } = this.state;
      const totalPrice = currentQuantity * (dish.price + selectedExtras.reduce((a, b) => a + b.price, 0))

      return (
        <Modal
          title={
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', justifyContent: 'space-between'}}>
                <Title level={2} style={{textAlign: 'center', margin: 0}}>
                  {dish.name[currentLang] || dish.name[defaultLang]}
                </Title>
                <CloseOutlined onClick={() => this.confirmMandatory('cancel',null)} />
              </div>
          }
          zIndex={500}
          visible={visible}
          closable={false}
          style={{
            // allow modal to be scrolled up and leave a space underneath
            maxHeight: '75vh',
            overflowY: 'auto',
            top: '10vh',
            display: 'flex'
          }}
          onCancel={() => {this.confirmMandatory('cancel',null)}}
          onOk={() => {this.confirmMandatory('accept', {selectedCondiments: selectedCondiments, selectedExtras: selectedExtras, quantity: currentQuantity})}}
          footer={
            <div>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'stretch'}}>
                <div>
                  <Title level={4}>{this.translate(msg.price)}</Title>
                </div>
                <div>
                  <Title level={4}>${totalPrice.toFixed(2)}</Title>
                </div>
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'stretch'}}>
                <div className='cart-item-quantity' style={{ border: '1px solid', borderRadius: 4, padding: '5px 3%', fontSize: '16px', width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <MinusOutlined style={{ cursor: 'pointer' }} onClick={() => {if (currentQuantity > 1) this.setState({currentQuantity: currentQuantity - 1})}} />
                  <span>{currentQuantity}</span>
                  <PlusOutlined style={{ cursor: 'pointer' }} onClick={() => {this.setState({currentQuantity: currentQuantity + 1})}} />
                </div>
                <Button type={this.state.canClose? 'primary' : 'disabled'} style={{width: '65%', height: 'auto'}} onClick={() => {this.confirmMandatory('accept', {selectedCondiments: selectedCondiments, selectedExtras: selectedExtras, quantity: currentQuantity})}}>
                  Confirm
                </Button>
              </div>
            </div>
          }
        >
          {((dish.description != null && dish.description[defaultLang] != '') || (dish.image_url != null && dish.image_url != '')) &&
          <>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              {(dish.description != null && dish.description[defaultLang] != '') &&
                <Paragraph
                  style={{ minWidth: 0, wordBreak: 'break-word', whiteSpace: 'normal', marginRight: 10 }}
                >
                  {dish.description[currentLang] || dish.description[defaultLang]}
                </Paragraph>
              }

              {(dish.image_url != null && dish.image_url != '') &&
                <div className='card-image' style={{ width: 100, height: 100, display: 'flex', flexShrink: 0, justifyContent: 'center', alignItems: 'center' }}>
                  {dish.image_url && (
                    <ZoomableImage
                      src={dish.image_url}
                      alt={dish.name[currentLang] || dish.name[defaultLang]}
                      width="100%"
                      height="100%"
                    />
                  )}
                </div>
              }
            </div>
            {((dish.option_groups != null && dish.option_groups.length > 0) || (dish.extras != null && dish.extras.length > 0)) &&
              <Divider />
            }
          </>
          }
          {(dish.option_groups != null) && dish.option_groups.map((category, index) => { // For every option_group
            const max_quantity_reached = selectedCondiments[category.option_group_name[defaultLang]] != null && selectedCondiments[category.option_group_name[defaultLang]].selected.length == category.max_quantity;
            const less_than_min_quantity = selectedCondiments[category.option_group_name[defaultLang]] != null && selectedCondiments[category.option_group_name[defaultLang]].selected.length < category.min_quantity
            const more_than_max_quantity = selectedCondiments[category.option_group_name[defaultLang]] != null && selectedCondiments[category.option_group_name[defaultLang]].selected.length > category.max_quantity
            return (
              (category.options != null && category.options.length > 0) && (
                <div key={category.option_group_name[defaultLang]}>
                  {index ? <Divider /> : null}
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Title level={3}>{category.option_group_name[currentLang] || category.option_group_name[defaultLang]}</Title>
                    {category.min_quantity > 0 && less_than_min_quantity &&
                      <Tag color="#1890ff">{this.translate(msg.required)}</Tag>
                    }
                    {category.min_quantity > 0 && !less_than_min_quantity &&
                      <Tag color="#009900">{this.translate(msg.required)}</Tag>
                    }

                  </div>
                  <br></br>
                  <div style={{display: 'flex', flexDirection:'column'}}>
                    <ChooseQuantity 
                      highlight={this.state.highlightTitles && (less_than_min_quantity || more_than_max_quantity)} 
                      animate={this.state.animateTitles && (less_than_min_quantity || more_than_max_quantity)}
                      max_quantity_reached={max_quantity_reached}
                      onAnimationEnd = {() => this.setState({animateTitles: false})}
                      min_quantity={category.min_quantity} 
                      max_quantity={category.max_quantity} 
                      cant_opciones={category.options.length}
                    />
                    <div className='option-group'>
                      {category.options.map(condiment => {
                        const optionSelected = (selectedCondiments[category.option_group_name[defaultLang]] && selectedCondiments[category.option_group_name[defaultLang]].selected.find(selectedCondiment => selectedCondiment[defaultLang] == condiment[defaultLang]) != null)
                        return(
                        <Checkbox 
                          key={condiment[defaultLang]} 
                          disabled={(max_quantity_reached || more_than_max_quantity) && !optionSelected} 
                          onChange={() => this.handleCondimentChange(category.option_group_name, condiment, category.max_quantity)}
                          checked={ optionSelected }
                        >
                          {condiment[currentLang] || condiment[defaultLang]}
                        </Checkbox>
                      )})}
                    </div>
                  </div>
                </div>
              )
            )
          })}
          {(dish.extras != null && dish.extras.length > 0) && 
            <>
              {dish.option_groups != null && dish.option_groups.length > 0 && <Divider />}
              <Title level={3}>Extras</Title>
            </>
          }
          <div style={{display: 'flex', flexDirection:'column'}}>
            <div>
              {(dish.extras != null) && dish.extras.map(extra => (
                <Checkbox
                  key={extra.name[defaultLang]}
                  onChange={() => this.handleExtraChange(extra)}
                  checked={selectedExtras.map(selectedExtra => selectedExtra.name[defaultLang]).includes(extra.name[defaultLang])}
                >
                  {extra.name[currentLang] || extra.name[defaultLang]} (+${extra.price.toFixed(2)})
                </Checkbox>
              ))}
            </div>
          </div>
        </Modal>
      );
    }
}

export default injectIntl(withRouter(ModifyModal));