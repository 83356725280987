import * as helper from './helpers';
import * as CustomStorage from '../../utilities/customStorage'
import { SmartRequests } from '../../utilities';

const PAGES_PREFIX = 'Page';
const TAGS_PREFIX = 'PageTag';

class Pages {

  constructor(buildingName, unit) { 
    this.buildingName = buildingName;
    this.unit = unit;
  }

  // Public
  get = async () => {
    let pages = await this.getStored();
    if (!pages) {
      pages = await this.fetch();
    }
    return pages;
  }

  // Public
  getTags = async () => {
    let tags = await this.getStoredTags();
    if (!tags) {
      await this.fetch();
      tags = await this.getStoredTags();
    }
    return tags;
  }

  // Private 
  fetch = async () => {
    const response = await SmartRequests.get(`/${this.buildingName}/resident/${this.unit}`);
    const data = response.data;
    let pages = this.parsePages(data);
    await this.store(pages);
    await this.storeTags(data.pages_for_tag);
    return pages;
  }

  // Private
  parsePages = (data) => { 
    let pages = helper.get_pages(
      'homepage',
      data.pages_for_tag, 
      this.sortPages(data.pages),
    )
    pages = pages.filter(p => p.page.type !== 'settings');
    return pages;
  }

  // Private
  sortPages = (pages) => {
    const sorted = pages.sort((page1, page2) => page1.name < page2.name);
    const ordered = sorted.reduce((all, cur) => {
      all[cur.id] = cur;
      return all;
    }, {});
    return ordered;
  }

  // Private
  store = async (pages) => {
    const storageItem = this.storageItemAtCurrentBuilding();
    const serialized = JSON.stringify(pages)
    await CustomStorage.setItem(storageItem, serialized);
  }

  // Private
  getStored = async () => {
    const storageItem = this.storageItemAtCurrentBuilding();
    const serialized = await CustomStorage.getItem(storageItem);
    const unserialized = JSON.parse(serialized);
    return unserialized;
  }

  // Private
  storeTags = async (tags) => {
    const storageItem = this.storageItemForTagAtCurrentBuilding();
    const serialized = JSON.stringify(tags)
    await CustomStorage.setItem(storageItem, serialized);
  }

  // Private
  getStoredTags = async () => {
    const storageItem = this.storageItemForTagAtCurrentBuilding();
    const serialized = await CustomStorage.getItem(storageItem);
    const unserialized = JSON.parse(serialized);
    return unserialized;
  }

  // Private
  storageItemForTagAtCurrentBuilding = () => {
    return `${TAGS_PREFIX}${this.buildingName}`;
  }

  // Private
  storageItemAtCurrentBuilding = () => {
    return `${PAGES_PREFIX}${this.buildingName}`;
  }
}

export default Pages;
