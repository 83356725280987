// import { Document, Page, pdfjs } from "react-pdf";
// import VisualizePdf from './pdf_renderer.js';
//import pdfjsLib from 'pdfjs-dist/build/pdf.js';

var pageNumber=1;
var pageRendering = false;
var pdfDoc = null;
var ctx="";
var pageNumPending = null;

// window.addEventListener('resize', function() {
//   console.log('Tamaño de Pantalla Cambiado');
//   const newScale = calcularNuevoScaleSegunTamañoPantalla();
//   renderPage(pageNumber, newScale);
// });

// function calcularNuevoScaleSegunTamañoPantalla() {
//   const anchoVentana = window.innerWidth;

//   const breakpointPequeño = 600; 
//   const breakpointMediano = 1024; 

//   if (anchoVentana < breakpointPequeño) {
//     return 1.0; 
//   } else if (anchoVentana < breakpointMediano) {
//     return 1.5; 
//   } else {
//     return 2.0;
//   }
// }



function renderPage(num, scale = 1.5) {
  return new Promise((resolve, reject) => {
    pdfDoc.getPage(num).then(function(page) {
      var viewport = page.getViewport({scale: scale});
      console.log("VIEWPORT " + JSON.stringify(viewport))
      var canvas = document.getElementById('pdf-canvas') || document.createElement('canvas');
      let createdNewCanvas = false;
      if (!document.getElementById('pdf-canvas')) {
        canvas.id = 'pdf-canvas'
        canvas.classList.add('pdf-canvas');
        createdNewCanvas = true;
      }
      ctx = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      var renderContext = {
        canvasContext: ctx,
        
        viewport: viewport
      };
      var renderTask = page.render(renderContext);
      renderTask.promise.then(function() {
        if (createdNewCanvas) {
          console.log('Page rendered');
          resolve(canvas);
        } else {
          pageRendering = false;
          if (pageNumPending !== null) {
            renderPage(pdfDoc, pageNumPending, scale);
            pageNumPending = null;
          }
          document.getElementById('page_num').textContent = num;
        }
      });
    });
  });
}


async function generatePdfCanvas(url, scale = 1.5) {
  console.log('PDF URL:', url);
  const pdfjsLib = await import('pdfjs-dist/legacy/build/pdf');
  const pdfjsWorker = await import('pdfjs-dist/legacy/build/pdf.worker.entry');   
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker//'//mozilla.github.io/pdf.js/legacy/build/pdf.worker.mjs'; //pdfjsWorker;
  var loadingTask = pdfjsLib.getDocument(url);
  return new Promise((resolve, reject) => {
    loadingTask.promise.then(function(pdfDoc_) {
      pdfDoc = pdfDoc_;
      console.log('PDF loaded');
      renderPage(1, scale).then(canvas => resolve(canvas));

    }, function (reason) {
      console.error(reason);
      reject(reason);
    });
  });}

  

function queueRenderPage(num) {
    if (pageRendering) {
      pageNumPending = num;
    } else {
      renderPage(num);
    }
  }
function onPrevPage() {
  if (pageNumber <= 1) {
      return;
    }
    pageNumber--;
    queueRenderPage(pageNumber);
  }

  /**
   * Displays next page.
   */
function onNextPage() {
    if (pageNumber >= pdfDoc.numPages) {
      return;
    }
    pageNumber++;
    queueRenderPage(pageNumber);
  }
function isPDFLink(href) {
  return href.endsWith('.pdf');
}

const remarkablePDF = (md, config = {}) => {
  const originalLinkOpenRenderer = md.renderer.rules.link_open;
  const originalLinkCloseRenderer = md.renderer.rules.link_close;
  md.renderer.rules.link_open = (tokens, idx, options, env) => {
    const href = tokens[idx].href;
    if (isPDFLink(href)) {
      env.pdf = true;
      const placeholderId = 'pdf-placeholder-' + Math.random().toString(36).substr(2, 9);
      //const canvasPromise = generateOpenTag(href);
      const canvasPromise = generatePdfCanvas(href);

      canvasPromise.then( function(canvas) {
        const placeholder = document.getElementById(placeholderId);
        if (placeholder) {
        const firstChild = placeholder.firstChild;
        placeholder.insertBefore(canvas, firstChild);
        document.getElementById('page_num').textContent = pageNumber;
        document.getElementById('page_count').textContent = pdfDoc.numPages;
        document.getElementById('next_page').addEventListener('click', onNextPage);
        document.getElementById('prev_page').addEventListener('click', onPrevPage);
        }
      })
    
      return `<div id="${placeholderId}">
              <div class="pdf-page-selector">
                <div class="buttons-separator">
                  <button class="ant-btn ant-btn-primary" id="prev_page">Previous</button>
                  <button class="ant-btn ant-btn-primary" id="next_page">Next</button>
                </div>
                <div>
                  <span>Page: <span id="page_num"></span> / <span id="page_count"></span></span>
                </div>
              </div>
            </div>`;
    }

    return originalLinkOpenRenderer(tokens, idx, options, env);
  };

  md.renderer.rules.link_close = (tokens, idx, options, env) => {
    if (env.pdf) {
      env.pdf = false;
      console.log('close')
      return '</canvas>';
    }

    return originalLinkCloseRenderer(tokens, idx, options, env);
  };
};
  
export default remarkablePDF;